import fetch from '@/config/axios.config'

const DEFAULT_CONFIG = {
  // 自动弹出错误信息
  isOutMsg: true,
  // 自动loading
  isLoading: true,
  // Loading 需要覆盖的 DOM 节点。可传入一个 DOM 对象或字符串；
  // 若传入字符串，则会将其作为参数传入 document.querySelector以获取到对应 DOM 节点默认是 document.body
  once: false,
  // 当还有接口没有返回结果是，不再次发送，避免按钮多次点击，和isLoading一起使用
  target: '.mainCon',
  // 实际用户和菜单显示用户是否相同
  isSameUSer: true,
  // 登录 status 校验
  isLogin: true,
  // 激活 status校验
  isActive: true,
  // 灰度产品线 status 校验
  isGrey: true,
  // csrf 传递token
  isCsrf: true,
  // 如果参数是空值是不传
  isRemoveField: false,
  // 和 isRemoveField 一起使用
  removeField: [],
  computeResponseTime: true,
  loadingTime: 6000
}

const POST_HEADER = {
  headers: {
    // 'content-type': 'application/json'
  }
}

/**
 * get 提交
 * @param {String} url 请求的url
 * @param {any} params  请求的参数
 * @param {Obejct} config  请求配置
 * @returns Promise
 */
export function get(url, params = {}, config = {}) {
  let opts = { ...DEFAULT_CONFIG, ...config }
  opts.params = getParams(params, opts)
  return fetch.get(url, opts)
}

/**
 *
 * post 提交
 * @param {String} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @param {any} isApiHost 请求配置
 * @returns Promise
 *
 * @memberOf HttpBase
 */
export function post(url, params = {}, config = {}) {
  let opts = { ...DEFAULT_CONFIG, ...config, ...POST_HEADER }
  return fetch.post(url, getParams(params, opts), opts)
}

/**
 *
 * put 提交
 * @param {String} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @returns Promise
 *
 * @memberOf HttpBase
 */
export function put(url, params, query = {}, config = {}) {
  let opts = { ...DEFAULT_CONFIG, ...POST_HEADER, ...config }
  url = `${url}/${params}`
  return fetch.put(url, getParams(query, opts), opts)
}

/**
 *
 * delete 提交
 * @param {String} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @returns Promise
 *
 * @memberOf HttpBase
 */
export function Delete(url, params = {}, config = {}) {
  let opts = { ...DEFAULT_CONFIG, ...POST_HEADER, ...config }
  opts.data = { ...params }
  return fetch.delete(url, opts)
}

/**
 *
 * 上传
 * @export
 * @param {any} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @param {any} [config={}] 配置
 * @returns Promise
 */
export function upload(url, params = {}, config = {}) {
  let opts = { ...DEFAULT_CONFIG, ...POST_HEADER, ...config }
  let form = new FormData()
  Object.keys(params).forEach(key => {
    form.append(key, params[key])
  })
  return fetch.post(url, form, opts)
}

/**
 *
 * 处理参数 移除值是 空的 和加上一些用户信息等操作
 * @param {any} params 传入参数
 * @param {any} config 配置
 * @returns 返回新的参数
 */
function getParams(params, config) {
  // 用户相关
  if (!config.isRemoveField) {
    return params
  }
  return removeEmptyField(params, config.removeField)
}

/**
 *
 * 移除提交请求中 列为空 null undefined 的值
 * @param {any} [params={}] 传入的参数
 * @param {any} [removeField=[]] 需要移除的列
 */
function removeEmptyField(params = {}, removeField = []) {
  let copyParams = JSON.parse(JSON.stringify(params))
  let arrField = removeField
  if (removeField.length === 0) {
    arrField = Object.keys(params)
  }
  arrField.forEach(key => {
    let val = copyParams[key]
    if (val === '' || val === undefined || val === null) {
      delete copyParams[key]
    }
  })
  return copyParams
}

export const removeField = removeEmptyField
