import { mapState } from 'vuex'

export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {}
  },
  watch: {},
  computed: {
    ...mapState({
      identityTypeOptions: state => state.profile.identityTypeOptions,
      provinceOptions: state => state.profile.provinceOptions,
      serviceTypeOptions: state => state.profile.serviceTypeOptions,
      organizersNatureOptions: state => state.profile.organizersNatureOptions,
      preApprovalOptions: state => state.profile.preApprovalOptions,
      languageOptions: state => state.profile.languageOptions,
      appServiceTypeOptions: state => state.profile.appServiceTypeOptions,
      sdkCsOptions: state => state.profile.sdkCsOptions,
      sdkFwlxOptions: state => state.profile.sdkFwlxOptions,
      appTypeOptions: state => state.profile.appTypeOptions,
      siteTypesTreeOptions: state => state.profile.siteTypesTreeOptions
    })
  },
  mounted() {},
  created() {},
  filters: {},
  methods: {}
}
