import license from '@/assets/images/temp/license.jpg'
import licenseLarge from '@/assets/images/temp/license-large.jpg'
import cardFront from '@/assets/images/temp/card.jpg'
import cardFrontLarge from '@/assets/images/temp/card-large.jpg'
import cardOpposite from '@/assets/images/temp/card2.jpg'
import cardOppositeLarge from '@/assets/images/temp/card2-large.jpg'
import checkSheet from '@/assets/images/temp/check-pic.jpg'
import checkSheetLarge from '@/assets/images/temp/check-large.jpg'
import commitCommunity from '@/assets/images/temp/commit-pic-community.png'
import commitCommunityLarge from '@/assets/images/temp/commit-community-large.png'
import commitPersonLarge from '@/assets/images/temp/commit-person-large.png'
import commitCommonExample from '@/assets/images/temp/commit-common-example.jpg'
import domainRealnameExample from '@/assets/images/temp/domain-realname-example.png'
import domainCertExample from '@/assets/images/temp/domain-cert-example.png'

import imgtype_1 from '@/assets/images/temp/imgtype_1.jpg'
import imgtypeL_1 from '@/assets/images/temp/imgtypeL_1.jpg'
import imgtype_2 from '@/assets/images/temp/imgtype_2.jpg'
import imgtypeL_2 from '@/assets/images/temp/imgtypeL_2.jpg'
import imgtype_3 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_3 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_4 from '@/assets/images/temp/imgtype_4.jpg'
import imgtypeL_4 from '@/assets/images/temp/imgtypeL_4.jpg'
import imgtype_6 from '@/assets/images/temp/imgtype_6.jpg'
import imgtypeL_6 from '@/assets/images/temp/imgtypeL_6.jpg'
import imgtype_7 from '@/assets/images/temp/imgtype_7.jpg'
import imgtypeL_7 from '@/assets/images/temp/imgtypeL_7.png'
import imgtype_9 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_9 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_10 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_10 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_11 from '@/assets/images/temp/imgtype_31.jpg'
import imgtypeL_11 from '@/assets/images/temp/imgtypeL_31.png'
import imgtype_13 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_13 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_14 from '@/assets/images/temp/imgtype_14.jpg'
import imgtypeL_14 from '@/assets/images/temp/imgtypeL_14.jpg'
import imgtype_15 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_15 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_16 from '@/assets/images/temp/imgtype_16.jpg'
import imgtypeL_16 from '@/assets/images/temp/imgtypeL_16.jpg'
import imgtype_17 from '@/assets/images/temp/imgtype_17.jpg'
import imgtypeL_17 from '@/assets/images/temp/imgtypeL_17.jpg'
import imgtype_18 from '@/assets/images/temp/imgtype_18.jpg'
import imgtypeL_18 from '@/assets/images/temp/imgtypeL_18.jpg'
import imgtype_20 from '@/assets/images/temp/imgtype_20.jpg'
import imgtypeL_20 from '@/assets/images/temp/imgtypeL_20.jpg'
import imgtype_21 from '@/assets/images/temp/imgtype_21.jpg'
import imgtypeL_21 from '@/assets/images/temp/imgtypeL_21.jpg'
import imgtype_22 from '@/assets/images/temp/imgtype_24.jpg'
import imgtypeL_22 from '@/assets/images/temp/imgtypeL_24.jpg'
import imgtype_23 from '@/assets/images/temp/imgtype_24.jpg'
import imgtypeL_23 from '@/assets/images/temp/imgtypeL_24.jpg'
import imgtype_24 from '@/assets/images/temp/imgtype_24.jpg'
import imgtypeL_24 from '@/assets/images/temp/imgtypeL_24.jpg'
import imgtype_26 from '@/assets/images/temp/imgtype_30.jpg'
import imgtypeL_26 from '@/assets/images/temp/imgtypeL_30.png'
import imgtype_27 from '@/assets/images/temp/imgtype_29.jpg'
import imgtypeL_27 from '@/assets/images/temp/imgtypeL_29.jpg'
import imgtype_29 from '@/assets/images/temp/imgtype_29.jpg'
import imgtypeL_29 from '@/assets/images/temp/imgtypeL_29.jpg'
import imgtype_30 from '@/assets/images/temp/imgtype_30.jpg'
import imgtypeL_30 from '@/assets/images/temp/imgtypeL_30.png'
import imgtype_31 from '@/assets/images/temp/imgtype_31.jpg'
import imgtypeL_31 from '@/assets/images/temp/imgtypeL_31.png'
import imgtype_33 from '@/assets/images/temp/imgtype_33.jpg'
import imgtypeL_33 from '@/assets/images/temp/imgtypeL_33.jpg'
import imgtype_34 from '@/assets/images/temp/imgtype_34.jpg'
import imgtypeL_34 from '@/assets/images/temp/imgtypeL_34.jpg'
import imgtype_36 from '@/assets/images/temp/imgtype_36.jpg'
import imgtypeL_36 from '@/assets/images/temp/imgtypeL_36.jpg'
import imgtype_38 from '@/assets/images/temp/imgtype_38.jpg'
import imgtypeL_38 from '@/assets/images/temp/imgtypeL_38.jpg'
import imgtype_39 from '@/assets/images/temp/imgtype_39.jpg'
import imgtypeL_39 from '@/assets/images/temp/imgtypeL_39.jpg'
import imgtype_40 from '@/assets/images/temp/imgtype_40.jpg'
import imgtypeL_40 from '@/assets/images/temp/imgtypeL_40.jpg'
import imgtype_42 from '@/assets/images/temp/imgtype_42.jpg'
import imgtypeL_42 from '@/assets/images/temp/imgtypeL_42.jpg'

export const SERVER_CONTENT_TYPE = [
  { value: 1, label: '综合门户' },
  { value: 2, label: '单位门户网站' },
  { value: 101, label: '即时通信' },
  { value: 202, label: '搜索引擎' },
  { value: 4, label: '网上邮局' },
  { value: 5, label: '网络新闻' },
  { value: 6, label: '博客/个人空间' },
  { value: 7, label: '网络广告/信息' },
  { value: 9, label: '网络购物' },
  { value: 10, label: '网上支付' },
  { value: 11, label: '网上银行' },
  { value: 12, label: '网上炒股/股票基金' },
  { value: 13, label: '网络游戏' },
  { value: 14, label: '网络音乐' },
  { value: 15, label: '网络影视' },
  { value: 16, label: '网络图片' },
  { value: 17, label: '网络软件/下载' },
  { value: 18, label: '网上求职' },
  { value: 19, label: '网上交友/婚介' },
  { value: 20, label: '网上房产' },
  { value: 21, label: '网络教育' },
  { value: 22, label: '网站建设' },
  { value: 23, label: 'WAP' },
  { value: 28, label: 'APP' },
  { value: 29, label: '网络借贷信息中介' },
  { value: 30, label: '互联网股权融资' },
  { value: 31, label: '互联网保险' },
  { value: 32, label: '互联网跨界金融及资产管理' },
  { value: 33, label: '网上非银支付' },
  { value: 0, label: '其他' }
]

export const PRE_APPROVAL_OPTION = [
  { value: 1, label: '新闻' },
  { value: 2, label: '出版' },
  { value: 3, label: '教育' },
  { value: 4, label: '医疗保健' },
  { value: 5, label: '药品和医疗器械' },
  { value: 6, label: '电子公告服务' },
  { value: 7, label: '文化' },
  { value: 8, label: '广播电影电视节目' },
  { value: 9, label: '宗教' },
  { value: 10, label: '互联网金融' },
  { value: 11, label: '网络预约车' },
  { value: 12, label: '校外培训（高中及以下阶段）' },
  { value: 0, label: '不涉及前置审批' }
]
export const IMG_LINK = {
  license: license,
  licenseLarge: licenseLarge,
  cardFront: cardFront,
  cardFrontLarge: cardFrontLarge,
  cardOpposite: cardOpposite,
  cardOppositeLarge: cardOppositeLarge,
  checkSheet: checkSheet,
  checkSheetLarge: checkSheetLarge,
  commitCommunity: commitCommunity,
  commitCommunityLarge: commitCommunityLarge,
  commitPersonLarge: commitPersonLarge,
  commitCommonExample: commitCommonExample,
  domainRealnameExample: domainRealnameExample,
  domainCertExample: domainCertExample,
  imgtype_1,
  imgtypeL_1,
  imgtype_2,
  imgtypeL_2,
  imgtype_3,
  imgtypeL_3,
  imgtype_4,
  imgtypeL_4,
  imgtype_6,
  imgtypeL_6,
  imgtype_7,
  imgtypeL_7,
  imgtype_9,
  imgtypeL_9,
  imgtype_10,
  imgtypeL_10,
  imgtype_11,
  imgtypeL_11,
  imgtype_13,
  imgtypeL_13,
  imgtype_14,
  imgtypeL_14,
  imgtype_16,
  imgtypeL_16,
  imgtype_15,
  imgtypeL_15,
  imgtype_17,
  imgtypeL_17,
  imgtype_18,
  imgtypeL_18,
  imgtype_20,
  imgtypeL_20,
  imgtype_21,
  imgtypeL_21,
  imgtype_22,
  imgtypeL_22,
  imgtype_23,
  imgtypeL_23,
  imgtype_24,
  imgtypeL_24,
  imgtype_26,
  imgtypeL_26,
  imgtype_27,
  imgtypeL_27,
  imgtype_29,
  imgtypeL_29,
  imgtype_30,
  imgtypeL_30,
  imgtype_31,
  imgtypeL_31,
  imgtype_33,
  imgtypeL_33,
  imgtype_34,
  imgtypeL_34,
  imgtype_36,
  imgtypeL_36,
  imgtype_38,
  imgtypeL_38,
  imgtype_39,
  imgtypeL_39,
  imgtype_40,
  imgtypeL_40,
  imgtype_42,
  imgtypeL_42
}
export const AGREEMENT = {
  informationSecurity: '//docs.jdcloud.com/cn/icp-license-service/information-security-management-protocol',
  assistChange:
    '//docs.jdcloud.com/cn/icp-license-service/assist-in-changing-the-filing-information-service-online-terms-of-service',
  sms: '//docs.jdcloud.com/cn/icp-license-service/recording-sms-verification-instructions',
  verificationSheet: '//docs.jdcloud.com/cn/icp-license-service/check-list-download',
  commitPaper: '//docs.jdcloud.com/cn/icp-license-service/unit-internet-information-service-filing-commitment',
  password: '//docs.jdcloud.com/cn/icp-license-service/icp-filing-password-recovery-method',
  introduction: 'https://docs.jdcloud.com/cn/icp-license-service/elec-record',
  authCode: 'https://docs.jdcloud.com/cn/icp-license-service/record-shouquanma',
  otherInfoIntroduction: 'https://docs.jdcloud.com/cn/icp-license-service/shanghaiziliao',
  companyLicense: 'https://docs.jdcloud.com/cn/icp-license-service/qyzxsqtk'
}

export const RECORD_TYPE = [
  { value: 1, label: '新增备案' },
  { value: 3, label: '有主体新增服务' },
  { value: 4, label: '主体变更' },
  { value: 5, label: '服务变更' },
  { value: 6, label: '变更接入' },
  { value: 7, label: '有主体新增接入' },
  { value: 8, label: '无主体新增接入' },
  { value: 9, label: '无主体新增服务' },
  { value: 10, label: '主体注销' },
  { value: 11, label: '服务注销' },
  { value: 12, label: '取消接入' },
  { value: 13, label: '图片变更' },
  { value: 14, label: '变更备案' },
  { value: 24, label: '升级备案' }
]

export const RECORD_TYPE_MAP = {
  ADD_RECORD: 1, //新增备案
  BODY_ADD_WEBSITE: 3, //有主体新增服务
  CHANGE_PRINCIPAL: 4, //主体变更
  CHANGE_WEBSITE: 5, //服务变更
  CHANGE_ACCESS: 6, //变更接入
  BODY_ADD_ACCESS: 7, //有主体新增接入
  NO_BODY_ADD_ACCESS: 8, //无主体新增接入
  NO_BODY_ADD_WEBSITE: 9, //无主体新增服务
  SUBJECT_LOGOUT: 10, //主体注销
  WEBSITE_LOGOUT: 11, //服务注销
  CANCEL_ACCESS: 12, //取消接入
  CHANGE_IMAGE: 13, //图片变更
  CHANGE_RECORD: 14, //变更备案
  UPGRADE_RECORD: 24 //升级备案
}

export const RECORD_STATUS = [
  { value: -3, label: '验证主办单位和服务信息', router: 'verify' },
  { value: -2, label: '主体信息', router: 'step1' },
  { value: -1, label: '服务信息', router: 'step2' },
  { value: 1, label: '上传资料', router: 'step3' },
  { value: 100, label: '信息核对', router: 'confirm' },
  { value: 101, label: '电子核验', router: 'audit' },
  { value: 102, label: '电子核验待审核', router: 'audit' },
  { value: 103, label: '电子核验审核驳回', router: 'audit' },
  { value: 104, label: '电子核验审核通过', router: 'audit' },
  { value: 2, label: '等待初审', router: 'audit' },
  { value: 3, label: '初审通过', router: 'screen' },
  { value: 4, label: '初审驳回', router: 'audit' },
  { value: 5, label: '等待审核当面核验照', router: 'audit' },
  { value: 7, label: '当面核验照审核驳回', router: 'audit' },
  { value: 11, label: '当面核验照审核通过', router: 'audit' },
  { value: 12, label: '提交管局审核', router: 'audit' },
  { value: 121, label: '提交管局审核', router: 'audit' },
  { value: 122, label: '提交管局审核', router: 'audit' },
  { value: 13, label: '管局审核通过', router: 'manage' },
  { value: 14, label: '管局审核驳回', router: 'audit' },
  { value: 15, label: '放弃本次流程', router: 'index' },
  { value: 17, label: '录入快递信息', router: 'index' },
  { value: 18, label: '运营人员操作', router: 'index' },
  { value: 19, label: '变更接入等待审核', router: 'audit' },
  { value: 20, label: '变更接入审核通过', router: 'audit' },
  { value: 21, label: '变更接入审核驳回', router: 'audit' }
]

export const OWNER_IDENTITY_TYPE = [
  { value: 2, label: '居民身份证' },
  { value: 7, label: '护照' },
  { value: 11, label: '台湾居民来往大陆通行证' },
  { value: 25, label: '军官证' },
  { value: 26, label: '外国人永久居留证' },
  { value: 27, label: '永久居留身份证' },
  { value: 28, label: '外国人永久居留身份证' },
  { value: 28, label: '港澳居民居住证' },
  { value: 29, label: '外国人永久居留身份证' },
  { value: 30, label: '港澳居民居住证' },
  { value: 31, label: '台湾居民居住证' }
]

export const REGION = [
  { value: 'cn-north-1', label: '华北-北京' },
  { value: 'cn-east-1', label: '华东-宿迁' },
  { value: 'cn-east-2', label: '华东-上海' },
  { value: 'cn-south-1', label: '华南-广州' }
]
// 授权码状态
export const AUTH_CODE_STATUE = [
  { value: '', label: '全部' },
  { value: 0, label: '待使用' },
  { value: 1, label: '备案中' },
  { value: 2, label: '已使用' },
  { value: 3, label: '已禁用' },
  { value: 4, label: '无效' }
]
// 关联服务类型
export const SERVICE_TYPE = [
  { value: 3, label: '授权码' },
  { value: 1, label: '云主机' },
  { value: 2, label: '负载均衡' }
]

export const at_2 = [
  9, // 政府机关-组织机构代码证
  23, //政府机关-统一社会信用代码证书
  3, // 事业单位-组织机构代码证
  4, // 事业单位-事业法人证书
  24, // 事业单位- 统一社会信用代码证书
  1, // 企业-营业执照
  2, // 个人-居民身份证
  7, // 个人-护照
  11, // 个人-台湾居民来往大陆通行证
  25, // 个人-军官证
  26, // 个人-港澳居民来往内地通行证
  27, // 个人-外国人永久居留证
  28, // 个人-永久居留身份证
  29, // 个人-外国人永久居留身份证
  30, // 个人-港澳居民居住证
  31, // 个人-台湾居民居住证
  6, // 社会团体-社会团体法人登记证书
  13, //民办非企业单位-组织机构代码证
  15, //基金会-组织结构代码证
  17, //律师执业机构-组织机构代码证
  22 // 群众性团体组织-统一社会信用代码证书
]

export const at_3 = [
  10, //社会团体-组织机构代码证
  14, //民办非企业单位-民办非企业单位登记证书
  32, //民办非企业单位-博物馆登记证
  33, //民办非企业单位-社会服务机构登记证书
  34, //民办非企业单位-民办学校办学许可证
  35, //民办非企业单位-博物馆登记证
  16, //基金会-基金会法人登记证书
  18, //律师执业机构-律师事务所执业许可证
  19, //国防机构-军队代号
  20, //国防机构-	有偿服务许可证
  35, //国防机构-博物馆登记证
  21, //外国在华文化中心-统一社会信用代码证书
  36, //外国在华文化中心-外国政府旅游部门常驻代表机构批准登记证
  37, //外国在华文化中心-北京市外国驻华使馆人员子女学校办学许可证
  38, //司法鉴定机构-司法鉴定许可证
  39, //境外机构-外国机构证件
  40, //医疗机构-医疗机构执业许可证
  41, //集体经济-农村集体经济组织登记证
  42, //仲裁机构-宗教活动场所登记证
  43 //仲裁机构-仲裁委员会登记证
]
// 	验证类型  0：主体负责人信息验证  1：服务负责人信息验证
export const validType = {
  OwnerZm: 0,
  FZRZm: 1
}

// 备案授权码申请类型 0:vm 1：site 2：mail
export const AUTH_CODE_TYPE = {
  VM: 0, //
  SITE: 1, //
  MAIL: 2,
  LAVM: 3,
  YUNDING: 4
}

/* 备案服务信息相关 */
export const SERVICE_INFO_WEB = 'web'
export const SERVICE_INFO_APP = 'app'
export const SERVICE_INFO_VALUE_MAP = {
  SERVICE_INFO_WEB,
  SERVICE_INFO_APP
}
// 服务信息分类
export const SERVICE_INFO_TYPE_MAP = {
  [SERVICE_INFO_WEB]: '网站',
  [SERVICE_INFO_APP]: 'APP'
}
export const SERVICE_INFO_TYPE_OPTIONS = [
  {
    label: '网站',
    value: SERVICE_INFO_WEB
  },
  {
    label: 'APP',
    value: SERVICE_INFO_APP
  }
]

/* APP 系统平台类型 */
export const SYSTEM_TYPE_ANDROID = 'Android'
export const SYSTEM_TYPE_IOS = 'Ios'
export const SYSTEM_TYPE_BLACKBERRY = 'blackberry'
export const SYSTEM_TYPE_LINUX = 'linux'
export const SYSTEM_TYPE_HARMONYOS = 'HarmonyOS'
export const SYSTEM_TYPE_OTHER = 'other'
export const SYSTEM_TYPE_VALUE_MAP = {
  SYSTEM_TYPE_ANDROID: 'Android',
  SYSTEM_TYPE_IOS: 'Ios',
  SYSTEM_TYPE_BLACKBERRY: 'blackberry',
  SYSTEM_TYPE_LINUX: 'linux',
  SYSTEM_TYPE_HARMONYOS: 'HarmonyOS',
  SYSTEM_TYPE_OTHER: 'other'
}
export const SYSTEM_TYPE_MAP = {
  [SYSTEM_TYPE_ANDROID]: '安卓',
  [SYSTEM_TYPE_IOS]: 'IOS',
  [SYSTEM_TYPE_BLACKBERRY]: '黑莓',
  [SYSTEM_TYPE_LINUX]: 'linux',
  [SYSTEM_TYPE_HARMONYOS]: '鸿蒙',
  [SYSTEM_TYPE_OTHER]: '其他平台'
}
export const SYSTEM_TYPE_OPTIONS = [
  { label: '安卓', value: SYSTEM_TYPE_ANDROID },
  { label: 'IOS', value: SYSTEM_TYPE_IOS },
  { label: '黑莓', value: SYSTEM_TYPE_BLACKBERRY },
  { label: 'linux', value: SYSTEM_TYPE_LINUX },
  { label: '鸿蒙', value: SYSTEM_TYPE_HARMONYOS },
  { label: '其他平台', value: SYSTEM_TYPE_OTHER }
]
// APP 服务内容分类
export const APP_SERVER_CONTENT_TYPE = [
  { value: 1, label: '框架类' },
  { value: 2, label: '广告类' },
  { value: 3, label: '推送类' },
  { value: 4, label: '统计类' },
  { value: 5, label: '地图类' },
  { value: 6, label: '第三方登录类' },
  { value: 7, label: '社交类' },
  { value: 8, label: '支付类' },
  { value: 9, label: '客服类' },
  { value: 10, label: '测试类' },
  { value: 11, label: '安全风控类' },
  { value: 12, label: 'Crash 监控类' },
  { value: 13, label: '人脸识别类' },
  { value: 14, label: '语音识别类' },
  { value: 15, label: '短信验证类' },
  { value: 16, label: '基础功能类' },
  { value: 17, label: '认证类' },
  { value: 18, label: '实时音视频类' },
  { value: 19, label: '性能监控类' },
  { value: 20, label: '人工智能类' },
  { value: 21, label: '平台服务类' },
  { value: 0, label: '其他' }
]

export const FREQUENCY_MAP = {
  emergencyCallFrequency: '服务负责人应急电话',
  identityNoFrequency: '服务负责人证件号码',
  mobileFrequency: '服务负责人手机号'
}

export const PtInfoItemDefault = {
  appBm: '', //安卓
  bundleId: '', //ios
  appBmbb: '', //黑莓
  hmBm: '', //鸿蒙
  processName: '', //linux进程名称
  ptBm: '', //其它平台
  md5Key: '', //签名md5
  key: '' //平台公钥
}

export const AppInfoVoDefault = {
  siteId: '',
  sdkOfferFlag: 'NO', // YES、NO
  useSdk: false,
  useSdkInfoList: [
    {
      sdkCsId: null,
      sdkFwlxId: []
    }
  ],
  appIcon: '',
  appPt: {
    azPt: {
      ptInfo: [PtInfoItemDefault],
      ymXx: [{ ym: '' }]
    },
    iosPt: {
      ptInfo: [PtInfoItemDefault],
      ymXx: [{ ym: '' }]
    },
    hmPt: {
      ptInfo: [PtInfoItemDefault],
      ymXx: [{ ym: '' }]
    },
    bbPt: {
      ptInfo: [PtInfoItemDefault],
      ymXx: [{ ym: '' }]
    },
    linuxPt: {
      ptInfo: [PtInfoItemDefault],
      ymXx: [{ ym: '' }]
    },
    otherPt: [
      {
        ptmc: '',
        ptInfo: [PtInfoItemDefault],
        ymXx: [{ ym: '' }]
      },
      {
        ptmc: '',
        ptInfo: [PtInfoItemDefault],
        ymXx: [{ ym: '' }]
      },
      {
        ptmc: '',
        ptInfo: [PtInfoItemDefault],
        ymXx: [{ ym: '' }]
      },
      {
        ptmc: '',
        ptInfo: [PtInfoItemDefault],
        ymXx: [{ ym: '' }]
      },
      {
        ptmc: '',
        ptInfo: [PtInfoItemDefault],
        ymXx: [{ ym: '' }]
      }
    ]
  },
  ptAdded: ['']
}

export const DOMAIN_REALNAME_PROVINCES = [
  11, // 北京
  32, // 江苏
  61 // 陕西
]

export const PreApprovalInfoObjectItemDefault = {
  preApprovalOption: '',
  preApprovalNo: '',
  preApprovalImages: []
}
