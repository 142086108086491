import $ from 'jquery'
export default {
  mounted() {
    var page = this.$options.page
    var hideSideMenu = this.$options.hideSideMenu
    var _loadHandle = setInterval(function() {
      if (window.Ynavi) {
        clearInterval(_loadHandle)
        if (page && page !== '') {
          window.Ynavi.setCurrPage(page)
        }

        if (hideSideMenu) {
          $('.mainMenu').hide()
          $('.subMenu').hide()
          $('.mainCon').removeClass('trans')
          $('.mainCon').css('margin-left', '0px')
          setTimeout(function() {
            $('.mainCon').addClass('trans')
          }, 300)
        } else {
          setTimeout(function() {
            var subLeft = parseInt($('.subMenu').css('marginLeft'))
            var mainLeft = $('.mainMenu').width() + $('.subMenu').width() + subLeft
            $('.mainCon').removeClass('trans')
            $('.mainCon').css('margin-left', mainLeft + 'px')
            $('.mainCon').addClass('trans')
          }, 230)
          $('.mainMenu').show()
          $('.subMenu').show()
        }
      }
    }, 100)
  }
}
