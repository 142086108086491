import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
// import loading from './modules/loading'
import record from './modules/record'
import profile from './modules/profile'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const plugins = debug ? [createLogger()] : []
export default new Vuex.Store({
  actions,
  getters,
  mutations,
  modules: {
    record,
    profile
  },
  strict: debug,
  plugins: plugins
})
