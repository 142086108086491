<template>
  <div class="recordForm">
    <rc-card title="验证备案信息"></rc-card>
    <div class="jc-page-content">
      <div class="jc-panel jc-panel-padding form-loading">
        <jc-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
          <rc-section title="主办单位信息">
            <div class="jc-box-tiny-width">
              <jc-form-item label="所在地区：" prop="region" required>
                <province-select v-model="form.region" :provinces="provinceOptions" :display="true"></province-select>
                <p slot="hint">请选择实际区域，备案成功后省份不能变更</p>
              </jc-form-item>
              <jc-form-item label="单位性质：" required>
                <jc-select v-model="form.organizersNature" placeholder="请选择" @change="organizersNatureBtn">
                  <jc-option
                    v-for="itemOption in organizersNatureOptions"
                    :key="itemOption.code"
                    :label="itemOption.name"
                    :value="itemOption.code"
                  >
                  </jc-option>
                </jc-select>
                <p slot="hint">请根据实际服务内容选择</p>
              </jc-form-item>
              <jc-form-item label="证件类型：" required>
                <jc-select
                  v-model="form.identityType"
                  placeholder="请选择"
                  class="card-type-loading"
                  @change="cardTypeBtn"
                >
                  <jc-option
                    v-for="itemOption in verifyIdentityTypeOptions"
                    :key="itemOption.code"
                    :label="itemOption.name"
                    :value="itemOption.code"
                  >
                  </jc-option>
                </jc-select>
                <p slot="hint">
                  企业-营业执照；非企业-统一社会信息代码证书；个人-中国内地居民使用身份证
                </p>
              </jc-form-item>
              <jc-form-item prop="identityNo" label="证件号码：">
                <jc-input v-model.trim="form.identityNo" type="text" placeholder="请输入证件号码"></jc-input>
              </jc-form-item>
              <jc-form-item
                prop="ownerIdentityNoValidityPeriod"
                label="证件有效期："
                v-if="form.organizersNature === 5"
              >
                <validity-period v-model="form.ownerIdentityNoValidityPeriod"></validity-period>
              </jc-form-item>
            </div>
          </rc-section>
          <rc-section title="服务信息">
            <div class="jc-box-tiny-width">
              <jc-form-item prop="appServiceType" label="服务类型：">
                <jc-radio-group
                  v-model="form.appServiceType"
                  @change="handleClearFormRule('form', ['domain', 'siteName'])"
                >
                  <jc-radio
                    v-for="serviceItem in appServiceTypeOptions"
                    :key="serviceItem.value"
                    :label="serviceItem.value"
                    >{{ serviceItem.label }}</jc-radio
                  >
                </jc-radio-group>
                <p slot="hint">请选择您需要备案的服务类型</p>
              </jc-form-item>
              <jc-form-item
                prop="domain"
                label="网站域名："
                v-if="form.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB"
              >
                <jc-input
                  v-model.trim="form.domain"
                  type="text"
                  placeholder="请输入域名，格式如：hijdcloud.com"
                  @change="toLower"
                >
                  <template slot="prepend">www.</template>
                </jc-input>
              </jc-form-item>
              <jc-form-item
                prop="siteName"
                label="APP名称："
                v-if="form.appServiceType === serviceInfoValueMap.SERVICE_INFO_APP"
              >
                <jc-input v-model.trim="form.siteName" type="text" placeholder="请输入APP名称" :maxlength="128">
                </jc-input>
                <p slot="hint">
                  APP名称请填写该APP下载安装后，显示在图标下的名称
                </p>
              </jc-form-item>
            </div>
          </rc-section>
          <rc-section title="关联服务">
            <div class="jc-box-tiny-width">
              <jc-form-item label="关联服务：" prop="recordIp">
                <div style="display: flex">
                  <jc-select
                    v-model="form.jcloudServiceType"
                    placeholder="请选择"
                    style="width: 100px"
                    :disabled="true"
                  >
                    <jc-option
                      v-for="itemOption in SERVICE_TYPE"
                      :key="itemOption.value"
                      :label="itemOption.label"
                      :value="itemOption.value"
                    >
                    </jc-option>
                  </jc-select>
                  <jc-select
                    v-model="form.recordIp"
                    @visible-change="visibleChange"
                    placeholder="请选择"
                    style="width: 240px"
                    allow-create
                    filterable
                  >
                    <jc-option
                      v-for="itemOption in recordIpList"
                      :key="itemOption.code"
                      :label="itemOption.code"
                      :value="itemOption.code"
                    >
                    </jc-option>
                  </jc-select>
                </div>

                <router-link :to="{ name: 'authCodeApply' }" target="_blank">申请授权码</router-link>
                <a :href="AGREEMENT.authCode" target="_blank" style="margin-left: 15px">授权码说明</a>
              </jc-form-item>
              <jc-form-item class="jc-form-actionItem">
                <router-link class="jc-button" :to="{ path: '/index' }">返回</router-link>
                <jc-button type="primary" @click.prevent="submitForm('form')">验证</jc-button>
              </jc-form-item>
            </div>
          </rc-section>
        </jc-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDictionary,
  saveOrganizerWebsiteInfo,
  checkDomainExist,
  checkIdentityCardExist,
  checkServiceExist,
  getAuthCodeList
} from '@/service/record-api'
import { SERVICE_TYPE, AGREEMENT, SERVICE_INFO_VALUE_MAP, SERVICE_INFO_TYPE_OPTIONS } from '@/views/service/constant'
import { VERIFY_RULE } from '@/views/service/rules'
import profileMixins from '@/mixins/profile.mixins.js'
import ValidityPeriod from '@/components/validity-period'

export default {
  data() {
    return {
      areaShow: false,
      // 验证表单data
      form: {
        region: {
          province: '',
          city: '',
          county: ''
        },
        // 单位性质
        organizersNature: 4,
        // 证件类型
        identityType: 9,
        // 证件号
        identityNo: '',
        // 有效期
        ownerIdentityNoValidityPeriod: '',
        // 备案域名
        // domain: '',
        // 服务类型
        jcloudServiceType: 3,
        recordIp: '',
        miitRecordNo: '',
        /* 新增字段 */
        appServiceType: '',
        domain: '',
        siteName: ''
      },
      cityOptions: [],
      countyOptions: [],
      verifyIdentityTypeOptions: [],
      recordIpList: [], //授权码列表
      rules: VERIFY_RULE.call(this),
      // 省市县联动开关
      isHasId: '',
      validPricipal: false,
      popoverValue: false,
      SERVICE_TYPE,
      AGREEMENT,
      mainPhylicnum: '',
      domainPhylicnum: '', // 网站备案号
      cardPhylicnum: '', // 主体备案号
      siteName: '',
      /* 新增服务类型相关 */
      // 服务类型选择列表
      serviceInfoValueMap: { ...SERVICE_INFO_VALUE_MAP },
      appServiceTypeOptions: [...SERVICE_INFO_TYPE_OPTIONS]
    }
  },
  mixins: [profileMixins],
  components: {
    ValidityPeriod,
    provinceSelect: () => import('@/views/submit/src/components/record.province.vue')
  },
  filters: {},
  async created() {
    this.organizersNatureBtn()
    this.getRecordIpList() // 查询授权码列表
  },
  methods: {
    // 保存验证
    async save() {
      try {
        let params = {
          provinceCode: this.form.region.province,
          cityCode: this.form.region.city,
          countyCode: this.form.region.county,
          organizersNature: this.form.organizersNature,
          identityType: this.form.identityType,
          identityNo: this.form.identityNo,
          ownerIdentityNoValidityPeriod: this.form.ownerIdentityNoValidityPeriod,
          jcloudServiceType: this.form.jcloudServiceType,
          serviceValue: this.form.recordIp,
          miitRecordNo: this.form.miitRecordNo || this.mainPhylicnum,
          recordType: this.recordType,
          mainPhylicnum: this.mainPhylicnum,
          /* 新增字段 */
          appServiceType: this.form.appServiceType,
          domain: this.form.domain,
          siteName: this.form.siteName
        }
        let { data } = await saveOrganizerWebsiteInfo(params, {
          isOutMsg: false
        })
        this.$message({
          message: '验证主体成功',
          type: 'success'
        })
        this.$router.push({
          name: 'step1',
          params: { recordNo: data.recordNo },
          query: {
            cardPhylicnum: this.cardPhylicnum,
            domainPhylicnum: this.domainPhylicnum,
            appServiceType: this.form.appServiceType,
            siteName: this.form.siteName
          }
        })
      } catch (err) {
        if (err.code === 2003) {
          this.$message({
            message: '此证件号已在系统中存在，请修改主体证件号',
            type: 'error'
          })
        } else {
          this.$message({
            message: err.message,
            type: 'error'
          })
          try {
            window.__sgm__.custom({
              type: 1,
              code: 'ERROR_VERIFY_RECORD',
              msg: '验证主体保存错误：' + err.message
            })
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    // 单位性质
    async organizersNatureBtn() {
      try {
        this.form.identityType = ''
        this.form.identityNo = ''
        let { data } = await getDictionary({
          code: this.form.organizersNature,
          type: 'organizersnature'
        })
        this.verifyIdentityTypeOptions = data
        this.form.identityType = this.verifyIdentityTypeOptions[0].code
      } catch (err) {
        console.log(err)
      }
    },
    cardTypeBtn() {
      this.form.identityNo = ''
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.getRecordType() // 判断接入类型
          this.save()
        } else {
          return false
        }
      })
    },
    // 域名转化成小写
    toLower(value) {
      this.form.domain = value.toLocaleLowerCase()
    },
    handleChangeCheckBox() {
      if (this.validPricipal) {
        this.popoverValue = true
      } else {
        this.popoverValue = false
      }
    },
    async getRecordType() {
      const { identityNo, identityType = '', appServiceType, domain, siteName } = this.form || {}
      const promises = [
        checkIdentityCardExist({
          identityNo: identityNo || '',
          queryType: identityType
        })
      ]
      // 验证域名
      if (appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_WEB) {
        promises.push(
          checkDomainExist({
            domain: domain || ''
          })
        )
      } else if (appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP) {
        console.log(siteName)
        promises.push(
          checkServiceExist({
            appServiceType: SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP,
            identityNo: identityNo || '',
            siteName: siteName
          })
        )
      }
      return Promise.all(promises).then(value => {
        //证件备案情况
        const {
          data: { statusSecurity: cardSecurity, mainPhylicnum: cardPhylicnum }
        } = value[0]

        // 备案0：未备案1：已备案
        // 域名备案情况 : Jd为京东 Security为工信部
        const {
          data: { statusSecurity: domainSecurity, mainPhylicnum: domainMainPhylicnum, servicePhylicnum }
        } = value[1]
        // 只用于展示的备案号
        this.domainPhylicnum = servicePhylicnum || ''
        this.cardPhylicnum = domainMainPhylicnum || cardPhylicnum || ''
        //备案类型 1新增备案 3有主体新增网站 4.备案变更 5网站变革 6变更接入 7有主体新增接入 8无主体新增接入 9无主体新增网站 10主体注销 11网站注销 12取消接入
        // 域名在工信部备案且在京东云无备案 是无主体新增接入
        if (domainSecurity) {
          this.$store.commit('ADD_RECORD_TYPE', 8)
          this.mainPhylicnum = domainMainPhylicnum
        }
        // 证件在工信部备案其它为否 是无主体新增网站
        else if (cardSecurity) {
          this.$store.commit('ADD_RECORD_TYPE', 9)
          this.mainPhylicnum = cardPhylicnum
        } else {
          this.$store.commit('ADD_RECORD_TYPE', 1)
          this.mainPhylicnum = ''
        }
      })
    },
    visibleChange(v) {
      // console.log('visibleChange', v)
      if (v) {
        this.getRecordIpList()
      }
    },
    async getRecordIpList() {
      // console.log('getRecordIpList')
      try {
        const result = await getAuthCodeList({ validFlag: 0 })
        this.recordIpList = result.data
      } catch {
        this.recordIpList = []
      }
    },
    /* 新增服务类型相关 */
    // 清除表单项校验
    handleClearFormRule(formName, formItems) {
      this.$refs[formName].clearValidate(formItems)
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {}
    },
    'form.region'(val) {
      if (!val.county) {
        this.areaShow = true
      } else {
        this.areaShow = false
      }
    }
  }
}
</script>
