<template>
  <div>
    <jc-input
      v-model="showValue"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    ></jc-input>
  </div>
</template>
<script>
import desense from '@/plugin/desensitize'
import Emitter from '@jd/element-ui/lib/mixins/emitter'
export default {
  name: 'dz-input',
  inject: {
    jcForm: {
      default: ''
    },
    JcFormItem: {
      default: ''
    }
  },
  props: {
    value: String,
    dzType: String,
    type: String,
    placeholder: String,
    disabled: Boolean
  },
  components: {},
  mixins: [Emitter],
  data() {
    return {
      realValue: '',
      dzValue: '',
      showValue: '',
      edit: false
    }
  },
  watch: {
    value(val) {
      this.realValue = val
    },
    realValue(val) {
      this.dzValue = desense({ type: this.dzType, value: this.realValue })
      this.setValue()
      this.$emit('input', val)
    },

    edit() {
      this.setValue()
    }
  },
  computed: {},
  mounted() {
    this.realValue = this.value
  },
  created() {},
  filters: {},
  methods: {
    setValue() {
      this.edit ? (this.showValue = this.realValue) : (this.showValue = this.dzValue)
    },
    handleFocus() {
      this.edit = true
    },
    handleBlur() {
      this.realValue = this.showValue
      // this.edit = false
      this.dispatch('JcFormItem', 'el.form.blur', [this.realValue])
    },
    handleChange() {
      this.realValue = this.showValue
      this.dispatch('JcFormItem', 'el.form.change', [this.realValue])
    }
  }
}
</script>
