<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <rc-step :step="0"></rc-step>
      <div class="form-loading">
        <div class="jc-panel jc-panel-padding">
          <div class="jc-section">
            <div class="jc-section-header">
              <h3 class="jc-section-title">
                <span>主办单位信息 </span>
                <!-- <span v-if="cardPhylicnum" style="margin-left:16px"
                  >主体备案号：{{ cardPhylicnum }}</span
                > -->
              </h3>
            </div>
            <div class="jc-section-content">
              <jc-form ref="form1" label-width="120px" class="jc-form-tiny-width" label-position="left">
                <jc-form-item
                  label="主体备案号："
                  prop="cardPhylicnum"
                  v-if="cardPhylicnum && isShowField('cardPhylicnum')"
                >
                  <jc-input
                    v-model.trim="cardPhylicnum"
                    type="text"
                    placeholder="主体备案号"
                    :disabled="true"
                  ></jc-input>
                </jc-form-item>
              </jc-form>
              <jc-form
                v-if="['changePrincipal'].includes(form.recordNo)"
                :model="form"
                ref="form"
                label-width="120px"
                :rules="rules"
                class="jc-form-tiny-width"
                label-position="left"
              >
                <jc-form-item label="所在地区：" prop="region">
                  <province-select
                    v-model="form.region"
                    :provinces="provinceOptions"
                    :disabled="true"
                    :display="true"
                  ></province-select>
                </jc-form-item>
                <jc-form-item label="单位性质：">
                  <jc-select
                    v-model="form.organizersNature"
                    placeholder="请选择"
                    @visible-change="deleteLock"
                    @change="getIdentityTypeOptions"
                    :disabled="recordType !== 4 && recordType !== 14"
                  >
                    <jc-option
                      v-for="item in organizersNatureOptions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    ></jc-option>
                  </jc-select>
                </jc-form-item>
                <jc-form-item label="证件类型：">
                  <jc-select
                    v-model="form.identityType"
                    placeholder="请选择"
                    :disabled="recordType !== 4 && recordType !== 14"
                  >
                    <jc-option
                      v-for="itemOption in verifyIdentityTypeOptions"
                      :key="itemOption.code"
                      :label="itemOption.name"
                      :value="itemOption.code"
                    ></jc-option>
                  </jc-select>
                </jc-form-item>
                <jc-form-item label="证件号码：" prop="identityNo">
                  <jc-input
                    v-model.trim="form.identityNo"
                    type="text"
                    placeholder="请输入证件号码"
                    :disabled="recordType !== 4 && recordType !== 14"
                  ></jc-input>
                </jc-form-item>
                <jc-form-item
                  prop="ownerIdentityNoValidityPeriod"
                  label="证件有效期："
                  v-if="role === 5 && recordType !== 4 && recordType !== 14"
                >
                  <validity-period v-model="form.ownerIdentityNoValidityPeriod"></validity-period>
                </jc-form-item>
              </jc-form>
              <component :is="principalUser" ref="principal" :infoData="infoData" labelWidth="120px"></component>
            </div>
          </div>
          <div class="jc-section">
            <div class="jc-section-header">
              <h3 class="jc-section-title">主体负责人信息</h3>
            </div>
            <div class="jc-section-content">
              <component :is="ownerUser" ref="owner" :infoData="infoData" labelWidth="120px"></component>
            </div>
          </div>
        </div>
        <div class="jc-panel jc-panel-action">
          <jc-button v-if="['changePrincipal'].includes(form.recordNo)" @click="cancel">取消</jc-button>
          <jc-button type="primary" @click="nextStep()">下一步</jc-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  saveOrganizerInfo,
  getPrincipalInfo,
  updateStatus,
  getPrincipalInfoByPin,
  newProcess,
  getDictionary,
  getFrequencyInfo,
  validOwnerInfo
} from '@/service/record-api'
import { STEP1_RULE } from '@/views/service/rules'
import profileMixins from '@/mixins/profile.mixins.js'
import { deepClone } from '@/utils'
import { validType } from '@/views/service/constant.js'
import ValidityPeriod from '@/components/validity-period'

const FREQUENCY_MAP = {
  emergencyCallFrequency: '主体负责人应急电话',
  identityNoFrequency: '主体负责人证件号码',
  mobileFrequency: '主体负责人手机号'
}
const OWNER_FREQUENCY_MAP = {
  emailValidStatus: '主体负责人邮箱',
  emergencyCallStatus: '主体负责人应急电话',
  identityValidStatus: '主体负责人证件号码',
  mobileValidStatus: '主体负责人手机号'
}

export default {
  mixins: [profileMixins],
  provide() {
    return {
      modifyIdentitype: val => val
    }
  },
  data() {
    return {
      // 验证表单data
      form: {
        recordNo: this.$route.params.recordNo,
        // 主办人信息
        region: {
          province: '',
          city: '',
          county: ''
        },
        // 单位性质
        organizersNature: '',
        // 证件类型
        identityType: '',
        // 证件号
        identityNo: '',
        // 有效期
        ownerIdentityNoValidityPeriod: '',
        // 对比证件号是否修改
        identityNoOld: ''
      },
      infoData: {},
      verifyIdentityTypeOptions: [],
      locked: true,
      rules: STEP1_RULE.call(this),
      domainPhylicnum: this.$route.query.domainPhylicnum,
      cardPhylicnum: this.$route.query.cardPhylicnum,
      appServiceType: this.$route.query.appServiceType,
      siteName: this.$route.query.siteName
    }
  },
  components: {
    ValidityPeriod,
    provinceSelect: () => import('@/views/submit/src/components/record.province.vue'),
    principalPersonal: () => import('@/views/submit/src/record.information/components/principal.personal.vue'),
    principalCommunity: () => import('@/views/submit/src/record.information/components/principal.community.vue'),
    ownerPersonal: () => import('@/views/submit/src/record.information/components/owner.personal.vue'),
    ownerCommunity: () => import('@/views/submit/src/record.information/components/owner.community.vue')
  },
  async created() {
    if (this.form.recordNo === 'changePrincipal') {
      const { recordType } = this.$route.query
      if (recordType) {
        this.$store.commit('ADD_RECORD_TYPE', parseInt(recordType))
      } else {
        this.$store.commit('ADD_RECORD_TYPE', 4)
      }
    }
    await this.getMainInfo()
    this.getIdentityTypeOptions()
  },
  computed: {
    principalUser() {
      if (this.form.organizersNature === 5) {
        return 'principalPersonal'
      } else {
        return 'principalCommunity'
      }
    },
    ownerUser() {
      if (this.form.organizersNature === 5) {
        return 'ownerPersonal'
      } else {
        return 'ownerCommunity'
      }
    }
  },
  methods: {
    deleteLock() {
      this.locked = false
    },
    // 获取证件类型
    async getIdentityTypeOptions() {
      try {
        let { data } = await getDictionary({
          code: this.form.organizersNature,
          type: 'organizersnature'
        })
        this.verifyIdentityTypeOptions = data
      } catch (err) {
        console.log(err)
      }
      if (!this.locked) {
        this.form.identityNo = ''
        this.form.ownerIdentityNoValidityPeriod = ''
        this.form.identityType = this.verifyIdentityTypeOptions[0].code
      }
    },
    // 回显主体信息
    async getMainInfo() {
      let data
      if (this.form.recordNo === 'changePrincipal') {
        // 主体变更首次查询
        let result = await getPrincipalInfoByPin()
        data = result.data
      } else {
        let result = await getPrincipalInfo({
          recordNo: this.form.recordNo
        })
        data = result.data
      }
      this.infoData = data
      this.form.region = {
        province: data.provinceCode,
        city: data.cityCode,
        county: data.countyCode
      }
      this.form.identityType = data.identityType
      this.form.identityNo = data.identityNo
      this.form.ownerIdentityNoValidityPeriod = data.ownerIdentityNoValidityPeriod
      this.form.identityNoOld = data.identityNo
      this.form.organizersNature = data.organizersNature
      this.principalId = data.id
      if (!this.$route.query.cardPhylicnum && data.miitRecordNo) {
        this.cardPhylicnum = data.miitRecordNo
      }
    },
    async getFrequencyInfo() {
      try {
        const { ownerIdentityNo, mobile, emergencyCall } = this.$refs.owner?.form || {}
        const { data } = await getFrequencyInfo({
          isPrincipal: true,
          identityNo: ownerIdentityNo,
          mobile,
          emergencyCall
        })
        const duplicateList = Object.entries(data).reduce((res, cur) => {
          const [key, value] = cur
          if (value > 0) {
            res.push(FREQUENCY_MAP[key])
          }
          return res
        }, [])
        if (duplicateList.length) {
          const duplicateTip = duplicateList.join('、')
          const msg = `您输入的${duplicateTip}已在其他账号存在备案，若继续提交有审核失败的风险`
          return this.$jcConfirm(msg, '提示', {
            confirmButtonText: '继续备案',
            cancelButtonText: '返回修改'
          })
            .then(() => {
              return true
            })
            .catch(() => {
              return false
            })
        } else {
          return true
        }
      } catch (err) {
        console.log(err)
      }
    },
    async validOwner() {
      try {
        const { identityType, identityNo, mobile, emergencyCall, email } = this.form
        const params = {
          validType: validType.OwnerZm, //验证类型
          ownerIdentityType: identityType, // 证件类型
          ownerIdentityNo: identityNo, // 证件号码
          mobile,
          emergencyCall, //应急电话
          email
        }
        // if (this.form.recordNo === 'changePrincipal') {
        params.principalId = this.principalId
        // }
        const { data } = await validOwnerInfo({ ...params })
        const duplicateList = Object.entries(data).reduce((res, cur) => {
          const [key, value] = cur
          if (value > 0) {
            res.push(OWNER_FREQUENCY_MAP[key])
          }
          return res
        }, [])
        if (duplicateList.length) {
          const duplicateTip = duplicateList.join('、')
          const msg = `您输入的${duplicateTip}已在其他账号存在备案，若继续提交有审核失败的风险`
          return this.$jcConfirm(msg, '提示', {
            confirmButtonText: '继续备案',
            cancelButtonText: '返回修改'
          })
            .then(() => {
              return true
            })
            .catch(() => {
              return false
            })
        } else {
          return true
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 提交主体信息
    async nextStep() {
      let promise = [this.$refs.principal.validate(), this.$refs.owner.validate()]
      Promise.all(promise).then(
        async result => {
          result.forEach(value => {
            Object.assign(this.form, value)
          })
          // 主体负责人/网站负责人信息验证
          const valid = await this.validOwner()
          if (!valid) return
          this.savePrincipal()
        },
        error => {
          this.$message({ type: 'error', message: '请完善页面信息' })
          console.log(error)
        }
      )
    },
    // 主体变更取消流程
    cancel() {
      this.$router.push({ name: 'manage' })
    },
    // 保存主体信息
    async savePrincipal() {
      try {
        let params = deepClone(this.form)
        // 省市县
        Object.assign(params, {
          provinceCode: params.region.province,
          cityCode: params.region.city,
          countyCode: params.region.county,
          miitRecordNo: params.miitRecordNo || this.cardPhylicnum
        })
        delete params.region
        Object.assign(params, {
          domain: this.infoData.domain,
          jcloudServiceType: this.infoData.jcloudServiceType,
          serviceValue: this.infoData.serviceValue
        })
        if (this.form.recordNo === 'changePrincipal') {
          try {
            let { data } = await newProcess(
              {
                recordNo: null,
                recordType: this.recordType,
                principal: {
                  ...params,
                  miitRecordNo: this.infoData.miitRecordNo,
                  miitRecordPwd: this.infoData.miitRecordPwd
                }
              },
              {
                isOutMsg: false
              }
            )
            this.form.recordNo = data.recordNo
          } catch (error) {
            if (error.code === 2003) {
              this.$message({
                message: '此证件号已在系统中存在，请修改主体证件号',
                type: 'error'
              })
            } else {
              this.$message({
                message: error.message,
                type: 'error'
              })
            }
            return false
          }
        } else {
          await saveOrganizerInfo(params)
        }
        // this.form.recordNo = data.recordNo
        this.outMsg('success', '保存主体成功')
        this.routerRedirect()
        // 从信息确认页进入的，直接下一步返回信息确认页
      } catch (error) {
        console.error(error)
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_SAVE_PRINCIPAL',
            msg: '保存主体错误：' + error.message
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    // 保存成功后调转地址
    async routerRedirect() {
      if (this.$route.query.step === 'confirm') {
        // 从信息确认页返回就直接返回至信息确认页
        const { data } = await updateStatus({
          recordNo: this.form.recordNo,
          status: 'confirm'
        })
        if (data) {
          this.$router.push({
            name: 'confirm',
            params: { recordNo: this.form.recordNo }
          })
        }
      } else {
        this.$router.push({
          name: 'step2',
          params: { recordNo: this.form.recordNo },
          query: {
            domainPhylicnum: this.domainPhylicnum,
            cardPhylicnum: this.cardPhylicnum,
            appServiceType: this.appServiceType,
            siteName: this.siteName
          }
        })
      }
    }
  }
}
</script>
