import Vue from 'vue'
import { isNull, filterArray } from '@/utils'
import { RECORD_TYPE, RECORD_STATUS, SERVER_CONTENT_TYPE, PRE_APPROVAL_OPTION } from '@/views/service/constant.js'
import desense from '@/plugin/desensitize'
import moment from 'moment'
Vue.filter('limiteStr', (str, count) => {
  if (isNull(str)) {
    return '-'
  } else if (str.length > count) {
    return str.slice(0, count) + '...'
  } else {
    return str
  }
})

Vue.filter('recordTypeFilter', str => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, RECORD_TYPE, 'value')
  return result ? result.label : str
})

Vue.filter('recordStatusFilter', str => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, RECORD_STATUS, 'value')
  return result ? result.label : str
})

Vue.filter('timeFilter', val => {
  if (isNull(val)) {
    return '-'
  }
  return moment(val).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('organizersNatureFilter', (str, arrayOption) => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, arrayOption, 'code')
  return result ? result.name : str
})

Vue.filter('identityTypeFilter', (str, arrayOption) => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, arrayOption, 'code')
  return result ? result.name : str
})

Vue.filter('siteTypeFilter', str => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, SERVER_CONTENT_TYPE, 'value')
  return result ? result.label : str
})

Vue.filter('preApprovalFilter', str => {
  if (isNull(str)) {
    return '-'
  }
  const result = filterArray(str, PRE_APPROVAL_OPTION, 'value')
  return result ? result.label : str
})

Vue.filter('languageFilter', (str, arrayOption) => {
  if (isNull(str)) {
    return '-'
  }
  const result = str.split(',').map(item => {
    let selected = arrayOption.find(val => val.code === item)
    return selected ? selected.name : item
  })
  return result.join(',')
})

Vue.filter('appServiceTypeFilter', (str, arrayOption) => {
  if (isNull(str)) {
    return '-'
  }
  const result = str.split(',').map(item => {
    let selected = arrayOption.find(val => val.code === item)
    return selected ? selected.name : item
  })
  return result.join(',')
})

Vue.filter('listFilter', (value, list, key = 'value', show = 'label') => {
  if (!isNull(value) && list && list.length > 0) {
    if (!isNull(filterArray(value, list, key))) {
      return filterArray(value, list, key)[show]
    }
  }
  return '-'
})

Vue.filter('emptyFilter', value => {
  if (isNull(value)) {
    return '--'
  }
  return value
})

Vue.filter('desenseFilter', (value, type) => {
  if (isNull(value)) {
    return '--'
  }
  return desense({ type, value })
})

Vue.filter('validityPeriodFilter', value => {
  if (isNull(value)) {
    return '--'
  }
  const valArr = value.split('-') || []
  const startTime = valArr[0] ? moment(valArr[0]).format('YYYY年MM月DD日') : ''
  const endTime = valArr[1] ? (valArr[1] === '长期' ? valArr[1] : moment(valArr[1]).format('YYYY年MM月DD日')) : ''
  return startTime && endTime ? `${startTime} 至 ${endTime}` : '--'
})

Vue.filter('NumberFilter', value => {
  if (isNull(value) || typeof value !== 'number') {
    return '--'
  }
  return value
})
