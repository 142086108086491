import Vue from 'vue'
import Router from 'vue-router'
import App from '@/views/app.vue'
import entryRouter from '@/views/entry'
import otherRouter from '@/views/other'
import submitRouter from '@/views/submit'
import authCodeRouter from '@/views/authCode'
import { continueRecord } from '@/service/record-api'
import { validateSchedule } from '@/router/toCurrentPage'
import { getDictionaryAll, getByType, queryUpgradeInfo } from '@/service/record-api'
import store from '@/stores'
import moment from 'moment'

Vue.use(Router)

// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }

let routes = []

let rootRouter = {
  path: '/',
  component: App,
  children: []
}

let redirectRouter = {
  path: '*',
  redirect: '/404'
}

entryRouter(rootRouter.children)
otherRouter(rootRouter.children)
submitRouter(rootRouter.children)
authCodeRouter(rootRouter.children)
const router = new Router({
  mode: 'history',
  routes: routes.concat([rootRouter, redirectRouter])
})

router.beforeEach(async (to, from, next) => {
  let upgradeInfo = store.state.profile.upgradeInfo
  try {
    if (!upgradeInfo.fetched) {
      const { data } = await queryUpgradeInfo({})
      upgradeInfo = {
        ...data,
        fetched: true
      }
      store.commit('ADD_UPGRADE_INFO', upgradeInfo)
    }
    if (
      upgradeInfo.status === 'ONLINE' &&
      upgradeInfo.effectTimeBegin &&
      upgradeInfo.effectTimeEnd &&
      moment().isBetween(upgradeInfo.effectTimeBegin, upgradeInfo.effectTimeEnd)
    ) {
      if (to.path === '/disabled') {
        return next()
      }
      return next('/disabled')
    } else {
      if (to.path === '/disabled') {
        return next('/index')
      }
    }
  } catch (error) {
    console.log(error)
  }

  console.log(store)
  if (store.state.profile.identityTypeOptions.length === 0) {
    let { data } = await getDictionaryAll()
    store.commit('ADD_IDENTI_TYPE_OPTION', data[0])
    store.commit('ADD_PROVINCE_OPTION', data[1])
    // store.commit('ADD_SERVICE_TYPE_OPTION', data[2])
    store.commit('ADD_ORGANIZERS_NATURE_OPTION', data[3])
    store.commit('ADD_PREAPPROVAL_OPTIONS', data[5])
    store.commit('ADD_LANGUAGE_OPTIONS', data[6])
    store.commit('ADD_APP_SERVICE_TYPE_OPTIONS', data[8])
  }
  if (store.state.profile.sdkCsOptions.length === 0) {
    try {
      const [sdkCsOptions, sdkFwlxOptions, appTypeOptions] = await Promise.all([
        getByType({ type: 22 }),
        getByType({ type: 23 }),
        getByType({ type: 24 })
      ])
      store.commit('ADD_BY_TYPE_OPTIONS', {
        sdkCsOptions: sdkCsOptions.data,
        sdkFwlxOptions: sdkFwlxOptions.data,
        appTypeOptions: appTypeOptions.data
      })
    } catch (error) {
      console.log(error)
    }
  }
  if (to.meta.validator) {
    if (
      // ['changePrincipal', 'changeSite', 'changeSiteIp'].includes(
      //   to.params.recordNo
      // )
      /changePrincipal|changeSite|changeSiteIp|site|principal|insert/g.test(to.params.recordNo)
    ) {
      store.commit('ADD_RECORD_TYPE', '')
      return next()
    }
    try {
      let { result } = await continueRecord({ recordNo: to.params.recordNo })
      if (result.status) {
        store.commit('ADD_ROLE', result.organizersNature)
        store.commit('ADD_RECORD_TYPE', result.recordType)
        store.commit('ADD_ELEFLAG', result.eleFlag)
        if (to.meta.order) {
          let redirectRouterName = validateSchedule(result, to.name)
          if (redirectRouterName === to.name) {
            next()
          } else {
            next({
              name: redirectRouterName,
              params: {
                recordNo: to.params.recordNo
              },
              query: { ...to.query }
            })
          }
        } else {
          next()
        }
      } else {
        // 不允许进入
        next('/manageList')
      }
    } catch (error) {
      console.log(error)
      next('/index')
    }
  } else {
    next()
  }
})
export default router
