import { isFit } from '../utils'
/**
 * 【地址】最后6位掩码
 * @param {String} address
 */
function address(address) {
  if (!isFit(address)) return ''

  if (typeof address === 'number') {
    address = String(address)
  }

  address = address.length > 6 ? address.slice(0, -6) + '******' : address

  return address
}
export default address
