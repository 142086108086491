import ApplyList from './src/ApplyList.vue'
import ManageList from './src/ManageList.vue'

export default function(router) {
  router.push({
    path: '/authCode/apply',
    name: 'authCodeApply',
    component: ApplyList
  })

  router.push({
    path: '/authCode/manage',
    name: 'authCodeManage',
    component: ManageList
  })
}
