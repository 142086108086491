<template>
  <div>
    <rc-card title="备案授权码申请">
      <a slot="link" :href="AGREEMENT.introduction" target="_blank">备案帮助文档</a>
    </rc-card>
    <jc-alert type="warning" title="您在京东云的资源满足以下条件之一方可提交备案申请:" style="margin-bottom:0px">
      <p slot>
        1、您京东云的云主机或轻量云主机有效期剩余时长需大于3个月（包含续费资源）;
      </p>
      <p slot>2、您京美建站的资源有效期剩余时长大于9个月；</p>
      <p slot>3、您京东云邮箱有效期剩余时长大于9个月方可提交备案申请。</p>
    </jc-alert>
    <div class="jc-page-content">
      <div class="jc-container-table">
        <jc-tabs v-model="activeName.name" type="border-card">
          <jc-tab-pane v-for="(key, value) in TABS" :key="value" :name="value" :label="TAB_NAME[value]" />
          <transition name="fade" mode="out-in">
            <keep-alive>
              <component :is="productList" />
            </keep-alive>
          </transition>
        </jc-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import VMList from '@/views/authCode/src/components/VMList'
import SiteList from '@/views/authCode/src/components/SiteList'
import EmailList from '@/views/authCode/src/components/EmailList'
import LavmList from '@/views/authCode/src/components/LavmList'
import YundingList from '@/views/authCode/src/components/YundingList'
import { AGREEMENT } from '@/views/service/constant'
import { reactive, computed } from '@vue/composition-api'
export default {
  components: {
    VMList,
    SiteList,
    EmailList,
    LavmList,
    YundingList
  },
  setup() {
    const TABS = {
      vm: 'VMList', // 云主机
      lavm: 'LavmList', // 轻量云主机
      site: 'SiteList', //京美建站
      email: 'EmailList', //京东云邮箱
      yunding: 'YundingList'
    }
    const TAB_NAME = {
      vm: '云主机', // 云主机
      lavm: '轻量云主机', // 轻量云主机
      site: '京美建站', //京美建站
      email: '京东云邮箱', //京东云邮箱
      yunding: '云鼎-云主机'
    }
    const activeName = reactive({ name: 'vm' })
    const productList = computed(() => {
      return TABS[activeName.name]
    })
    return {
      AGREEMENT,
      TABS,
      TAB_NAME,
      activeName,
      productList
    }
  }
}
</script>
<style lang="css" scoped></style>
