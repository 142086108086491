<template>
  <div class="jc-page-header">
    <h1>
      <a href="#" @click="goBack"> <i class="jc-icon-my-angle-left-bold"></i> </a>{{ title }}
      <small v-if="childrenTitle">{{ childrenTitle }}</small>
    </h1>
    <div :class="statusClass" v-if="statusLabel">{{ statusLabel }}</div>
    <jc-popover
      ref="popover2"
      placement="top-start"
      :title="popoverTitle"
      width="200"
      trigger="hover"
      :content="popoverContent"
    ></jc-popover>
    <a href="#" @click="goIntroduce" class="jc-icon-my-help" v-if="popoverTitle || popoverContent"></a>
    <div class="jc-box-action">
      <a :href="linkAddress">{{ linkMessage }}</a>
      <slot name="children-card-tail"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rc-children-card',
  props: {
    title: {
      // 一级标题
      require: true,
      type: [String, Number]
    },
    childrenTitle: [String, Number], // 二级标题
    statusLabel: [String, Number], // 状态说明
    statusLevel: {
      // 状态等级
      type: String,
      default: 'success'
    },
    popoverTitle: [String, Number], // 说明标题
    popoverContent: [String, Number], // 说明内容
    linkAddress: {
      // 跳转地址
      type: String
    },
    linkMessage: {
      // 跳转说明
      type: String
    }
  },
  components: {},
  mixins: [],
  data() {
    return {}
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    statusClass() {
      switch (this.statusLevel) {
        case 'success': {
          return 'jc-status jc-status-success'
        }
        case 'progress': {
          return 'jc-status jc-status-progress'
        }
        case 'danger': {
          return 'jc-status jc-status-danger'
        }
        case 'warning': {
          return 'jc-status jc-status-warning'
        }
      }
    }
  },
  filters: {},
  methods: {
    goBack() {
      // 返回上级@back
      this.$emit('back')
    },
    goIntroduce() {
      // 跳转至简介 @introduce
      this.$emit('introduce')
    }
  }
}
</script>
