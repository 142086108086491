const PERSON_ROLE = 5
const OTHER_ROLE = 'X'

const hiddenFieldKey = (fieldName, role) => {
  role = role == PERSON_ROLE ? PERSON_ROLE : OTHER_ROLE
  return `${fieldName}--${role}`
}

// 流程隐藏字段： https://joyspace.jd.com/sheets/AWxkODXmi4tMlXC3Juzv
export const HIDDEN_FIELDS = {
  /**
   * 主体信息页
   */
  // 主体备案号
  'cardPhylicnum--X': [1],
  'cardPhylicnum--5': [1],
  // 投资者或单位主管
  'investorName--X': [8],
  'investorName--5': [1, 4, 8, 9, 14],
  // 证件地址
  'indentityAddress--X': [8],
  'indentityAddress--5': [8],
  // 通信地址
  'detailAddress--X': [8],
  'detailAddress--5': [8],
  // 主体负责人姓名
  'ownerName--X': [],
  'ownerName--5': [4, 8, 9, 14],
  // 证件类型
  'ownerIdentityType--X': [],
  'ownerIdentityType--5': [4, 8, 9, 14],
  // 证件号码
  'ownerIdentityNo--X': [],
  'ownerIdentityNo--5': [4, 8, 9, 14],
  // 办公电话
  'principalPhone--X': [],
  'principalPhone--5': [1, 4, 8, 9, 14],
  // 主体备注信息
  'principalRemark--X': [8, 9],
  'principalRemark--5': [8, 9],
  /**
   * 网站信息页
   */
  // 网站备案号
  'domainPhylicnum--X': [1, 9],
  'domainPhylicnum--5': [1, 9],
  // 应用服务类型
  'appServiceType--X': [8],
  'appServiceType--5': [8],
  // 主网站服务内容
  'siteType--X': [8],
  'siteType--5': [8],
  // 前置审批选项
  'preApprovalOption--X': [8],
  'preApprovalOption--5': [8],
  // 语言类别
  'siteLanguage--X': [8],
  'siteLanguage--5': [8],
  /**
   * 上传资料页
   */
  // 域名证书
  'domainCertificate--X': [4],
  'ddomainCertificate--5': [4],
  // 主体辅助材料
  'principalOther--X': [5, 24],
  'principalOther--5': [5, 24],
  // 主体负责人辅助材料
  'principalOwnerOther--X': [5, 24],
  'principalOwnerOther--5': [1, 3, 4, 5, 7, 8, 9, 14, 24],
  // 网站辅助材料
  'siteOther--X': [4],
  'siteOther--5': [4],
  // 网站负责人辅助材料
  'siteOwnerOther--X': [4],
  'siteOwnerOther--5': [1, 3, 4, 5, 7, 8, 9, 14, 24],
  /**
   * 活体核验
   */
  // 主体证件
  'principalIdentity--X': [5, 24],
  'principalIdentity--5': [5, 24],
  // 主体负责人证件
  'principalOwnerIdentity--X': [5, 24],
  'principalOwnerIdentity--5': [5, 24],
  // 网站负责人证件
  'siteOwnerIdentity--X': [4],
  'siteOwnerIdentity--5': [4],
  // 活体照片
  'videoImg--X': [4],
  'videoImg--5': [4]
}

// role 主体证件类型 5 个人 其他 非个人
export const isShowField = (fieldName, recordType, role = PERSON_ROLE) => {
  const recordTypes = HIDDEN_FIELDS[hiddenFieldKey(fieldName, role)]

  if (!recordTypes || recordTypes.length === 0) {
    return true
  }

  if (recordTypes.indexOf(parseInt(recordType)) === -1) {
    return true
  }

  return false
}
