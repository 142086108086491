<template>
  <div class="recordForm">
    <div class="jc-page-content">
      <jc-alert type="warning" :closable="false" show-icon>
        <template slot="title">
          <div>
            <p>
              请使用京东云APP扫描二维码进行拍照上传，<a
                href="javascript:;"
                class="blueLink"
                @click="dialogVisibleDownload = true"
                >立即下载APP</a
              >
            </p>
          </div>
        </template>
      </jc-alert>
      <rc-step :step="3"></rc-step>
      <div class="jc-panel jc-panel-padding form-loading">
        <jc-form :model="form" :rules="rules" ref="form" label-width="140px">
          <rc-section
            :title="principalInfo.identityType | listFilter(identityTypeOptions, 'code', 'name')"
            v-if="eleOrganizers && isShowField('principalIdentity')"
          >
            <span slot="add">
              主办单位名称：{{ principalInfo.organizersName }}&nbsp;&nbsp; 证件类型：{{
                principalInfo.identityType | listFilter(identityTypeOptions, 'code', 'name')
              }}&nbsp;&nbsp; 证件号码：{{ principalInfo.identityNo }}
            </span>
            <div v-for="(item, index) in form.license" :key="'lience' + index" label-width="0">
              <jc-form-item :prop="'license.' + index" :rules="atRule('lisence', index)">
                <span slot="label">
                  <span>{{ principalInfo.identityType | listFilter(identityTypeOptions, 'code', 'name') }}</span>
                </span>
                <jc-row>
                  <jc-col :span="3">
                    <rc-upload v-model="form.license[index]" :uploadData="uploadData('licenseData', index)"></rc-upload>
                  </jc-col>
                  <jc-col :span="2">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="handleQrCodeParams('licenseData', principalInfo.identityType)"
                      >{{ form.license[index].imgUrl ? '重新拍照' : '立即拍照' }}</jc-button
                    >
                  </jc-col>
                  <jc-col :span="16" :offset="2">
                    <upload-example
                      class="img-type-img2"
                      :showUrl="imgExample[`imgtype_${principalInfo.identityType}`]"
                      :largeUrl="imgExample[`imgtypeL_${principalInfo.identityType}`]"
                    ></upload-example>
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </div>
            <jc-form-item>
              <p slot="hint">
                1. 请您上传清晰、无污物、完整的证件原件彩色扫描件<br />
                2. 证件必须在有效期内<br />
                <!-- <jc-checkbox v-model="agreeForm.agreement">
                  我已阅读并授权京东云查询企业征信信息
                  <a :href="agreement.companyLicense" target="_blank">《企业征信授权条款》</a>
                </jc-checkbox> -->
              </p>
            </jc-form-item>
          </rc-section>
          <rc-section
            title="主体信息"
            class="type-img-warp"
            v-if="
              (principalInfo.ownerIdentityType === 2 || principalInfo.ownerIdentityType === 7) &&
                isShowField('principalOwnerIdentity')
            "
          >
            <span slot="add">
              <!-- 主单位负责人：{{principalInfo.ownerName|desenseFilter('name')}}&nbsp;&nbsp; -->
              <!-- 证件号码：{{principalInfo.ownerIdentityNo|desenseFilter('idCard')}} -->
              主单位负责人：{{ principalInfo.ownerName }}&nbsp;&nbsp; 证件号码：{{ principalInfo.ownerIdentityNo }}
            </span>
            <div v-for="(item, index) in form.owner" :key="'owner' + index" label-width="0">
              <jc-form-item :prop="'owner.' + index" :rules="atRule('owner', index)">
                <span slot="label">
                  <span>{{ principalInfo.ownerIdentityType | listFilter(identityTypeOptions, 'code', 'name') }}</span
                  ><span>&nbsp;附件{{ index + 1 }}:</span>
                </span>
                <jc-row v-if="principalInfo.ownerIdentityType === 2 && (role === 5 || !IS_OPEN_PC_UPLOAD_IDCARD)">
                  <jc-col :span="3">
                    <div class="avatar-uploader">
                      <img
                        v-if="form.owner[index].imgUrl"
                        :src="form.owner[index].imgUrl"
                        class="avatar"
                        style="width: 96px; height: 96px"
                      />
                      <i
                        v-else
                        class="jc-icon-plus avatar-uploader-icon"
                        @click="handleQrCodeParams('ownerData', principalInfo.ownerIdentityType)"
                      ></i>
                    </div>
                  </jc-col>
                  <jc-col :span="2" v-if="form.owner[index].imgUrl">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="handleQrCodeParams('ownerData', principalInfo.ownerIdentityType)"
                      >重新拍照</jc-button
                    >
                  </jc-col>
                </jc-row>

                <jc-row v-else-if="IS_OPEN_PC_UPLOAD_IDCARD && principalInfo.ownerIdentityType === 2 && role !== 5">
                  <jc-col :span="3">
                    <rc-upload
                      v-model="form.owner[index]"
                      :uploadData="uploadData('ownerDataWithOcrCheck', index)"
                    ></rc-upload>
                  </jc-col>
                  <jc-col :span="2">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="handleQrCodeParams('ownerData', principalInfo.ownerIdentityType)"
                      >{{ form.owner[index].imgUrl ? '重新拍照' : '立即拍照' }}</jc-button
                    >
                  </jc-col>
                </jc-row>

                <jc-row v-else>
                  <jc-col :span="3">
                    <rc-upload v-model="form.owner[index]" :uploadData="uploadData('ownerData', index)"></rc-upload>
                  </jc-col>
                  <jc-col :span="2">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="handleQrCodeParams('ownerData', principalInfo.ownerIdentityType)"
                      >{{ form.owner[index].imgUrl ? '重新拍照' : '立即拍照' }}</jc-button
                    >
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </div>

            <upload-example
              class="img-type-img"
              :showUrl="imgExample[`imgtype_${principalInfo.ownerIdentityType}`]"
              :largeUrl="imgExample[`imgtypeL_${principalInfo.ownerIdentityType}`]"
            ></upload-example>
          </rc-section>
          <jc-form-item
            v-if="
              IS_OPEN_PC_UPLOAD_IDCARD &&
                principalInfo.ownerIdentityType === 2 &&
                role !== 5 &&
                isShowField('principalOwnerIdentity')
            "
          >
            <p slot="hint" style="margin-bottom: 20px">
              文件大小不低于100K，分辨率不能低于1280*720，必须为彩色，并在有效期内<br />
            </p>
          </jc-form-item>

          <div v-for="(item, index) in siteInfo" :key="item.id">
            <rc-section
              v-if="(item.identityType === 2 || item.identityType === 7) && isShowField('siteOwnerIdentity')"
              :title="`服务负责人${getIdentityType(item.identityType)}上传`"
            >
              <span slot="add">
                服务：{{ item.siteName }}&nbsp;&nbsp;服务负责人：{{ item.ownerName }}&nbsp;&nbsp; 证件号码：{{
                  item.identityNo
                }}
              </span>
              <div class="type-img-warp">
                <div
                  v-for="(owner, ownerIndex) in form.siteOwner[index].owner"
                  :key="'siteowner' + ownerIndex"
                  label-width="0"
                >
                  <jc-form-item
                    :prop="'siteOwner.' + index + '.owner.' + ownerIndex"
                    :rules="atRule('siteOwner', index, ownerIndex)"
                  >
                    <span slot="label">
                      <span>{{ item.identityType | listFilter(identityTypeOptions, 'code', 'name') }}</span
                      ><span>&nbsp;附件{{ ownerIndex + 1 }}:</span>
                    </span>
                    <jc-row v-if="item.identityType === 2">
                      <jc-col :span="3">
                        <div class="avatar-uploader">
                          <img
                            v-if="form.siteOwner[index].owner[ownerIndex].imgUrl"
                            :src="form.siteOwner[index].owner[ownerIndex].imgUrl"
                            class="avatar"
                            style="width: 96px; height: 96px"
                          />
                          <i
                            v-else
                            class="jc-icon-plus avatar-uploader-icon"
                            @click="handleQrCodeParams('siteOwnerData', item.identityType, index)"
                          ></i>
                        </div>
                      </jc-col>
                      <jc-col :span="2" v-if="form.siteOwner[index].owner[ownerIndex].imgUrl">
                        <jc-button
                          class="uploader-btn"
                          type="text"
                          @click="handleQrCodeParams('siteOwnerData', item.identityType, index)"
                          >重新拍照</jc-button
                        >
                      </jc-col>
                    </jc-row>
                    <jc-row v-else>
                      <jc-col :span="3">
                        <rc-upload
                          v-model="form.siteOwner[index].owner[ownerIndex]"
                          :uploadData="uploadData('siteOwnerData', index, ownerIndex)"
                        ></rc-upload>
                      </jc-col>
                      <jc-col :span="2">
                        <jc-button
                          class="uploader-btn"
                          type="text"
                          @click="handleQrCodeParams('siteOwnerData', item.identityType, index)"
                          >{{ form.siteOwner[index].owner[ownerIndex].imgUrl ? '重新拍照' : '立即拍照' }}</jc-button
                        >
                      </jc-col>
                    </jc-row>
                  </jc-form-item>
                </div>
                <upload-example
                  class="img-type-img2"
                  :showUrl="imgExample[`imgtype_${item.identityType}`]"
                  :largeUrl="imgExample[`imgtypeL_${item.identityType}`]"
                ></upload-example>
              </div>
              <jc-form-item :prop="'siteOwner.' + index + '.videoImg'" :rules="atRule()" v-if="isShowField('videoImg')">
                <span slot="label">
                  <span>视频核身</span>
                </span>
                <jc-row>
                  <jc-col :span="3">
                    <div class="avatar-uploader">
                      <img
                        v-if="form.siteOwner[index].videoImg.imgUrl"
                        :src="form.siteOwner[index].videoImg.imgUrl"
                        class="avatar"
                        style="width: 96px; height: 96px"
                      />
                      <i
                        v-else
                        class="jc-icon-plus avatar-uploader-icon"
                        @click="handleQrCodeParams('siteOwnerData', item.identityType, index)"
                      ></i>
                    </div>
                  </jc-col>
                  <jc-col :span="2" v-if="form.siteOwner[index].videoImg.imgUrl">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="handleQrCodeParams('siteOwnerData', item.identityType, index)"
                      >重新拍照</jc-button
                    >
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </rc-section>
            <rc-section
              title="视频核身"
              v-if="item.identityType !== 2 && item.identityType !== 7 && isShowField('videoImg')"
            >
              <span slot="add">
                服务：{{ item.siteName }}&nbsp;&nbsp;服务负责人：{{ item.ownerName }}&nbsp;&nbsp;
              </span>
              <jc-form-item :prop="'siteOwner.' + index + '.videoImg'" :rules="atRule()">
                <jc-row>
                  <jc-col :span="3">
                    <div class="avatar-uploader">
                      <img
                        v-if="form.siteOwner[index].videoImg.imgUrl"
                        :src="form.siteOwner[index].videoImg.imgUrl"
                        class="avatar"
                        style="width: 96px; height: 96px"
                      />
                      <i
                        v-else
                        class="jc-icon-plus avatar-uploader-icon"
                        @click="
                          handleQrCodeParams(
                            'videoData',
                            3, // h5单视频核验
                            index
                          )
                        "
                      ></i>
                    </div>
                  </jc-col>
                  <jc-col :span="2" v-if="form.siteOwner[index].videoImg.imgUrl">
                    <jc-button
                      class="uploader-btn"
                      type="text"
                      @click="
                        handleQrCodeParams(
                          'videoData',
                          3, // h5单视频核验
                          index
                        )
                      "
                      >重新拍照</jc-button
                    >
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </rc-section>
          </div>
        </jc-form>
      </div>
      <div class="jc-panel jc-panel-action">
        <jc-button @click="back">
          上一步
        </jc-button>
        <jc-button type="primary" @click.prevent="nextStep" id="noPersonStepThirdVerifyNext">下一步</jc-button>
      </div>
    </div>
    <jc-dialog :visible.sync="dialogVisible" custom-class="app-qr-dialog">
      <div class="app-qr-dialog-title">使用京东云APP进行上传资料</div>
      <div class="app-qr-dialog-imgs">
        <img style="width:200px" src="~assets/images/app-qr.png" />
        <img style="width:40px" src="~assets/images/app-dialog-arrow.png" />
        <img style="width:150px" src="~assets/images/app-1.png" />
        <div class="qrCodeImg">
          <div v-if="isTimeout">
            <div class="qrCode-mask"></div>
            <div class="qrCode-refresh" @click="refreshQrCode">
              <i class="jc-icon-refresh-line"></i>
              <p>刷新</p>
            </div>
          </div>
          <img :src="qrCodeImg" />
          <p style="color: red" v-if="isTimeout">二维码已经过期，请刷新重试</p>
        </div>
        <img style="width:40px" src="~assets/images/app-dialog-arrow.png" />
        <img style="width:150px" src="~assets/images/app-2.png" />
      </div>
      <div class="app-qr-dialog-descs">
        <div class="desc-item">
          <div class="desc-item-num">1</div>
          <div class="desc-item-txt">下载京东云APP</div>
          <div class="desc-item-txt"></div>
        </div>
        <div class="desc-item">
          <div class="desc-item-num">2</div>
          <div class="desc-item-txt">使用京东云APP进行扫码</div>
          <div class="desc-item-txt">Android APP需要2.5.3及以上版本</div>
          <div class="desc-item-txt">iOS APP需要2.5.2及以上版本</div>
        </div>
        <div class="desc-item">
          <div class="desc-item-num">3</div>
          <div class="desc-item-txt">
            在京东云APP进行资料上传和视频核身 APP端未操作完前，请不要关闭该弹窗
          </div>
          <div class="desc-item-txt"></div>
        </div>
      </div>
    </jc-dialog>
    <jc-dialog :visible.sync="dialogVisibleDownload" custom-class="downloadDialog">
      <div class="downloadCodeImg">
        <img src="~assets/images/app_download.png" />
        <p>下载京东云APP</p>
      </div>
    </jc-dialog>
  </div>
</template>
<script>
import { IMG_LINK, AGREEMENT, at_3, RECORD_TYPE_MAP } from '@/views/service/constant.js'
import {
  getAttachmentInfo,
  getSiteInfo,
  getPrincipalInfo,
  generateQrCode,
  getSubmitStatus,
  queryEleCheck
} from '@/service/record-api'
import { UPLOAD_FIRST_TRIAL_RUlE } from '@/views/service/rules'
import apiParams from '@/mixins/upload.mixins.js'
import { OWNER_IDENTITY_TYPE } from '@/views/service/constant'
import profileMixins from '@/mixins/profile.mixins.js'
import { atSort } from '@/utils'
export default {
  props: {},
  mixins: [apiParams, profileMixins],
  components: {
    uploadExample: () => import('@/views/submit/src/record.information/components/upload.example.vue')
  },
  data() {
    return {
      IS_OPEN_PC_UPLOAD_IDCARD: false,
      recordNo: this.$route.params.recordNo || '',
      type: this.$route.query.type || '', // 目前有changeImage变更图片
      imgExample: IMG_LINK,
      form: {
        license: [],
        owner: [],
        ownerFront: {
          // 主单位负责人身份证上传 正面
          imgUrl: '',
          attachmentId: ''
        },
        ownerOpposite: {
          // 主单位负责人身份证上传 反面
          imgUrl: '',
          attachmentId: ''
        },
        commitmentPaper: {
          // 承诺书
          imgUrl: '',
          attachmentId: ''
        },
        // 服务负责人身份证上传 真实核验单
        siteOwner: [],
        // 域名证书,
        domains: [],
        otherInfo: [
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' }
        ] //其他辅助材料
      },
      qrCodeImg: '',
      dialogVisible: false,
      qrCodeTime: null,
      validateTime: null,
      isTimeout: false,
      getQrCodeParams: {},
      rules: UPLOAD_FIRST_TRIAL_RUlE.call(this),
      // rules: {},
      siteInfo: [],
      domainInfo: [],
      principalInfo: {},
      attachmentInfo: {},
      agreement: AGREEMENT,
      RECORD_TYPE_MAP,
      traceId: '',
      isUpdate: false,
      dialogVisibleDownload: false,
      agreeForm: {
        agreement: false
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        clearInterval(this.validateTime)
      }
    }
  },
  computed: {
    ownerIdentityType() {
      let identityType = this.principalInfo.ownerIdentityType || 2
      return OWNER_IDENTITY_TYPE.find(val => val.value === identityType).label
    },
    eleOrganizers() {
      return (this.role !== 5 && this.principalInfo.identityType === 1) || this.principalInfo.identityType === 4
      // 电子核验主办单位证件1(营业执照)4(事业法人证书)需拍照上传
    }
  },
  mounted() {},
  created() {
    this.init()
  },
  filters: {},
  methods: {
    // 上传附件参数
    uploadData(type, index, ownerIndex) {
      // type: 1：主体证件 2：主体负责人证件3：服务负责人证件4：服务真实性核验单5：幕布照片6：域名证书
      // 7：前置审批号附件8：活体照片9：承诺书10：活体视频11：其他辅助资料上传12：现场核验照片
      switch (type) {
        case 'licenseData': {
          // 工商执照
          return this.apiParams({
            attachmentType: 1,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.license[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'ownerData': {
          // 主单位负责人证件 正面
          return this.apiParams({
            attachmentType: 2,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.owner[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'ownerDataWithOcrCheck': {
          // 主单位负责人证件 正面
          return this.apiParams({
            uploadPath: 'uploadfileWithOcrCheck',
            attachmentType: 2,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.owner[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'siteOwnerData': {
          // 服务负责人证件
          return this.apiParams({
            attachmentType: 3,
            recordNo: this.recordNo,
            sortNum: ownerIndex + 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].owner[ownerIndex].attachmentId,
            recordType: this.recordType
          })
        }
      }
    },
    async init() {
      // 初始化服务相关上传内容
      const promise = [this.getSiteInfo(), this.getPrincipalInfo(), this.getAttachmentInfo()]
      await Promise.all(promise)
      Object.keys(this.attachmentInfo).forEach(att => {
        // 主体、主体负责人、服务负责人 辅助材料sortNum 345 服务辅助材料sortNum 123
        let attaInfo = this.attachmentInfo[att].filter(item => item.sortNum <= 2)
        // console.log('attaInfo', attaInfo)
        if (attaInfo[0]) {
          switch (att) {
            case 'attachmentUrlYYZZZm': {
              //营业执照
              let arr = [...attaInfo]
              arr = atSort(arr)
              arr.forEach((val, index) => {
                this.form.license.splice(index, 1, {
                  attachmentId: val.id,
                  imgUrl: val.url
                })
              })
              break
            }
            case 'attachmentUrlOwnerZm': {
              // 主体负责人
              let arr = [...attaInfo]
              arr = atSort(arr)
              arr.forEach((val, index) => {
                this.form.owner.splice(index, 1, {
                  attachmentId: val.id,
                  imgUrl: val.url
                })
              })
              break
            }
            case 'attachmentUrlFZRZm': {
              // 服务负责人正面
              this.siteInfo.forEach((site, index) => {
                let siteAttInfo = attaInfo.filter(item => item.siteId === site.id)
                if (siteAttInfo.length) {
                  siteAttInfo = atSort(siteAttInfo)
                  siteAttInfo.forEach((val, i) => {
                    this.form.siteOwner[index].owner.splice(i, 1, {
                      attachmentId: val.id,
                      imgUrl: val.url
                    })
                  })
                }
              })
              break
            }
            case 'attachmentUrlHt': {
              // 活体照片
              this.siteInfo.forEach((site, index) => {
                let siteAttInfo = attaInfo.filter(item => item.siteId === site.id)
                if (siteAttInfo.length) {
                  siteAttInfo = atSort(siteAttInfo)
                  siteAttInfo.forEach(val => {
                    this.form.siteOwner[index].videoImg = {
                      imgUrl: val.url,
                      attachmentId: val.id
                    }
                  })
                }
              })
              break
            }
          }
        }
      })
    },
    // 获取附件信息
    async getAttachmentInfo() {
      let { data } = await getAttachmentInfo({ recordNo: this.recordNo })
      this.attachmentInfo = data
    },
    // 获取服务信息
    async getSiteInfo() {
      if (!this.isUpdate) {
        try {
          let { data } = await getSiteInfo({ recordNo: this.recordNo })
          this.siteInfo = data.recordSites
          this.domainInfo = data.recordDomains
        } catch (err) {
          console.error(err)
        }

        // 初始化服务相关
        for (let j = this.siteInfo.length; j > 0; j--) {
          let sitImg = {
            owner: [],
            videoImg: {
              imgUrl: '',
              attachmentId: ''
            }
          }
          for (let i = 2; i > 0; i--) {
            sitImg.owner.push({
              imgUrl: '',
              attachmentId: ''
            })
          }
          this.form.siteOwner.push(sitImg)
        }
      }
    },
    // 查询主体详情
    async getPrincipalInfo() {
      if (!this.isUpdate) {
        try {
          let { data } = await getPrincipalInfo({
            recordNo: this.recordNo
          })
          this.principalInfo = data
          this.form.license.push({
            imgUrl: '',
            attachmentId: ''
          })
          let ownerCount = at_3.includes(data.ownerIdentityType) ? 3 : 2
          for (let i = ownerCount; i > 0; i--) {
            this.form.owner.push({
              imgUrl: '',
              attachmentId: ''
            })
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    getIdentityType(value) {
      let identityType = value || 2
      return OWNER_IDENTITY_TYPE.find(val => val.value === identityType).label
    },
    // 下一步
    nextStep() {
      // if (this.role !== 5 && this.principalInfo.identityType === 1 && !this.agreeForm.agreement) {
      //   this.$message({
      //     type: 'error',
      //     message: ' 请同意和授权查询企业征信信息'
      //   })
      //   return
      // }

      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const result = await queryEleCheck({
              recordNo: this.recordNo,
              status: 100
            })
            if (result.success) {
              this.$message.success('提交成功')
              this.$router.push({
                name: 'confirm',
                params: { recordNo: this.recordNo }
              })
            } else {
              this.$message({
                type: 'error',
                message: result.message,
                showClose: true,
                duration: 10000
              })
            }
          } catch (error) {
            console.log(error)
            try {
              window.__sgm__.custom({
                type: 1,
                code: 'ERROR_ELE_CHECK',
                msg: '电子核验错误：' + error.message
              })
            } catch (e) {
              console.log(e)
            }
          }
        } else {
          this.$message({ type: 'error', message: '请完善页面信息' })
        }
      })
    },
    async handleQrCodeParams(type, identityType, index) {
      this.isTimeout = false
      this.traceId = Math.ceil(Math.random() * 1000)
      let time = new Date().getTime()
      let h5Type
      let attachmentType
      let siteId = []
      switch (type) {
        case 'licenseData': {
          // 主体证件 h5Type 4: 营业执照拍照，6：事业单位法人证书拍照
          this.getQrCodeParams = {
            pin: this.principalInfo.pin,
            h5Type: identityType === 1 ? 4 : 6,
            attachmentType: 1,
            recordNo: this.recordNo,
            expire: time,
            traceId: String(this.traceId)
          }
          this.getQrCode()
          return
        }
        case 'ownerData': {
          let isPrincipalOwnerList = this.siteInfo.filter(item => item.isPrincipalOwner)
          if (isPrincipalOwnerList && isPrincipalOwnerList.length) {
            h5Type = identityType === 2 ? 1 : 2
            attachmentType = 15
            siteId = isPrincipalOwnerList.map(item => item.id)
          } else {
            h5Type = identityType === 2 ? 7 : 5
            attachmentType = 2
          }
          this.getQrCodeParams = {
            pin: this.principalInfo.pin,
            h5Type,
            attachmentType,
            recordNo: this.recordNo,
            expire: time,
            siteId,
            traceId: String(this.traceId)
          }
          this.getQrCode()
          return
        }
        case 'siteOwnerData': {
          this.getQrCodeParams = {
            pin: this.principalInfo.pin,
            h5Type: identityType === 2 ? 1 : 2,
            attachmentType: this.siteInfo[index].isPrincipalOwner ? 15 : 3,
            recordNo: this.recordNo,
            expire: time,
            siteId: [this.siteInfo[index].id],
            traceId: String(this.traceId)
          }
          this.getQrCode()
          return
        }
        case 'videoData': {
          this.getQrCodeParams = {
            pin: this.principalInfo.pin,
            h5Type: identityType,
            attachmentType: 8, // 活体照片
            recordNo: this.recordNo,
            expire: time,
            siteId: [this.siteInfo[index].id],
            traceId: String(this.traceId)
          }
          this.getQrCode()
          return
        }
      }
    },
    async getQrCode() {
      const result = await generateQrCode({
        paramMap: this.getQrCodeParams
      })
      this.dialogVisible = true
      this.qrCodeImg = result.data.imageFile
      this.getStatus()
      this.qrCodeTime = setTimeout(() => {
        this.isTimeout = true
      }, 1000 * 60 * 10)
    },
    async getStatus() {
      this.validateTime = setInterval(async () => {
        const { data } = await getSubmitStatus({
          traceId: this.traceId
        })
        if (data === 'success') {
          clearInterval(this.validateTime)
          this.dialogVisible = false
          this.isUpdate = true
          this.init()
        } else if (data === 'invalid') {
          clearInterval(this.validateTime)
        }
      }, 3000)
    },
    async refreshQrCode() {
      this.traceId = Math.ceil(Math.random() * 1000)
      this.getQrCodeParams.traceId = String(this.traceId)
      const result = await generateQrCode({
        paramMap: this.getQrCodeParams
      })
      this.qrCodeImg = result.data.imageFile
      this.isTimeout = false
      this.getStatus()
      this.qrCodeTime = setTimeout(() => {
        this.isTimeout = true
      }, 1000 * 60 * 10)
    },
    // 上一步
    back() {
      this.$router.push({ name: 'step2', params: { recordNo: this.recordNo } })
    },
    atRule() {
      return this.rules.file
      // if (type === 'lisence') {
      //   return this.rules.file
      // } else if (type === 'owner') {
      //   if (index === 0) {
      //     return this.rules.file
      //   } else if (index === 1 && this.principalInfo.ownerIdentityType === 2) {
      //     return this.rules.file
      //   } else {
      //     return this.rules.noValidate
      //   }
      // } else if (type === 'siteOwner') {
      //   if (ownerIndex === 0) {
      //     return this.rules.file
      //   } else if (
      //     ownerIndex === 1 &&
      //     this.siteInfo[index].identityType === 2
      //   ) {
      //     return this.rules.file
      //   } else {
      //     return this.rules.noValidate
      //   }
      // }
    }
  }
}
</script>
<style>
.app-qr-dialog {
  width: 1000px;
  height: 600px;
}
.app-qr-dialog .jc-dialog__header {
  border-bottom: none;
}
.app-qr-dialog .app-qr-dialog-title {
  width: 100%;
  height: 36px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
}
.app-qr-dialog .app-qr-dialog-imgs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 20px 60px 20px 20px;
  box-sizing: border-box;
}
.app-qr-dialog .app-qr-dialog-descs {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 10px;
}
.app-qr-dialog-descs .desc-item {
  text-align: center;
}
.app-qr-dialog-descs .desc-item-num {
  width: 20px;
  height: 20px;
  background: rgba(44, 104, 255, 1);
  border-radius: 10px;
  font-size: 13px;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto;
}
.app-qr-dialog-descs .desc-item-txt {
  width: 238px;
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5;
}
.img-type-img {
  height: calc(100% - 20px);
  position: absolute !important;
  border: none !important;
  top: 20px;
  left: 500px;
}
.img-type-img2 {
  height: 100%;
  position: absolute !important;
  border: none !important;
  top: 0px;
  left: 488px;
}
.img-type-img > img {
  height: 100%;
}
.img-type-img2 > img {
  height: 100%;
}
.type-img-warp {
  position: relative;
}
.type-img-warp .jc-section-content {
  position: relative;
}
</style>
<style scoped>
.uploader-btn {
  height: 94px;
}
.avatar-uploader {
  height: 94px;
  width: 94px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.avatar-uploader-icon {
  background: none;
  text-align: center;
  line-height: 92px;
}
.qrCodeImg {
  width: 200px;
  position: relative;
  text-align: center;
}
.qrCode-mask {
  width: 200px;
  height: 180px;
  position: absolute;
  /* left: 200px; */
  top: 10px;
  background-color: white;
  opacity: 0.8;
}
.qrCode-refresh {
  position: absolute;
  top: 42%;
  /* left: 290px; */
  left: 42%;
  font-size: 14px;
  cursor: pointer;
  color: #2c68ff;
  margin: auto;
  font-weight: bold;
}
.jc-dialog__wrapper /deep/ .downloadDialog {
  width: 480px;
}
.downloadCodeImg {
  text-align: center;
  padding: 40px 0 55px;
}
.downloadCodeImg img {
  width: 192px;
}
.downloadCodeImg p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 25px;
}
</style>
