<template>
  <div>
    <rc-children-card title="备案管理" @back="back"></rc-children-card>
    <div class="jc-page-content">
      <div class="jc-panel jc-panel-padding">
        <rc-section title="服务信息">
          <table class="demo-table">
            <tr>
              <th>服务名称</th>
              <td>{{ siteInfo.siteName }}</td>
            </tr>
            <tr>
              <th>关联的京东云服务</th>
              <!-- <td>{{ siteInfo.jcloudServiceType | listFilter(SERVICE_TYPE) }}：{{ siteInfo.serviceValue }}</td> -->
              <td>{{ siteInfo.serviceValue }}</td>
            </tr>
            <tr>
              <th>服务域名</th>
              <td>
                <span v-for="item in domainInfo" :key="item.id">
                  {{ item.domain }}
                </span>
              </td>
            </tr>
            <tr v-if="siteInfo.appServiceType === 'web'">
              <th>网站URL</th>
              <td>{{ domainInfo[0] && domainInfo[0].homeUrl }}</td>
            </tr>
            <tr>
              <th>服务内容</th>
              <td>
                <p>{{ showSiteTypes(siteInfo.siteTypes) }}</p>
              </td>
            </tr>
            <!-- <tr>
              <th>应用服务类型</th>
              <td>
                {{
                  siteInfo.appServiceType
                    | appServiceTypeFilter(appServiceTypeOptions)
                }}
              </td>
            </tr> -->
            <!-- <tr>
              <th>前置审批项</th>
              <td>{{ siteInfo.preApprovalOption | preApprovalFilter }}</td>
            </tr>
            <tr>
              <th>前置审批号</th>
              <td>{{ siteInfo.preApprovalNo }}</td>
            </tr> -->
            <tr>
              <th>服务语言</th>
              <td>
                {{ siteInfo.siteLanguage | languageFilter(languageOptions) }}
              </td>
            </tr>
            <tr>
              <th>服务备注</th>
              <td>{{ siteInfo.remark }}</td>
            </tr>
          </table>
        </rc-section>
        <rc-section title="负责人信息">
          <jc-button v-if="desense" type="primary" @click="desense = false" style="margin-bottom:8px"
            >查看全部</jc-button
          >
          <jc-button v-else type="primary" @click="desense = true" style="margin-bottom:8px">加密信息</jc-button>
          <table class="demo-table">
            <tr>
              <th>姓名</th>
              <td v-if="desense">
                {{ siteInfo.ownerName | desenseFilter('name') }}
              </td>
              <td v-else>{{ siteInfo.ownerName }}</td>
            </tr>
            <tr>
              <th>证件类型</th>
              <td>
                {{ siteInfo.identityType | identityTypeFilter(identityTypeOptions) }}
              </td>
            </tr>
            <tr>
              <th>证件号码</th>
              <td v-if="desense">
                {{ siteInfo.identityNo | desenseFilter('idCard') }}
              </td>
              <td v-else>{{ siteInfo.identityNo }}</td>
            </tr>
            <tr>
              <th>办公电话</th>
              <td>{{ siteInfo.phone }}</td>
            </tr>
            <tr>
              <th>手机号</th>
              <td v-if="desense">
                {{ siteInfo.mobile | desenseFilter('mobile') }}
              </td>
              <td v-else>{{ siteInfo.mobile }}</td>
            </tr>
            <tr>
              <th>应急电话号码</th>
              <td v-if="desense">
                {{ siteInfo.emergencyCall | desenseFilter('mobile') }}
              </td>
              <td v-else>{{ siteInfo.emergencyCall }}</td>
            </tr>
            <tr>
              <th>电子邮箱地址</th>
              <td v-if="desense">
                {{ siteInfo.email | desenseFilter('mail') }}
              </td>
              <td v-else>{{ siteInfo.email }}</td>
            </tr>
          </table>
        </rc-section>
      </div>
    </div>
  </div>
</template>
<script>
import { getSiteInfo, queryServiceDetail } from '@/service/record-api'
import profileMixins from '@/mixins/profile.mixins.js'
import { SERVICE_TYPE, SERVER_CONTENT_TYPE } from '@/views/service/constant.js'

export default {
  props: {},
  components: {},
  mixins: [profileMixins],
  data() {
    return {
      siteId: this.$route.query.id,
      recordNo: this.$route.query.recordNo,
      miitRecordNo: this.$route.query.miitRecordNo,
      siteInfo: {},
      domainInfo: [],
      desense: true,
      SERVICE_TYPE
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getSiteInfoNew()
  },
  created() {},
  filters: {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    async getSiteInfoNew() {
      const { data } = await queryServiceDetail({
        miitRecordNo: this.miitRecordNo
      })
      this.siteInfo = data
      this.domainInfo = data.domains
        ? data.domains.map(x => {
            return {
              domain: x,
              homeUrl: 'www.' + x
            }
          })
        : []
    },
    async getSiteInfo() {
      const { data } = await getSiteInfo({
        siteId: this.siteId,
        recordNo: this.recordNo
      })
      this.siteInfo = data.recordSites[0] || []
      this.domainInfo = data.recordDomains.filter(val => val.siteId === this.siteId)
    },
    showSiteTypes(siteTypes) {
      if (!siteTypes) {
        return '--'
      }
      if (typeof siteTypes === 'string' || siteTypes instanceof String) {
        siteTypes = siteTypes.split(',')
      }
      if (this.siteInfo.appServiceType === 'app') {
        let namesMap = {}
        this.appTypeOptions.map(item => {
          namesMap[item.gjId] = item.localName
        })
        return siteTypes.map(t => namesMap[t]).join(',')
      }
      let namesMap = {}
      SERVER_CONTENT_TYPE.map(item => {
        namesMap[item.value] = item.label
      })
      return siteTypes.map(t => namesMap[parseInt(t)]).join(',')
    }
  }
}
</script>
