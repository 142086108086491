import { getRecordInfo, updateStatus } from '@/service/record-api'
import quitMessage from '@/mixins/quit.mixins.js'
import confirm from '@/mixins/confirm.mixins.js'
import { RECORD_TYPE_MAP } from '@/views/service/constant.js'

export default {
  props: [],
  components: {
    confirmDialog: () => import('@/views/submit/src/record.status/components/confirm.dialog.vue')
  },
  mixins: [quitMessage, confirm], // 放弃本次流程， 备案详情
  data() {
    return {
      recordNo: this.$route.params.recordNo,
      step: 2,
      isAuditProgress: false,
      status: '',
      approveReason: '',
      authTitle: '',
      introduction: '',
      logo: '',
      modified: false,
      deleted: false
    }
  },
  mounted() {},
  created() {
    this.getStatus()
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    reload() {
      // 2 3 4 5 7 11 幕布 不改

      // 102
      // 103
      // 104：新加的进度条：京东云初审

      // 12 新：提交管局
      // 14 新：管局审核

      // 121 新 短信核验
      // 122 新 管局审核

      // 19 20 21 变更接入：运管 不改
      this.isAuditProgress = false
      switch (this.status) {
        case 2: {
          // 等待初审(新增备案、变更接入、主体注销、网站注销、取消接入, 变更图片)
          if ([6].includes(this.recordType)) {
            this.step = 1
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 0
          } else if (this.recordType === RECORD_TYPE_MAP.CHANGE_IMAGE) {
            this.step = 1
          } else {
            this.step = 3
          }
          this.authTitle = '您的备案信息已提交'
          this.introduction =
            '京东云将在<span style="color:red">1-3个工作日</span>完成备案初审审核，请耐心等侯。审核结果及下一步操作说明将在本页面更新。'
          this.logo = 'pass w580'
          break
        }
        case 4: {
          //初审驳回
          if ([6].includes(this.recordType)) {
            this.step = 1
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 0
          } else if (this.recordType === RECORD_TYPE_MAP.CHANGE_IMAGE) {
            this.step = 1
          } else {
            this.step = 2
          }
          this.authTitle = '您的备案信息审核失败'
          this.introduction = `失败原因：${this.approveReason || '暂无原因'}`
          this.modified = true
          this.logo = 'pass_v1 failed'
          break
        }
        case 5: {
          //等待审核当面核验照
          this.step = 3
          this.authTitle = '您的当面核验照片已提交等待审核'
          this.introduction =
            '京东云将在<span style="color:red">1-3个工作日</span>完成当面核验照片审核，请耐心等侯，审核结果及下一步操作说明将在本页面更新。'
          this.logo = 'pass w580'
          break
        }
        case 7: {
          //当面核验照审核驳回
          this.step = 3
          this.authTitle = '您的当面核验照片审核失败'
          this.introduction = `失败原因：${this.approveReason || '暂无原因'}`
          this.modified = true
          this.logo = 'pass_v1 failed'
          break
        }
        case 11: {
          //当面核验照审核通过
          this.step = 4
          this.authTitle = '您的当面核验照片已通过审核，等待提交管局'
          this.introduction = '您的备案信息等待提交管局审核，请耐心等侯，提交管局审核后页面会更新。'
          this.logo = 'pass w600'
          break
        }
        case 12: {
          //提交管局审核
          if ([6].includes(this.recordType)) {
            this.step = 2
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 1
          } else {
            // this.step = 4
            this.isAuditProgress = true
            this.step = 2
          }
          this.authTitle = '您的备案信息已提交至管局审核'
          this.introduction = '京东云已经将您备案信息提交管局审核，管局审核时间20个工作日内完成，请您耐心等侯。'
          this.logo = 'pass'
          break
        }
        case 121: {
          //短信核验
          if ([6].includes(this.recordType)) {
            this.step = 2
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 1
          } else {
            this.isAuditProgress = true
            this.step = 3
          }
          this.authTitle = '待完成工信部短信核验'
          this.introduction =
            '请在<span style="color:red">24小时</span>内完成工信部短信核验，您的备案订单才能正常提交至管局审核'
          this.logo = 'pass'
          break
        }
        case 122: {
          //管局审核
          if ([6].includes(this.recordType)) {
            this.step = 2
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 1
          } else {
            this.isAuditProgress = true
            this.step = 4
          }
          this.authTitle = '您的备案信息已提交至管局审核'
          this.introduction = '京东云已经将您备案信息提交管局审核，管局审核时间20个工作日内完成，请您耐心等侯。'
          this.logo = 'pass'
          break
        }
        case 14: {
          //管局审核驳回
          if ([6].includes(this.recordType)) {
            this.step = 2
          } else if ([10, 11, 12].includes(this.recordType)) {
            this.step = 1
          } else {
            // this.step = 4
            this.isAuditProgress = true
            this.step = 4
          }
          this.authTitle = '您的备案信息管局审核失败'
          this.introduction = `失败原因：${this.approveReason || '暂无原因'}`
          this.modified = true
          this.logo = 'pass_v1 failed'
          break
        }
        case 102: {
          //电子核验待审核
          // this.step = 3
          this.isAuditProgress = true
          this.step = 1
          this.authTitle = '您的资料已提交等待审核'
          this.introduction =
            '京东云将在<span style="color:red">1-3个工作日</span>完成审核，请耐心等侯，审核结果及下一步操作说明将在本页面更新。'
          this.logo = 'pass w580'
          break
        }
        case 103: {
          //电子核验审核驳回
          // this.step = 3
          this.isAuditProgress = true
          this.step = 1
          this.authTitle = '您的资料被驳回'
          this.introduction = `失败原因：${this.approveReason || '暂无原因'}`
          this.modified = true
          this.logo = 'pass_v1 failed'
          break
        }
        case 104: {
          //电子核验审核通过
          // this.step = 4
          this.isAuditProgress = true
          this.step = 1
          this.authTitle = '您的资料审核通过，等待提交管局'
          this.introduction = '您的备案信息等待提交管局审核，请耐心等侯，提交管局审核后页面会更新。'
          this.logo = 'pass w600'
          break
        }
        // case 19: {
        //   // 变更接入等待审核
        //   this.step = 1
        //   this.authTitle = '您的备案信息已提交'
        //   this.introduction =
        //     '京东云将在<span style="color:red">1-3个工作日</span>完成备案初审审核，请耐心等侯。审核结果及下一步操作说明将在本页面更新。'
        //   this.logo = 'pass w580'
        //   break
        // }
        case 20: {
          //当面核验照审核通过
          this.step = 2
          this.authTitle = '您的变更接入已通过审核，等待提交管局'
          this.introduction = '您的备案信息等待提交管局审核，请耐心等侯，提交管局审核后页面会更新。'
          this.logo = 'pass w600'
          break
        }
        // case 21: {
        //   // 变更接入审核驳回
        //   this.step = 1
        //   this.authTitle = '您的备案信息审核失败'
        //   this.introduction = `失败原因：${this.approveReason || '暂无原因'}`
        //   this.modified = true
        //   this.logo = 'pass_v1 failed'
        //   break
        // }
      }
    },
    // 驳回返回修改信息
    async modifySubmit() {
      switch (this.status) {
        case 4: {
          // 初审驳回
          if ([10, 11, 12].includes(this.recordType)) {
            // this.$router.push({
            //   name: 'cancelRecord',
            //   parmas: { recordNo: this.recordNo }
            // })
            this.quitMessage()
          } else {
            await this.modifyStatus('confirm')
            this.$router.push({
              name: 'confirm',
              parmas: { recordNo: this.recordNo }
            })
          }
          break
        }
        case 7: {
          // 当面核验照审核驳回
          await this.modifyStatus('screen')
          this.$router.push({
            name: 'screen',
            parmas: { recordNo: this.recordNo }
          })
          break
        }
        case 14: {
          // 管局审核失败
          let step
          switch (this.recordType) {
            case 1:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 3:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 4:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 5:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 6:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 7:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 8:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 9:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 10:
              this.quitMessage()
              return
            case 11:
              this.quitMessage()
              return
            case 12:
              this.quitMessage()
              return
            case 14:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 24:
              await this.modifyStatus('step2')
              step = 'step2'
              break
          }
          this.$router.push({
            name: step,
            parmas: { recordNo: this.recordNo }
          })
          break
        }
        case 103: {
          // 电子核验审核驳回
          let step
          switch (this.recordType) {
            case 1:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 2:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 3:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 4:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 5:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 6:
              await this.modifyStatus('step2')
              step = 'step2'
              break
            case 7:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 8:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 9:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 14:
              await this.modifyStatus('step1')
              step = 'step1'
              break
            case 24:
              await this.modifyStatus('step2')
              step = 'step2'
              break
          }
          this.$router.push({
            name: step,
            parmas: { recordNo: this.recordNo }
          })
          break
        }
        case 21: {
          // 变更接入审核驳回
          await this.modifyStatus('step2')
          this.$router.push({
            name: 'step2',
            parmas: { recordNo: this.recordNo }
          })
          break
        }
      }
    },
    // 修改备案状态
    async modifyStatus(status) {
      let params = {
        recordNo: this.recordNo,
        status: status
      }
      try {
        await updateStatus(params)
      } catch (err) {
        console.error(err)
      }
    },
    async getStatus() {
      let {
        data: { status, recordNo, approveReason }
      } = await getRecordInfo({ recordNo: this.recordNo })
      this.status = status
      this.recordNo = recordNo
      this.approveReason = approveReason
      this.reload()
    },
    showConfirm() {
      this.$refs.confirm.showDialog()
    },
    btn() {
      switch (this.status) {
        case 2: {
          // 等待初审
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 4: {
          if ([10, 11, 12].includes(this.recordType)) {
            return (
              <div>
                <a href="#" class="greenBtn_v1 mr20" onClick={this.quitMessage}>
                  放弃重新提交
                </a>
              </div>
            )
          } else {
            return (
              <div>
                <a href="#" class="greenBtn_v1 mr20" onClick={this.modifySubmit}>
                  修改信息提交
                </a>
                <a href="#" class="blueLink" onClick={this.quitMessage}>
                  放弃本次流程
                </a>
              </div>
            )
          }
        }
        case 5: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 7: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.modifySubmit}>
                修改信息提交
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 11: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
            </div>
          )
        }
        case 12:
        case 121:
        case 122: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
            </div>
          )
        }
        case 14: {
          if ([10, 11, 12].includes(this.recordType)) {
            return (
              <div>
                <a href="#" class="greenBtn_v1 mr20" onClick={this.quitMessage}>
                  放弃重新提交
                </a>
              </div>
            )
          } else {
            return (
              <div>
                <a href="#" class="greenBtn_v1 mr20" onClick={this.modifySubmit}>
                  修改信息提交
                </a>
                <a href="#" class="blueLink" onClick={this.quitMessage}>
                  放弃本次流程
                </a>
              </div>
            )
          }
        }
        case 102: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 103: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.modifySubmit}>
                修改信息提交
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 104: {
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
            </div>
          )
        }
        case 19: {
          // 等待初审
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
        case 20: {
          // 变更接入审核通过
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.showConfirm}>
                浏览备案信息
              </a>
            </div>
          )
        }
        case 21: {
          // 变更接入审核驳回
          return (
            <div>
              <a href="#" class="greenBtn_v1 mr20" onClick={this.modifySubmit}>
                修改信息提交
              </a>
              <a href="#" class="blueLink" onClick={this.quitMessage}>
                放弃本次流程
              </a>
            </div>
          )
        }
      }
    }
  },
  render() {
    // eslint-disable-line
    return (
      <div class="recordForm">
        <rc-card recordType={this.recordType} />
        <div class="jc-page-content">
          <rc-step step={this.step} isAuditProgress={this.isAuditProgress} />
          <div>
            <div class="white-box mb20 status-loading">
              <div class={this.logo}>
                <h5 class="mb20">{this.authTitle}</h5>
                <p class="mb20">
                  <div domPropsInnerHTML={this.introduction || ''}></div>
                  <br />
                  备案订单号：
                  <span class="org">{this.recordNo || '暂无信息'}</span>
                </p>
                <div class="pt10">{this.btn()}</div>
              </div>
            </div>
          </div>
        </div>
        <confirm-dialog ref="confirm" />
      </div>
    )
  }
}
