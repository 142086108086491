export default {
  methods: {
    outMsg(type, errMsg, errObj) {
      if (errObj && errObj.message) {
        this.$message({
          message: errObj.message,
          type: type
        })
      } else {
        this.$message({
          message: errMsg,
          type: type,
          duration: 3000
        })
      }
    }
  }
}
