<template>
  <div>
    <rc-card title="备案授权码管理">
      <a slot="link" :href="AGREEMENT.introduction" target="_blank">备案帮助文档</a>
    </rc-card>
    <div class="jc-page-content">
      <div class="jc-container-table">
        <div class="jc-container-table-actionBar">
          <!-- <jc-form class="jc-table-actionBar-item" :inline="true">
            <jc-form-item>
              <router-link
                class="jc-button jc-button--primary"
                :to="{ name: 'attachment' }"
                target="_blank"
                :clstag="clstagcontent('申请合同')"
                >{{ '申请合同') }}</router-link
              >
            </jc-form-item>
          </jc-form> -->
          <jc-form class="jc-table-actionBar-item" :model="form" :inline="true">
            <jc-form-item label="备案授权码：" prop="code">
              <jc-input style="width: 185px;" v-model.trim="form.code" placeholder="请输入备案授权码"></jc-input>
            </jc-form-item>
            <jc-form-item label="资源信息：" prop="instanceId">
              <jc-input style="width: 185px;" v-model.trim="form.instanceId" placeholder="请输入资源ID"></jc-input>
            </jc-form-item>
            <jc-form-item label="状态：" prop="validFlag">
              <jc-select v-model="form.validFlag" placeholder="请选择" style="width: 130px;">
                <jc-option
                  v-for="item in AUTH_CODE_STATUE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></jc-option>
              </jc-select>
            </jc-form-item>
            <jc-form-item>
              <jc-button type="primary" @click="search">查询</jc-button>
            </jc-form-item>
          </jc-form>
        </div>
        <jc-table :data="tableData" style="width: 100%">
          <jc-table-column label="备案授权码" width="275px">
            <template slot-scope="scope">
              {{ scope.row.code | emptyFilter }}
            </template>
          </jc-table-column>
          <jc-table-column label="产品线名称">
            <template slot-scope="scope">
              {{ scope.row.instanceName | emptyFilter }}
            </template>
          </jc-table-column>
          <jc-table-column label="资源信息">
            <template slot-scope="scope">
              {{ scope.row.instanceId | emptyFilter }}
            </template>
          </jc-table-column>
          <jc-table-column label="创建时间" width="200px">
            <template slot-scope="scope">
              {{ scope.row.startTime | timeFilter }}
            </template>
          </jc-table-column>
          <jc-table-column label="有效期" width="300px">
            <template slot-scope="scope">
              {{ scope.row.startTime | timeFilter }} 至
              {{ scope.row.expiredTime | timeFilter }}
            </template>
          </jc-table-column>
          <jc-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.validFlag | listFilter(AUTH_CODE_STATUE) }}
            </template>
          </jc-table-column>
          <jc-table-column label="操作">
            <template slot-scope="scope">
              <router-link :to="{ name: 'index' }" v-if="scope.row.validFlag === 0">去备案</router-link>
            </template>
          </jc-table-column>
        </jc-table>
        <div class="jc-container-table-actionBar">
          <jc-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.currentPage"
            :page-sizes="[20, 50, 100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          ></jc-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AUTH_CODE_STATUE } from '@/views/service/constant'
import { getAuthCodeList } from '@/service/record-api'
import { AGREEMENT } from '@/views/service/constant'
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      form: {
        code: '',
        instanceId: this.$route.query.instanceId || '',
        validFlag: '',
        pageSize: 20,
        currentPage: 1
      },
      tableData: [],
      totalCount: 0,
      AUTH_CODE_STATUE,
      AGREEMENT
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getList()
  },
  created() {},
  filters: {},
  methods: {
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getList()
    },
    search() {
      this.form.currentPage = 1
      this.getList()
    },
    async getList() {
      const { data, page } = await getAuthCodeList({
        ...this.form
      })
      this.tableData = data
      this.totalCount = page.total
    }
  }
}
</script>
<style lang="css" scoped></style>
