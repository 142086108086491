<template>
  <div>
    <jc-steps :active="getActiveStep()" finish-status="success">
      <jc-step v-for="item in stepTitle" :key="item.title" :title="item.title"></jc-step>
    </jc-steps>
    <!-- <div class="fixed-quit">
      <jc-tooltip
        class="item"
        effect="dark"
        content="点击放弃本次流程，只会放弃您当前操作的备案流程，其它备案流程不会受影响"
        placement="top-end"
      >
        <jc-button type="text" v-if="!!recordNo && recordNo.length === 18" @click="quitMessage">
          放弃本次流程
          <i class="jc-icon-my-help jc-icon--right" style="font-size:16px;vertical-align: middle;"></i>
        </jc-button>
      </jc-tooltip>
    </div> -->
  </div>
</template>
<script>
import { RECORD_TYPE_MAP } from '@/views/service/constant.js'
import { deleteRecord } from '@/service/record-api'

export default {
  name: 'rc-step',
  data() {
    return {
      progress: [
        { title: '主体信息' },
        { title: '服务信息' },
        { title: '上传资料' },
        { title: '真实性核验' },
        { title: '提交管局' },
        { title: '备案完成' }
      ],
      AIProgress: [
        { title: '主体信息' },
        { title: '服务信息' },
        { title: '上传资料' },
        { title: '电子核验' },
        // { title: '提交管局' },
        // { title: '备案完成' }
        { title: '提交备案订单' }
      ],
      auditProgress: [
        { title: '提交订单' },
        { title: '京东云初审' },
        { title: '提交管局' },
        { title: '短信核验' },
        { title: '管局审核' },
        { title: '备案完成' }
      ],
      joinProgress: [{ title: '服务信息' }, { title: '等待审核' }, { title: '提交管局' }, { title: '备案完成' }],
      cancelProgress: [
        // { title: '提交注销' },
        { title: '等待审核' },
        { title: '提交管局' },
        { title: '备案完成' }
      ],
      cancelInsertProgress: [
        // { title: '提交取消接入' },
        { title: '等待审核' },
        { title: '提交管局' },
        { title: '备案完成' }
      ],
      changeImage: [{ title: '上传资料' }, { title: '提交管局' }, { title: '备案完成' }]
    }
  },
  props: {
    step: {
      type: Number,
      require: true
    },
    isAuditProgress: {
      type: Boolean,
      require: false
    }
  },
  components: {},
  mixins: [],
  methods: {
    getActiveStep() {
      if (this.recordType === 4) {
        // 变更主体，跳过原第2步服务信息
        if (this.step > 1) {
          return this.step - 1
        }
      }
      return this.step
    },
    // 放弃本次流程提示
    async quitMessage() {
      try {
        await this.$confirm('如您放弃当前备案，当前备案中所填信息将被删除！', '放弃本次流程', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: ''
        })
      } catch (err) {
        return false
      }
      this.quitRecord()
    },
    //放弃本次流程
    async quitRecord() {
      try {
        let params = {
          recordNo: this.recordNo
        }
        await deleteRecord(params)
        this.outMsg('success', '放弃本次流程成功')
        this.$router.push('/index')
      } catch (err) {
        console.error(err)
      }
    }
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    recordNo() {
      return this.$route.params.recordNo || ''
    },
    stepTitle() {
      if (this.recordType === 6) {
        return this.joinProgress
      } else if (this.recordType === 12) {
        return this.cancelInsertProgress
      } else if ([10, 11].includes(this.recordType)) {
        return this.cancelProgress
      } else if (this.recordType === RECORD_TYPE_MAP.CHANGE_IMAGE) {
        return this.changeImage
      } else if (this.recordType === 4) {
        // 提交后 展示新进度条
        if (this.isAuditProgress) {
          return this.auditProgress
        }
        // 变更主体，跳过服务信息
        return this.$store.state.record.eleFlag !== 1
          ? this.progress.filter(item => item.title !== '服务信息')
          : this.AIProgress.filter(item => item.title !== '服务信息')
      } else {
        // 提交后 展示新进度条
        if (this.isAuditProgress) {
          return this.auditProgress
        }
        return this.$store.state.record.eleFlag !== 1 ? this.progress : this.AIProgress
      }
    }
  },
  filters: {}
}
</script>
<style lang="css" scoped>
.fixed-quit {
  position: fixed;
  right: 80px;
  bottom: 10px;
  z-index: 9;
}
</style>
