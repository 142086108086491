import { reactive, ref, watch } from '@vue/composition-api'
import { getVMList } from '@/service/record-api'

export function useSearch(pageParams, totalCount, authCodeType) {
  const form = reactive({
    webAdmin: '',
    domainName: '',
    regionId: 'cn-north-1'
  })
  const tableData = ref([])
  const getList = async () => {
    try {
      const { data = {} } = await getVMList({
        ...form,
        ...pageParams,
        authCodeType
      })
      tableData.value = data.authCodeInstanceVos || []
      totalCount.value = data.totalCount || 0
    } catch (e) {
      tableData.value = []
      totalCount.value = 0
    }
  }

  watch(pageParams, () => {
    getList()
  })

  return {
    form,
    tableData,
    getList
  }
}
