<template>
  <div>
    <rc-children-card title="备案管理" @back="back"></rc-children-card>
    <div class="jc-page-content">
      <div class="jc-panel jc-panel-padding">
        <principal-detail :desense.sync="localDesense" :ownerDesense.sync="localDesense"></principal-detail>
      </div>
    </div>
  </div>
</template>
<script>
import PrincipalDetail from '@/views/entry/src/components/PrincipalInfo'
export default {
  props: {},
  components: {
    PrincipalDetail
  },
  mixins: [],
  data() {
    return {
      localDesense: true
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  filters: {},
  methods: {
    back() {
      this.$router.push({ name: 'manage' })
    }
  }
}
</script>
