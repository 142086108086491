<template>
  <div>
    <rc-card title="备案列表">
      <a slot="link" :href="AGREEMENT.introduction" target="_blank">备案帮助文档</a>
    </rc-card>
    <div class="jc-page-content">
      <!--status: 13:管局审核通过 15:放弃本次流程 -->
      <jc-alert
        v-if="currentStatus.status && currentStatus.status !== 15 && currentStatus.status !== 13"
        type="warning"
        :closable="false"
      >
        {{
          `尊敬的用户您好：您的备案尚未完成，现处于【 ${currentStatus.name} 】状态，请点击右侧“继续本次流程”按钮，根据系统提示完成备案操作，感谢您对京东云的支持！`
        }}
      </jc-alert>
      <jc-table :data="tableData" class="table-loading" style="width: 100%;">
        <jc-table-column label="备案订单号" width="160">
          <template slot-scope="scope">
            {{ scope.row.recordNo || '--' }}
          </template>
        </jc-table-column>
        <jc-table-column label="服务名称">
          <template slot-scope="scope">
            <p v-for="item in scope.row.websites" :key="item.id">
              {{ item.siteName || '--' }}
            </p>
          </template>
        </jc-table-column>
        <jc-table-column label="域名" show-overflow-tooltip>
          <template slot-scope="scope">
            <p slot="reference" v-for="item in scope.row.websites" :key="item.id">
              {{ item.domains && item.domains.map(val => val.domain).join(',') | limiteStr(20) }}
            </p>
          </template>
        </jc-table-column>
        <jc-table-column label="备案类型">
          <template slot-scope="scope">
            {{ scope.row.recordType | recordTypeFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="更新时间" width="154">
          <template slot-scope="scope">
            {{ scope.row.createTime | timeFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.status | recordStatusFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="操作">
          <template slot-scope="scope">
            <jc-button type="text" @click.prevent="auditRecord(scope.row)">审核历史</jc-button>
            <!--status: 13:管局审核通过 15:放弃本次流程 -->
            <span v-if="scope.row.status !== 15">
              <jc-button type="text" @click.prevent="continueRecord(scope.row)" :disabled="scope.row.status === 13"
                >继续本次流程</jc-button
              >
              <!--status: 11:当面核验照审核通过 12:提交管局审核 13:管局审核通过 104:电子核验审核通过 -->
              <jc-button
                type="text"
                @click.prevent="handleDelete(scope.row)"
                :disabled="[12, 121, 122, 13].includes(scope.row.status)"
                >放弃本次流程</jc-button
              >
            </span>
          </template>
        </jc-table-column>
      </jc-table>
    </div>
  </div>
</template>
<script>
import { filterArray } from '@/utils'
import { RECORD_STATUS, AGREEMENT } from '@/views/service/constant.js'
import { getRecordList, deleteRecord } from '@/service/record-api'
import { recordSchedule } from '@/utils/status.utils.js'
export default {
  mixins: [],
  data() {
    return {
      loading: true,
      tableData: [],
      delShow: false,
      recordNo: '',
      AGREEMENT,
      organizersNature: 5
    }
  },
  created() {
    this.getInfo()
  },
  computed: {
    currentStatus() {
      const status = (this.tableData[0] && this.tableData[0].status) || ''
      const name = filterArray(status, RECORD_STATUS, 'value').label
      return { status, name }
    }
  },
  methods: {
    async getInfo() {
      try {
        let { data } = await getRecordList()
        this.tableData = data
        // if (list && list.length) {
        //   list.forEach((item, index) => {
        //     this.tableData.push(item.recordPrincipal)
        //     this.tableData[index].stateName = item.stateName
        //     // this.tableData[index].recordType = '新增备案'
        //     this.tableData[index].siteList = item.recordSiteList
        //   })
        // }
      } catch (error) {
        this.tableData = []
        console.log(error)
      }
    },
    handleDelete(row) {
      this.recordNo = row.recordNo
      this.quitMessage()
    },
    // 继续备案
    async continueRecord(row) {
      try {
        let redirectRouter = recordSchedule(row)
        this.$router.push({
          name: redirectRouter,
          params: { recordNo: row.recordNo }
        })
      } catch (error) {
        this.outMsg('error', '获取备案状态失败')
        this.$router.push({ name: 'index' })
      }
    },
    async quitMessage() {
      try {
        await this.$confirm('如您放弃当前备案，当前备案中所填信息将被删除！', '放弃本次流程', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: ''
        })
      } catch (err) {
        return false
      }
      this.quitRecord()
    },
    async quitRecord() {
      try {
        let params = {
          recordNo: this.recordNo
        }
        await deleteRecord(params)
        this.outMsg('success', '放弃本次流程成功')
        // 备案三期修改
        await this.getInfo()
        if (!this.tableData.length || this.tableData.every(val => val.status === 15)) {
          this.$router.push({ path: '/index' })
        }
      } catch (err) {
        console.error(err)
      }
    },
    async auditRecord(row) {
      this.$router.push({
        name: 'auditRecord',
        query: { recordNo: row.recordNo }
      })
    }
  }
}
</script>
