var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recordForm"},[_c('rc-card',{attrs:{"type":_vm.recordType}}),_c('div',{staticClass:"jc-page-content"},[_c('rc-step',{attrs:{"step":_vm.eleFlag ? 3 : _vm.recordType === _vm.RECORD_TYPE_MAP.CHANGE_IMAGE ? 0 : 2}}),_c('div',{staticClass:"jc-panel jc-panel-padding form-loading"},[_c('rc-section',{attrs:{"title":"主体信息"}},[([1, 4].includes(_vm.recordType))?_c('span',{attrs:{"slot":"link"},slot:"link"},[_c('router-link',{attrs:{"to":{
              name: 'step1',
              params: { recordNo: _vm.recordNo },
              query: { step: 'confirm' }
            }}},[_vm._v("编辑")])],1):_vm._e(),_c(_vm.principalUser,{tag:"component",attrs:{"infoData":_vm.infoData}})],1),_c('rc-section',{attrs:{"title":"主体资料信息"}},[(_vm.role !== 5)?_c('confirm-picture',{key:"1",attrs:{"type":1,"infoData":_vm.infoData}}):_vm._e(),_c('confirm-picture',{key:"2",attrs:{"type":2,"infoData":_vm.infoData}})],1),_vm._l((_vm.infoData.siteInfo),function(item){return _c('rc-section',{key:item.siteName,attrs:{"title":"服务信息"}},[_c('span',{attrs:{"slot":"link"},slot:"link"},[_c('router-link',{attrs:{"to":{
              name: 'step2',
              params: { recordNo: _vm.recordNo },
              query: { step: 'confirm' }
            }}},[_vm._v("编辑")])],1),_c('rc-section',{attrs:{"title":"基本信息"}},[_c(_vm.websiteUser,{tag:"component",attrs:{"siteData":item,"infoData":_vm.infoData}})],1),_c('rc-section',{attrs:{"title":"资料信息"}},[(_vm.eleFlag !== 1)?_c('div',[_c('confirm-picture',{attrs:{"type":_vm.role !== 5 ? 3 : 4,"infoData":_vm.infoData,"siteData":item}})],1):_c('div',[_c('confirm-picture',{attrs:{"type":5,"infoData":_vm.infoData,"siteData":item}})],1)])],1)}),(_vm.eleFlag === 1)?_c('rc-section',{attrs:{"title":"承诺书"}},[_c('confirm-picture',{attrs:{"type":6,"infoData":_vm.infoData}})],1):_vm._e(),_c('jc-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"id":"agreement-anchor"}},[_c('jc-form-item',{attrs:{"prop":"agreement"}},[_c('jc-checkbox',{model:{value:(_vm.form.agreement),callback:function ($$v) {_vm.$set(_vm.form, "agreement", $$v)},expression:"form.agreement"}},[_vm._v("\n            我已阅读并同意\n            "),_c('a',{attrs:{"href":_vm.agreement.informationSecurity,"target":"_blank"}},[_vm._v("《信息安全管理协议》")]),_vm._v("和\n            "),_c('a',{attrs:{"href":_vm.agreement.assistChange,"target":"_blank"}},[_vm._v("《协助更改备案信息服务在线服务条款》")])])],1)],1)],2),_c('div',{staticClass:"jc-panel jc-panel-action"},[_c('jc-button',{on:{"click":_vm.back}},[_vm._v("上一步")]),_c('jc-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[_vm._v("提交信息")])],1)],1),_c('jc-dialog',{attrs:{"title":"重要提示","visible":_vm.dialogVisible,"size":"tiny"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.recordType === _vm.RECORD_TYPE_MAP.BODY_ADD_ACCESS || _vm.recordType === _vm.RECORD_TYPE_MAP.NO_BODY_ADD_ACCESS
            ? '您将会收到工信部发出的短信息验证码（工信部短信将发送至原接入商备案信息中网站负责人手机号码），请您收到短信验证码24 小时内完成短信验证，若未完成验证则备案信息将被退回。具体手机号短信息验证规则表查看帮助中心文档'
            : '主体负责人及网站负责人手机号码将会收到工信部发出的短信息验证码，请您收到短信验证码24 小时内完成短信验证，若未完成验证则备案信息将被退回。具体手机号短信息验证规则表查看帮助中心文档')),_c('a',{staticClass:"blueLink",attrs:{"href":_vm.agreement.sms,"target":"_blank"}},[_vm._v("帮助中心文档")]),_vm._v("。")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('jc-button',{attrs:{"id":"confirmInfoCancel"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('jc-button',{attrs:{"type":"primary","id":"confirmInfoContinueSubmit"},on:{"click":_vm.continueSubmit}},[_vm._v("继续提交")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }