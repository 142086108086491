<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <rc-step :step="recordType === RECORD_TYPE_MAP.CHANGE_IMAGE ? 0 : 2"></rc-step>
      <div class="jc-panel jc-panel-padding form-loading">
        <p class="tip-earlyWarning mb20">
          图片说明：1、将需上传的证件及核验单扫描后上传，保证图片清晰；2、仅支持 jpg 格式，大小在 100K-5M
          之间；3、如果证件核验有问题，请拨打客服热线：400-0988505转2再转9（7*24小时）。
        </p>
        <jc-form :model="form" :rules="rules" ref="form" label-width="150px">
          <rc-section title="主办单位证件上传" v-if="eleOrganizers">
            <span slot="add">
              主办单位名称：{{ principalInfo.organizersName }}&nbsp;&nbsp; 证件类型：{{
                principalInfo.identityType | listFilter(identityTypeOptions, 'code', 'name')
              }}&nbsp;&nbsp;
              <!-- 证件号码：{{principalInfo.identityNo|desenseFilter('idCard')}} -->
              证件号码：{{ principalInfo.identityNo }}
              <template v-if="principalInfo.identityType === 2">
                证件有效期：{{ principalInfo.identityNoValidityPeriod | validityPeriodFilter }}
              </template>
            </span>
            <div v-for="(item, index) in form.license" :key="'lience' + index" label-width="0">
              <jc-form-item :prop="'license.' + index" :rules="atRule('lisence', index)">
                <span slot="label">
                  <span v-if="index < 2">
                    <span>{{ principalInfo.identityType | listFilter(identityTypeOptions, 'code', 'name') }}</span>
                    <span>&nbsp;附件{{ index + 1 }}:</span>
                  </span>
                  <span v-else>辅助材料: </span>
                </span>
                <jc-row>
                  <jc-col :span="4">
                    <rc-upload v-model="form.license[index]" :uploadData="uploadData('licenseData', index)"></rc-upload>
                  </jc-col>
                  <jc-col :span="19" :offset="1" style="position: relative">
                    <upload-example
                      class="step3-type-img"
                      :showUrl="imgExample[`imgtype_${principalInfo.identityType}`]"
                      :largeUrl="imgExample[`imgtypeL_${principalInfo.identityType}`]"
                      v-if="index === 0"
                    ></upload-example>
                  </jc-col>
                </jc-row>
                <p slot="hint" v-if="index + 1 === form.license.length">
                  （选填项）请查看
                  <a :href="agreement.otherInfoIntroduction" target="_blank"> 各省管局辅助材料要求说明</a>
                </p>
              </jc-form-item>
            </div>
            <jc-form-item>
              <p slot="hint">
                1. 请您上传清晰、无污物、完整的证件原件彩色扫描件<br />
                2. 证件必须在有效期内
              </p>
            </jc-form-item>
          </rc-section>
          <rc-section
            :title="
              role !== 5
                ? `主体负责人   ${getIdentityType(principalInfo.ownerIdentityType)}  上传`
                : `${getIdentityType(principalInfo.ownerIdentityType)}上传`
            "
            v-if="eleFlag === 1 ? principalInfo.ownerIdentityType !== 2 && principalInfo.ownerIdentityType !== 7 : true"
          >
            <span slot="add">
              <!-- 主单位负责人：{{principalInfo.ownerName|desenseFilter('name')}}&nbsp;&nbsp; -->
              <!-- 证件号码：{{principalInfo.ownerIdentityNo|desenseFilter('idCard')}} -->
              主单位负责人：{{ principalInfo.ownerName }}&nbsp;&nbsp; 证件号码：{{ principalInfo.ownerIdentityNo }}
            </span>
            <div v-for="(item, index) in form.owner" :key="'owner' + index" label-width="0">
              <jc-form-item :prop="'owner.' + index" :rules="atRule('owner', index)">
                <span slot="label">
                  <span v-if="index < 2">
                    <span>{{ principalInfo.ownerIdentityType | listFilter(identityTypeOptions, 'code', 'name') }}</span>
                    <span>&nbsp;附件{{ index + 1 }}:</span>
                  </span>
                  <span v-else>辅助材料: </span>
                </span>
                <jc-row>
                  <jc-col :span="4">
                    <!-- <rc-upload v-model="form.owner[index]" :uploadData="uploadData('ownerData', index)"></rc-upload> -->
                    <rc-upload
                      v-model="form.owner[index]"
                      :uploadData="uploadData('ownerData', index)"
                      @uploadSuccess="refreshAttechment"
                    ></rc-upload>
                  </jc-col>
                  <jc-col :span="19" :offset="1" style="position: relative">
                    <upload-example
                      class="step3-type-img"
                      :showUrl="imgExample[`imgtype_${principalInfo.identityType}`]"
                      :largeUrl="imgExample[`imgtypeL_${principalInfo.identityType}`]"
                      v-if="index === 0"
                    ></upload-example>
                    <!-- 正面示意图 -->
                    <!-- <upload-example
                      v-if="principalInfo.ownerIdentityType === 2 && index === 0"
                      :showUrl="imgExample.cardFront"
                      :largeUrl="imgExample.cardFrontLarge"
                    ></upload-example> -->
                    <!-- 反面示意图 -->
                    <!-- <upload-example
                      v-if="principalInfo.ownerIdentityType === 2 && index === 1"
                      :showUrl="imgExample.cardOpposite"
                      :largeUrl="imgExample.cardOppositeLarge"
                    ></upload-example> -->
                  </jc-col>
                </jc-row>
                <p slot="hint" v-if="index + 1 === form.owner.length">
                  （选填项）请查看
                  <a :href="agreement.otherInfoIntroduction" target="_blank"> 各省管局辅助材料要求说明</a>
                </p>
              </jc-form-item>
            </div>
            <!-- <jc-form-item prop="ownerFront">
                <span slot="label">
                  <span v-if="[2, 11].includes(principalInfo.ownerIdentityType)">
                    证件正面照片：
                  </span>
                  <span v-else>
                    护照照片：
                  </span>
                </span>
                <jc-row :gutter="10">
                  <jc-col :span="4">
                    <rc-upload v-model="form.ownerFront" :uploadData="uploadData('ownerFrontData')"></rc-upload>
                  </jc-col>
                  <jc-col :span="20">
                    <upload-example v-if="principalInfo.ownerIdentityType === 2" :showUrl="imgExample.cardFront" :largeUrl="imgExample.cardFrontLarge"></upload-example>
                  </jc-col>
                </jc-row>
              </jc-form-item> -->
            <!-- <jc-form-item v-if="[2, 11].includes(principalInfo.ownerIdentityType)" label="证件反面照片：" prop="ownerOpposite">
                <jc-row :gutter="10">
                  <jc-col :span="4">
                    <rc-upload v-model="form.ownerOpposite" :uploadData="uploadData('ownerOppositeData')"></rc-upload>
                  </jc-col>
                  <jc-col :span="20">
                    <upload-example v-if="principalInfo.ownerIdentityType === 2" :showUrl="imgExample.cardOpposite" :largeUrl="imgExample.cardOppositeLarge"></upload-example>
                  </jc-col>
                </jc-row>
              </jc-form-item> -->
          </rc-section>
          <div v-for="(item, index) in siteInfo" :key="item.id">
            <!-- 如果是非同主体负责人，非个人备案，需要电子核验情况下非身份证和非护照时才会展示 -->
            <rc-section
              v-if="
                item.isPrincipalOwner &&
                  role !== 5 &&
                  (eleFlag === 1 ? item.identityType !== 2 && item.identityType !== 7 : true)
              "
              :title="`服务负责人${getIdentityType(item.identityType)}上传`"
            >
              <span slot="add">
                <!-- 服务负责人：{{item.ownerName|desenseFilter('name')}}&nbsp;&nbsp; -->
                <!-- 证件号码：{{item.identityNo|desenseFilter('idCard')}} -->
                服务负责人：{{ item.ownerName }}&nbsp;&nbsp; 证件号码：{{ item.identityNo }}
              </span>
              <div
                v-for="(owner, ownerIndex) in form.siteOwner[index].owner"
                :key="'siteowner' + ownerIndex"
                label-width="0"
              >
                <jc-form-item
                  :prop="'siteOwner.' + index + '.owner.' + ownerIndex"
                  :rules="atRule('siteOwner', index, ownerIndex)"
                >
                  <span slot="label">
                    <span v-if="ownerIndex < 2">
                      <span>{{ item.identityType | listFilter(identityTypeOptions, 'code', 'name') }}</span>
                      <span>&nbsp;附件{{ index + 1 }}:</span>
                    </span>
                    <span v-else>辅助材料: </span>
                  </span>
                  <jc-row>
                    <jc-col :span="4">
                      <rc-upload
                        v-model="form.siteOwner[index].owner[ownerIndex]"
                        :uploadData="uploadData('siteOwnerData', index, ownerIndex)"
                        @uploadSuccess="refreshAttechment"
                      ></rc-upload>
                    </jc-col>
                    <jc-col :span="19" :offset="1" style="position: relative">
                      <upload-example
                        class="step3-type-img"
                        :showUrl="imgExample[`imgtype_${item.identityType}`]"
                        :largeUrl="imgExample[`imgtypeL_${item.identityType}`]"
                        v-if="index === 0"
                      ></upload-example>
                      <!-- <upload-example
                        v-if="item.identityType === 2 && ownerIndex === 0"
                        :showUrl="imgExample.cardFront"
                        :largeUrl="imgExample.cardFrontLarge"
                      ></upload-example>
                      <upload-example
                        v-if="item.identityType === 2 && ownerIndex === 1"
                        :showUrl="imgExample.cardOpposite"
                        :largeUrl="imgExample.cardOppositeLarge"
                      ></upload-example> -->
                    </jc-col>
                  </jc-row>
                  <p slot="hint" v-if="ownerIndex + 1 === form.siteOwner[index].owner.length">
                    （选填项）请查看
                    <a :href="agreement.otherInfoIntroduction" target="_blank"> 各省管局辅助材料要求说明</a>
                  </p>
                </jc-form-item>
              </div>
              <!-- <jc-form-item :prop="'siteOwner.'+ index +'.siteOwnerFront'" :rules="rules.siteOwnerFront">
                <span slot="label">
                  <span v-if="[2, 11].includes(item.identityType)">
                    证件正面照片：
                  </span>
                  <span v-else>
                    护照照片：
                  </span>
                </span>
                <jc-row :gutter="10">
                  <jc-col :span="4">
                    <rc-upload v-model="form.siteOwner[index].siteOwnerFront" :uploadData="uploadData('siteOwnerFrontData', index)"></rc-upload>
                  </jc-col>
                  <jc-col :span="20">
                    <upload-example v-if="item.identityType ===2" :showUrl="imgExample.cardFront" :largeUrl="imgExample.cardFrontLarge"></upload-example>
                  </jc-col>
                </jc-row>
              </jc-form-item>
              <jc-form-item v-if="[2, 11].includes(item.identityType)" label="证件反面照片：" :prop="'siteOwner.'+ index +'.siteOwnerOpposite'" :rules="rules.siteOwnerOpposite">
                <jc-row :gutter="10">
                  <jc-col :span="4">
                    <rc-upload v-model="form.siteOwner[index].siteOwnerOpposite" :uploadData="uploadData('siteOwnerOppositeData', index)"></rc-upload>
                  </jc-col>
                  <jc-col :span="20">
                    <upload-example v-if="item.identityType ===2" :showUrl="imgExample.cardOpposite" :largeUrl="imgExample.cardOppositeLarge"></upload-example>
                  </jc-col>
                </jc-row>
              </jc-form-item> -->
            </rc-section>
          </div>
          <div v-for="(item, index) in siteInfo" :key="`${item.id}-${item.siteName}-check`">
            <rc-section v-if="eleFlag !== 1" title="服务真实性核验单上传">
              <span slot="add">
                服务名称：{{ item.siteName || '--' }}&nbsp;&nbsp;
                <span v-if="domainInfo.find(domain => domain.siteId === item.id)"
                  >服务域名：{{ domainInfo.find(domain => domain.siteId === item.id).domain }}</span
                >
              </span>
              <jc-form-item label="真实核验单：" :prop="'siteOwner.' + index + '.siteCheck'" :rules="rules.siteCheck">
                <jc-row>
                  <jc-col :span="4">
                    <rc-upload
                      v-model="form.siteOwner[index].siteCheck"
                      :uploadData="uploadData('siteCheckData', index)"
                    ></rc-upload>
                  </jc-col>
                  <jc-col :span="19" :offset="1">
                    <upload-example
                      :showUrl="imgExample.checkSheet"
                      :largeUrl="imgExample.checkSheetLarge"
                      :largeWidth="450"
                      :bottom="true"
                    ></upload-example>
                  </jc-col>
                </jc-row>
                <p slot="hint">
                  1. 请下载
                  <a :href="agreement.verificationSheet" target="_blank">《服务备案信息真实性核验单》</a><br />
                  2. 按照样例提示填写，不得涂改，注意不要填写日期<br />
                  3. 扫描其中一份已经填写的核验单并上传到这里，保留两份原件以备后续邮寄
                </p>
              </jc-form-item>
              <jc-form-item label="辅助材料：">
                <jc-row>
                  <jc-col :span="4">
                    <rc-upload
                      v-model="form.siteOwner[index].siteCheckOther"
                      :uploadData="uploadData('siteCheckOtherData', index)"
                    ></rc-upload>
                  </jc-col>
                </jc-row>
                <p slot="hint">
                  （选填项）请查看
                  <a :href="agreement.otherInfoIntroduction" target="_blank"> 各省管局辅助材料要求说明</a>
                </p>
              </jc-form-item>
            </rc-section>
          </div>
          <!-- <div v-for="(item, index) in siteInfo" :key="`${item.id}-${item.siteName}-preApproval`">
            <rc-section title="服务前置审批附件上传" v-if="item.preApprovalOption !== 0">
              <span slot="add">
                名称：{{ item.siteName || '--' }}&nbsp;&nbsp;
                <span v-if="domainInfo.find(domain => domain.siteId === item.id)"
                  >首页 URL：{{ domainInfo.find(domain => domain.siteId === item.id).homeUrl }}</span
                >&nbsp;&nbsp;
                <span>前置审批号：{{ item.preApprovalNo }}</span>
              </span>
              <jc-form-item
                label="前置审批附件："
                :prop="'siteOwner.' + index + '.sitePreApprovalNo'"
                :rules="rules.siteCheck"
              >
                <jc-row :gutter="10">
                  <jc-col :span="4">
                    <rc-upload
                      v-model="form.siteOwner[index].sitePreApprovalNo"
                      :uploadData="uploadData('preApprovalNo', index)"
                    ></rc-upload>
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </rc-section>
          </div> -->
          <rc-section
            v-if="isShowField('domainCertificate') && domainInfo && domainInfo.length"
            :title="isDomainRealnameProvince ? '域名实名认证截图' : '上传域名证书'"
          >
            <jc-form-item
              v-for="(item, index) in domainInfo"
              :key="item.domainId"
              :prop="'domains.' + index"
              :rules="rules.domains"
            >
              <div>域名：{{ item.domain || '--' }}</div>
              <jc-row>
                <jc-col :span="4">
                  <rc-upload
                    v-model="form.domains[index]"
                    :uploadData="uploadData('domainCertificateData', index)"
                  ></rc-upload>
                </jc-col>
                <!-- <jc-col :span="4" :offset="1">
                  <h4>域名：</h4>
                  <p>{{ item.domain || '--' }}</p>
                </jc-col> -->
                <jc-col :span="19" :offset="1">
                  <upload-example
                    :showUrl="imgExample.commitCommunity"
                    :largeUrl="
                      isDomainRealnameProvince ? imgExample.domainRealnameExample : imgExample.domainCertExample
                    "
                  ></upload-example>
                </jc-col>
              </jc-row>
            </jc-form-item>
            <div v-if="!isDomainRealnameProvince" style="padding-left: 150px;color:rgba(0,0,0,0.65)">
              请上传域名的证书，如果您的域名是京东云购买的域名，请访问<a
                target="_blank"
                href="https://net-console.jdcloud.com/domain/index"
                >域名控制台</a
              >， 在操作中点击【管理】进入到域名管理页面，切换到【域名证书】tab页下载证书。
            </div>
          </rc-section>

          <rc-section
            v-if="showAppRisk && siteInfo && siteInfo.findIndex(site => site.appServiceType === 'app') > -1"
            title="反诈承诺书"
          >
            <template v-for="(item, index) in siteInfo">
              <jc-form-item
                v-if="item.appServiceType === 'app'"
                :key="item.siteId"
                :prop="'appRisk.' + index"
                :rules="rules.appRisk"
              >
                <jc-row>
                  <jc-col :span="4">
                    <rc-upload
                      v-model="form.appRisk[index]"
                      :uploadData="uploadData('attachmentUrlAppRisk', index)"
                    ></rc-upload>
                  </jc-col>
                  <jc-col :span="19" :offset="1">
                    <h4>APP：{{ item.siteName || '--' }}</h4>
                  </jc-col>
                </jc-row>
              </jc-form-item>
            </template>
          </rc-section>

          <rc-section title="承诺书上传" v-if="eleFlag === 1">
            <span slot="add">
              <!-- 服务负责人：{{siteInfo[0] && siteInfo[0].ownerName|desenseFilter('name')}}&nbsp;&nbsp; -->
              <!-- <span>证件号码：{{siteInfo[0] && siteInfo[0].identityNo|desenseFilter('idCard')}}</span> -->
              服务负责人：{{ siteInfo[0] && siteInfo[0].ownerName }}&nbsp;&nbsp;
              <span>证件号码：{{ siteInfo[0] && siteInfo[0].identityNo }}</span>
            </span>
            <jc-form-item label="承诺书：" prop="commitmentPaper">
              <jc-row>
                <jc-col :span="4">
                  <rc-upload v-model="form.commitmentPaper" :uploadData="uploadData('commitmentPaper')"></rc-upload>
                </jc-col>
                <jc-col :span="19" :offset="1">
                  <upload-example
                    :showUrl="imgExample.commitCommunity"
                    :largeUrl="imgExample.commitCommonExample"
                  ></upload-example>
                </jc-col>
              </jc-row>
              <p v-if="isCommonTemplate()" slot="hint">
                1.
                <span style="font-weight: 600;">您在承诺书位置点击放大镜的+号，看到承诺书，代表承诺书已默认上传，</span
                >您不需更改承诺书，可直接进行下一步操作，表示您同意承诺书内容；<br />
                2. 如在承诺书位置未默认上传承诺书，请按照样例图提示下载承诺书和填写，并进行上传，内容不得涂改。<br />
              </p>
              <p v-else slot="hint">
                1. 请下载
                <a href="javascript:void(0)" @click="downloadCommiment">《互联网信息服务备案承诺书》</a><br />
                2. 按照样例图提示填写，不得涂改。<br />
              </p>
            </jc-form-item>
          </rc-section>

          <jc-collapse v-if="eleFlag === 1">
            <jc-collapse-item>
              <template slot="title"
                >其他辅助材料上传
                <jc-button type="primary" style="margin-left:10px">点击展开</jc-button>
              </template>

              <rc-section>
                <span slot="add">
                  <b style="color: #484d6a; font-size: 12px;"
                    >以下为选填内容，请根据下面文字提示，补充上传本省要求的辅助资料</b
                  >
                </span>

                <jc-form-item v-if="isShowField('principalOther')" label="主体辅助材料：" prop="" label-width="150px">
                  <jc-row>
                    <div>主体名称：{{ principalInfo.organizersName }}&nbsp;&nbsp;</div>
                    <jc-col v-for="attIndex in [2, 3, 4]" :key="attIndex" :span="4">
                      <jc-form-item v-if="form.license[attIndex]" label="" :prop="'license.' + attIndex">
                        <rc-upload
                          v-model="form.license[attIndex]"
                          :uploadData="uploadData('licenseData', attIndex)"
                        ></rc-upload>
                      </jc-form-item>
                    </jc-col>
                  </jc-row>
                  <div v-html="mainAuxiliaryMaterialsRule" v-show="mainAuxiliaryMaterialsRule"></div>
                </jc-form-item>

                <jc-form-item
                  v-if="isShowField('principalOwnerOther')"
                  label="主体负责人辅助材料："
                  prop=""
                  label-width="150px"
                >
                  <jc-row>
                    <div>
                      主体负责人：{{ principalInfo.ownerName }}&nbsp;&nbsp;
                      <!-- 证件号码：{{ principalInfo.ownerIdentityNo }} -->
                    </div>
                    <jc-col v-for="attIndex in [2, 3, 4]" :key="attIndex" :span="4">
                      <jc-form-item v-if="form.owner[attIndex]" label="" :prop="'owner.' + attIndex">
                        <rc-upload
                          v-model="form.owner[attIndex]"
                          :uploadData="uploadData('ownerData', attIndex)"
                        ></rc-upload>
                      </jc-form-item>
                    </jc-col>
                  </jc-row>
                </jc-form-item>

                <jc-form-item v-if="isShowField('siteOther')" label="服务辅助材料：" prop="" label-width="150px">
                  <div v-for="(item, index) in siteInfo" :key="item.id">
                    <div>
                      服务：{{ item.siteName }}&nbsp;&nbsp;
                      <!-- 证件号码：{{ item.identityNo }} -->
                    </div>
                    <jc-row>
                      <jc-col v-for="attIndex in [1, 2, 3]" :key="attIndex" :span="4">
                        <jc-form-item
                          v-if="form.siteOwner[index].siteOther[attIndex]"
                          label=""
                          :prop="'siteOwner.' + index + '.siteOther.' + attIndex"
                        >
                          <rc-upload
                            v-model="form.siteOwner[index].siteOther[attIndex]"
                            :uploadData="uploadData('siteOtherData', index, attIndex)"
                          ></rc-upload>
                        </jc-form-item>
                      </jc-col>
                    </jc-row>
                  </div>
                  <div v-show="websiteSupportingMaterialsRule" v-html="websiteSupportingMaterialsRule"></div>
                </jc-form-item>

                <jc-form-item
                  v-if="isShowField('siteOwnerOther')"
                  label="服务负责人辅助材料："
                  prop=""
                  label-width="150px"
                >
                  <div v-for="(item, index) in siteInfo" :key="item.id">
                    <div>
                      服务名称：{{ item.siteName }}&nbsp;&nbsp;服务负责人：{{ item.ownerName }}&nbsp;&nbsp;
                      <!-- 证件号码：{{ item.identityNo }} -->
                    </div>
                    <jc-row>
                      <jc-col v-for="attIndex in [2, 3, 4]" :key="attIndex" :span="4">
                        <jc-form-item
                          v-if="form.siteOwner[index].owner[attIndex]"
                          label=""
                          :prop="'siteOwner.' + index + '.owner.' + attIndex"
                        >
                          <rc-upload
                            v-model="form.siteOwner[index].owner[attIndex]"
                            :uploadData="uploadData('siteOwnerData', index, attIndex)"
                          ></rc-upload>
                        </jc-form-item>
                      </jc-col>
                    </jc-row>
                    <div
                      v-show="websiteManagerSupportingMaterialsRule"
                      v-html="websiteManagerSupportingMaterialsRule"
                    ></div>
                  </div>
                </jc-form-item>
              </rc-section>
            </jc-collapse-item>
          </jc-collapse>
        </jc-form>
      </div>
      <div class="jc-panel jc-panel-action">
        <jc-button @click="back">
          上一步
        </jc-button>
        <jc-button type="primary" @click.prevent="nextStep" id="noPersonStepThirdVerifyNext">下一步</jc-button>
      </div>
    </div>
  </div>
</template>
<script>
import { IMG_LINK, AGREEMENT, at_3, RECORD_TYPE_MAP, DOMAIN_REALNAME_PROVINCES } from '@/views/service/constant.js'
import {
  getAttachmentInfo,
  updateStatus,
  getSiteInfo,
  getPrincipalInfo,
  generateCommiment,
  queryRule
} from '@/service/record-api'
import { UPLOAD_FIRST_TRIAL_RUlE } from '@/views/service/rules'
import apiParams from '@/mixins/upload.mixins.js'
import { OWNER_IDENTITY_TYPE } from '@/views/service/constant'
import profileMixins from '@/mixins/profile.mixins.js'
import { atSort } from '@/utils'
export default {
  props: {},
  mixins: [apiParams, profileMixins],
  components: {
    uploadExample: () => import('@/views/submit/src/record.information/components/upload.example.vue')
  },
  data() {
    return {
      recordNo: this.$route.params.recordNo || '',
      type: this.$route.query.type || '', // 目前有changeImage变更图片
      imgExample: IMG_LINK,
      mainAuxiliaryMaterialsRule: '',
      websiteSupportingMaterialsRule: '',
      websiteManagerSupportingMaterialsRule: '',
      form: {
        // license: {
        //   // 工商营业执照
        //   imgUrl: '',
        //   attachmentId: ''
        // },
        license: [],
        owner: [],
        ownerFront: {
          // 主单位负责人身份证上传 正面
          imgUrl: '',
          attachmentId: ''
        },
        ownerOpposite: {
          // 主单位负责人身份证上传 反面
          imgUrl: '',
          attachmentId: ''
        },
        commitmentPaper: {
          // 承诺书
          imgUrl: '',
          attachmentId: ''
        },
        // 服务负责人身份证上传 真实核验单
        siteOwner: [],
        // 域名证书,
        domains: [],
        appRisk: [],
        otherInfo: [
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' },
          { imgUrl: '', attachmentId: '' }
        ] //其他辅助材料
      },
      rules: UPLOAD_FIRST_TRIAL_RUlE.call(this),
      // rules: {},
      siteInfo: [],
      domainInfo: [],
      principalInfo: {},
      attachmentInfo: {},
      agreement: AGREEMENT,
      RECORD_TYPE_MAP,
      showAppRisk: false
    }
  },
  watch: {},
  computed: {
    ownerIdentityType() {
      let identityType = this.principalInfo.ownerIdentityType || 2
      return OWNER_IDENTITY_TYPE.find(val => val.value === identityType).label
    },
    eleOrganizers() {
      if (this.eleFlag === 1) {
        return (
          this.role !== 5 && this.principalInfo.identityType !== 1 && this.principalInfo.identityType !== 4
          // 电子核验主办单位证件1(营业执照)4(事业法人证书)需拍照上传
        )
      } else {
        return this.role !== 5
      }
    },
    isDomainRealnameProvince() {
      const { provinceCode } = this.principalInfo

      return DOMAIN_REALNAME_PROVINCES.findIndex(x => parseInt(provinceCode) === x) > -1
    }
  },
  mounted() {},
  async created() {
    await this.getTextRules()
    this.init()
  },
  filters: {},
  methods: {
    //获取文本规则
    async getTextRules() {
      try {
        let result = await getPrincipalInfo({
          recordNo: this.recordNo
        })
        let { data } = await queryRule({
          type: this.role == 5 ? 0 : 1,
          provinceCode: result.data.provinceCode
        })
        this.mainAuxiliaryMaterialsRule = data.find(item => item.fieldCode === 5)
          ? data.find(item => item.fieldCode === 5).content
          : ''
        this.websiteSupportingMaterialsRule = data.find(item => item.fieldCode === 6)
          ? data.find(item => item.fieldCode === 6).content
          : ''
        this.websiteManagerSupportingMaterialsRule = data.find(item => item.fieldCode === 7)
          ? data.find(item => item.fieldCode === 7).content
          : ''
      } catch (err) {
        console.log(err)
      }
    },
    // 上传附件参数
    uploadData(type, index, ownerIndex) {
      // type: 1：主体证件 2：主体负责人证件3：服务负责人证件4：服务真实性核验单5：幕布照片6：域名证书
      // 7：前置审批号附件8：活体照片9：承诺书10：活体视频11：其他辅助资料上传12：现场核验照片
      switch (type) {
        case 'licenseData': {
          // 工商执照 辅助材料 345
          return this.apiParams({
            attachmentType: 1,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.license[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'ownerData': {
          // 主单位负责人证件 正面  辅助材料 345
          return this.apiParams({
            attachmentType: 2,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.owner[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'siteOwnerData': {
          // 服务负责人证件 辅助材料 345
          return this.apiParams({
            attachmentType: 3,
            recordNo: this.recordNo,
            sortNum: ownerIndex + 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].owner[ownerIndex].attachmentId,
            recordType: this.recordType
          })
        }

        case 'siteCheckData': {
          // 真实核验单
          return this.apiParams({
            attachmentType: 4,
            recordNo: this.recordNo,
            sortNum: 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].siteCheck.attachmentId,
            recordType: this.recordType
          })
        }
        case 'siteCheckOtherData': {
          // 真实核验单辅助材料
          return this.apiParams({
            attachmentType: 4,
            recordNo: this.recordNo,
            sortNum: 2,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].siteCheckOther.attachmentId,
            recordType: this.recordType
          })
        }
        case 'siteOtherData': {
          // 网站辅助材料，sortNum 1为真实核验单，2~4为辅助材料 电子化核验场景使用
          return this.apiParams({
            attachmentType: 4,
            recordNo: this.recordNo,
            sortNum: ownerIndex + 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].siteOther[ownerIndex].attachmentId,
            recordType: this.recordType
          })
        }
        case 'preApprovalNo': {
          // 前置审批号
          return this.apiParams({
            attachmentType: 7,
            recordNo: this.recordNo,
            sortNum: 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.siteOwner[index].sitePreApprovalNo.attachmentId,
            recordType: this.recordType
          })
        }
        case 'domainCertificateData': {
          // 域名证书
          return this.apiParams({
            attachmentType: 6,
            recordNo: this.recordNo,
            sortNum: 1,
            siteId: this.domainInfo[index].siteId,
            domainId: this.domainInfo[index].id,
            attachmentId: this.form.domains[index].attachmentId,
            recordType: this.recordType
          })
        }
        case 'attachmentUrlAppRisk': {
          // 反诈
          return this.apiParams({
            attachmentType: 13,
            recordNo: this.recordNo,
            sortNum: 1,
            siteId: this.siteInfo[index].id,
            attachmentId: this.form.appRisk[index] ? this.form.appRisk[index].attachmentId : '',
            recordType: this.recordType
          })
        }
        case 'commitmentPaper': {
          // 承诺书
          return this.apiParams({
            attachmentType: 9,
            recordNo: this.recordNo,
            sortNum: 1,
            attachmentId: this.form.commitmentPaper.attachmentId,
            recordType: this.recordType
          })
        }
        case 'otherInfo': {
          // 其他辅助材料
          return this.apiParams({
            attachmentType: 11,
            recordNo: this.recordNo,
            sortNum: index + 1,
            attachmentId: this.form.otherInfo[index].attachmentId,
            recordType: this.recordType
          })
        }
      }
    },
    async init(refresh = false) {
      if (!refresh) {
        await this.getPrincipalInfo()

        await this.generateCommiment()
      }

      // 初始化服务相关上传内容
      const promise = [this.getSiteInfo(), this.getAttachmentInfo()]
      await Promise.all(promise)
      Object.keys(this.attachmentInfo).forEach(att => {
        let attaInfo = this.attachmentInfo[att]
        if (attaInfo[0]) {
          switch (att) {
            case 'attachmentUrlYYZZZm': {
              //营业执照
              let arr = [...attaInfo]
              arr = atSort(arr)
              arr.forEach(val => {
                this.form.license[val.sortNum - 1].attachmentId = val.id
                this.form.license[val.sortNum - 1].imgUrl = val.url
                // val.elePassFlag !== 1 && this.eleFlag === 1 ? '' : val.url
              })
              break
            }
            case 'attachmentUrlOwnerZm': {
              // 主体负责人
              let arr = [...attaInfo]
              arr = atSort(arr)
              arr.forEach(val => {
                this.form.owner[val.sortNum - 1].attachmentId = val.id
                this.form.owner[val.sortNum - 1].imgUrl = val.url
                // val.elePassFlag !== 1 && this.eleFlag === 1 ? '' : val.url
              })
              break
            }
            case 'attachmentUrlFZRZm': {
              // 服务负责人正面
              this.siteInfo.forEach((site, index) => {
                let siteAttInfo = attaInfo.filter(item => item.siteId === site.id)
                if (siteAttInfo.length) {
                  siteAttInfo = atSort(siteAttInfo)
                  siteAttInfo.forEach(val => {
                    this.form.siteOwner[index].owner[val.sortNum - 1].attachmentId = val.id
                    this.form.siteOwner[index].owner[val.sortNum - 1].imgUrl = val.url
                    // val.elePassFlag !== 1 && this.eleFlag === 1 ? '' : val.url
                  })
                }
              })
              break
            }
            // 服务真实性核验单？
            case 'attachmentUrlHyd': {
              this.siteInfo.forEach((site, index) => {
                let siteAttInfo = attaInfo.filter(item => item.siteId === site.id)
                if (siteAttInfo.length) {
                  siteAttInfo.forEach(item => {
                    if (item.sortNum === 1) {
                      this.form.siteOwner[index].siteCheck.imgUrl = item.url
                      this.form.siteOwner[index].siteCheck.attachmentId = item.id
                    } else {
                      this.form.siteOwner[index].siteCheckOther.imgUrl = item.url
                      this.form.siteOwner[index].siteCheckOther.attachmentId = item.id
                    }
                  })

                  siteAttInfo = atSort(siteAttInfo)
                  siteAttInfo.forEach(val => {
                    this.form.siteOwner[index].siteOther[val.sortNum - 1].attachmentId = val.id
                    this.form.siteOwner[index].siteOther[val.sortNum - 1].imgUrl = val.url
                    // val.elePassFlag !== 1 && this.eleFlag === 1 ? '' : val.url
                  })
                }
              })
              break
            }
            case 'attachmentUrlCns': {
              this.form.commitmentPaper.imgUrl = attaInfo[0].url
              this.form.commitmentPaper.attachmentId = attaInfo[0].id
              break
            }
            case 'attachmentUrlQzsp': {
              this.siteInfo.forEach((site, index) => {
                const siteAttInfo = attaInfo.find(item => item.siteId === site.id)
                if (siteAttInfo) {
                  this.form.siteOwner[index].sitePreApprovalNo.imgUrl = siteAttInfo.url
                  this.form.siteOwner[index].sitePreApprovalNo.attachmentId = siteAttInfo.id
                }
              })
              break
            }
            case 'attachmentUrlYmzs': {
              this.domainInfo.forEach((domain, index) => {
                const domainAttInfo = attaInfo.find(item => item.domainId === domain.id)
                if (domainAttInfo) {
                  this.form.domains[index].imgUrl = domainAttInfo.url
                  this.form.domains[index].attachmentId = domainAttInfo.id
                }
              })
              break
            }
            case 'attachmentUrlAppRisk': {
              this.siteInfo.forEach((site, index) => {
                const siteAttInfo = attaInfo.find(item => item.siteId === site.id)
                if (siteAttInfo) {
                  this.form.appRisk[index] = {
                    imgUrl: siteAttInfo.url,
                    attachmentId: siteAttInfo.id
                  }
                } else {
                  this.form.appRisk[index] = {
                    imgUrl: '',
                    attachmentId: ''
                  }
                }
              })
              break
            }
            case 'attachmentUrlOtherInfo': {
              // 其他材料
              attaInfo.forEach((otherInfo, index) => {
                let info = this.form.otherInfo[index]
                info.imgUrl = otherInfo.url
                info.attachmentId = otherInfo.id
              })
            }
          }
        }
      })
    },
    refreshAttechment() {
      this.init(true)
    },
    // 获取附件信息
    async getAttachmentInfo() {
      let { data } = await getAttachmentInfo({ recordNo: this.recordNo })
      this.attachmentInfo = data
    },
    // 获取服务信息
    async getSiteInfo() {
      try {
        let { data } = await getSiteInfo({ recordNo: this.recordNo })
        this.siteInfo = data.recordSites
        const recordSitesMap = {}
        data.recordSites.map(site => {
          recordSitesMap[site.id] = site
        })

        this.domainInfo = data.recordDomains.filter(domain => {
          return !recordSitesMap[domain.siteId].appServiceType || recordSitesMap[domain.siteId].appServiceType === 'web'
        })
      } catch (err) {
        console.error(err)
      }

      // 初始化服务相关
      this.siteInfo.forEach((item, index) => {
        if (!this.form.appRisk[index]) {
          this.form.appRisk[index] = {
            imgUrl: '',
            attachmentId: ''
          }
        }
        let sitImg = {
          siteCheck: {
            // 真实核验单
            imgUrl: '',
            attachmentId: ''
          },
          siteCheckOther: {
            // 真实核验单辅助材料
            imgUrl: '',
            attachmentId: ''
          },
          owner: [],
          siteOther: [],
          sitePreApprovalNo: {
            // 前置审批号附件
            imgUrl: '',
            attachmentId: ''
          },
          siteCommit: {
            // 承诺书
            imgUrl: '',
            attachmentId: ''
          }
        }
        let count = at_3.includes(item.identityType) ? 6 : 5
        for (let i = count; i > 0; i--) {
          sitImg.owner.push({
            imgUrl: '',
            attachmentId: ''
          })
        }

        for (let i = count - 1; i > 0; i--) {
          sitImg.siteOther.push({
            imgUrl: '',
            attachmentId: ''
          })
        }
        this.form.siteOwner.push(sitImg)
      })
      // 初始化域名证书
      this.domainInfo.forEach(domain => {
        let domainImg = {
          imgUrl: domain.certificateUrl,
          attachmentId: domain.attachmentId
        }
        this.form.domains.push(domainImg)
      })
    },
    // 查询主体详情
    async getPrincipalInfo() {
      try {
        let { data } = await getPrincipalInfo({
          recordNo: this.recordNo
        })
        this.principalInfo = data
        let count = at_3.includes(data.identityType) ? 6 : 5
        for (let i = count; i > 0; i--) {
          this.form.license.push({
            imgUrl: '',
            attachmentId: ''
          })
        }
        let ownerCount = at_3.includes(data.ownerIdentityType) ? 6 : 5
        for (let i = ownerCount; i > 0; i--) {
          this.form.owner.push({
            imgUrl: '',
            attachmentId: ''
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    isCommonTemplate() {
      const { provinceCode } = this.principalInfo
      // 上海、广东需要下载, 不自动生成
      // 广东44 上海31 山西14
      if (parseInt(provinceCode) === 44 || parseInt(provinceCode) === 31 || parseInt(provinceCode) === 14) {
        return false
      }

      return true
    },
    async generateCommiment() {
      const { provinceCode, organizersNature, organizersName, id } = this.principalInfo

      if (!this.isCommonTemplate()) {
        return
      }

      try {
        await generateCommiment({
          province: provinceCode,
          organizersNature: organizersNature,
          companyName: organizersName,
          recordNo: this.recordNo,
          principalId: id
        })
      } catch (error) {
        console.error(error)
      }
    },
    async downloadCommiment() {
      if (this.isCommonTemplate()) {
        return
      }

      const { provinceCode, organizersNature, organizersName, id } = this.principalInfo

      try {
        const res = await generateCommiment(
          {
            province: provinceCode,
            organizersNature: organizersNature,
            companyName: organizersName,
            recordNo: this.recordNo,
            principalId: id
          },
          {
            responseType: 'blob' // important
          }
        )
        // https://stackoverflow.com/a/26129406/5184758
        let blob = new Blob([res])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = '互联网信息服务备案承诺书.jpg'
        link.click()
      } catch (error) {
        console.error(error)
      }
    },
    getIdentityType(value) {
      let identityType = value || 2
      return OWNER_IDENTITY_TYPE.find(val => val.value === identityType).label
    },
    // 下一步
    nextStep() {
      const step = this.eleFlag ? 'eleCheck' : 'confirm'
      this.$refs.form.validate(async valid => {
        if (valid) {
          // const result = await validatePic({
          //   recordNo: this.recordNo,
          //   identityType: this.siteInfo[0].identityType
          // })
          // if(!result.data) {
          //   this.$message.error('请完善页面信息')
          //   location.reload(1)
          //   return
          // }
          const { data } = await updateStatus({
            recordNo: this.recordNo,
            status: step
          })
          if (data) {
            this.$message.success('提交成功')
            this.$router.push({
              name: step,
              params: { recordNo: this.recordNo }
            })
          } else {
            this.$message.error('修改状态失败')
          }
        } else {
          this.$message({ type: 'error', message: '请完善页面信息' })
        }
      })
    },
    // 上一步
    back() {
      // 主体变更跳过第2步
      if (this.recordType === RECORD_TYPE_MAP.CHANGE_PRINCIPAL) {
        this.$router.push({
          name: 'step1',
          params: { recordNo: this.recordNo }
        })
      } else {
        this.$router.push({
          name: 'step2',
          params: { recordNo: this.recordNo }
        })
      }
    },
    atRule(type, index, ownerIndex) {
      if (type === 'lisence') {
        if (index === 0) {
          return this.rules.file
        } else {
          return this.rules.noValidate
        }
      } else if (type === 'owner') {
        if (index === 0) {
          return this.rules.file
        } else if (index === 1 && this.principalInfo.ownerIdentityType === 2) {
          return this.rules.file
        } else {
          return this.rules.noValidate
        }
      } else if (type === 'siteOwner') {
        if (ownerIndex === 0) {
          return this.rules.file
        } else if (ownerIndex === 1 && this.siteInfo[index].identityType === 2) {
          return this.rules.file
        } else {
          return this.rules.noValidate
        }
      }
    }
  }
}
</script>
<style>
.step3-type-img {
  position: absolute !important;
  height: 220px;
  border: none !important;
}
.step3-type-img > img {
  height: 100%;
}
</style>
