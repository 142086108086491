<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content" v-loading="loading">
      <rc-step :step="3"></rc-step>
      <div class="jc-panel jc-panel-padding form-loading">
        <p class="tip-earlyWarning mb20" v-if="recordNo">
          备案订单号：{{ recordNo }}
          <a href="#" class="blueLink ml10" @click="show" id="trialSucBrowse">浏览备案信息</a>
          <a href="#" class="blueLink ml10" @click.prevent="quitMessage" id="trialSucGiveUp">放弃本次流程</a>
          <br />恭喜您，已通过京东云备案初审！<span v-if="isJiangsu" style="color:red;"
            >请前往拍照核验点免费办理拍照，办理拍照的人需为网站负责人</span
          ><span v-else>请尽快办理拍照，办理拍照的人需为网站负责人</span>
        </p>
        <div class="jc-section" v-if="isJiangsu">
          <div class="jc-section-header">
            <h3 class="jc-section-title">拍照核验</h3>
          </div>
          <div class="jc-section-content">
            <jc-steps :space="160" :active="step" direction="vertical">
              <jc-step>
                <div slot="title">
                  <h3>请网站负责人本人携带以下资料前往拍照核验点</h3>
                </div>
                <div slot="description">
                  <ol class="info-data-ol">
                    <li>网站负责人本人身份证原件</li>
                    <li>
                      <a href="https://docs.jdcloud.com/cn/icp-license-service/check-list-download" target="_blank"
                        >网站备案真实性核验单</a
                      >
                    </li>
                    <li>
                      <a href="https://docs.jdcloud.com/cn/icp-license-service/jiangsuziliao" target="_blank"
                        >网站备案信息真实性责任告知书</a
                      >
                    </li>
                    <li>
                      <a href="https://docs.jdcloud.com/cn/icp-license-service/jiangsuziliao" target="_blank"
                        >若网站负责人非法人，需提供法人授权书</a
                      >
                    </li>
                  </ol>
                </div>
              </jc-step>
              <jc-step>
                <div slot="title">
                  <h3>
                    拍照核验点地址如下：（拍照核验点工作时间：工作日9:00 - 18:00） 备案业务咨询请拨打：400-098-8505转2。
                  </h3>
                </div>
                <div slot="description">
                  <ul class="area-ul">
                    <li>
                      宿迁市拍照核验点地址：江苏省宿迁市湖滨新区龙泉路199号 陆启周 15665179658；
                    </li>
                    <li>
                      南京市拍照核验点地址：江苏省南京市江宁区麒麟街东麒路277号富力科技园9栋京东云2楼217 陈玲英
                      15811109821；
                    </li>
                  </ul>
                </div>
              </jc-step>
              <jc-step title="将拍好的核验照片上传">
                <div slot="description">
                  <jc-form :model="imgForm" ref="imgForm" :rules="imgRules" class="jc-form-small-width">
                    <jc-row :gutter="20">
                      <jc-col :span="8" v-for="(ownerName, index) in infoData.ownerNameList" :key="ownerName">
                        <jc-form-item :prop="'screen.' + index" :rules="imgRules.screen">
                          <rc-upload
                            v-model="imgForm.screen[index]"
                            :uploadData="uploadData('screenData', index)"
                          ></rc-upload>
                          <p slot="hint">jpg 格式，5M 以内</p>
                        </jc-form-item>
                      </jc-col>
                    </jc-row>
                  </jc-form>
                </div>
              </jc-step>
            </jc-steps>
          </div>
        </div>
        <div class="jc-section" v-else>
          <div class="jc-section-header">
            <h3 class="jc-section-title">
              幕布拍照
              <small>申请幕布邮寄，您自行拍照后上传</small>
            </h3>
          </div>
          <div class="jc-section-content">
            <jc-steps :space="120" :active="step" direction="vertical">
              <jc-step>
                <div slot="title">
                  <h3>申请幕布并填写收件地址<small>幕布免费邮寄，无需寄回</small></h3>
                </div>
                <div slot="description">
                  <template v-if="infoData.applicationStatus == -1">
                    <jc-button :plain="true" size="small" @click.prevent="applyShow = true" id="trialSucApply">
                      未申请，点击申请邮寄幕布
                    </jc-button>
                  </template>
                  <template v-else>
                    <jc-button :plain="true" size="small" @click.prevent="getDetail" id="trialSucMail">
                      <template v-if="infoData.applicationStatus == 1">已申请</template>
                      <template v-else>已邮寄</template> 查看收货地址及快递信息
                    </jc-button>
                  </template>
                </div>
              </jc-step>
              <jc-step>
                <div slot="title">
                  <h3>
                    收到幕布后，请
                    <strong class="jc-status-warning">{{ nameList }}</strong>
                    在幕布前拍照
                  </h3>
                </div>
                <div slot="description">
                  <jc-button :plain="true" size="small" @click.prevent="instructionShow = true" id="trialSucPhoto"
                    >查看拍照说明</jc-button
                  >
                </div>
              </jc-step>
              <jc-step title="将拍好的幕布照片上传">
                <div slot="description">
                  <jc-form :model="imgForm" ref="imgForm" :rules="imgRules" class="jc-form-small-width">
                    <jc-row :gutter="20">
                      <jc-col :span="8" v-for="(ownerName, index) in infoData.ownerNameList" :key="ownerName">
                        <jc-form-item :prop="'screen.' + index" :rules="imgRules.screen">
                          <p>
                            {{ ownerName }}
                          </p>
                          <rc-upload
                            v-model="imgForm.screen[index]"
                            :uploadData="uploadData('screenData', index)"
                          ></rc-upload>
                          <p slot="hint">jpg 格式，5M 以内</p>
                        </jc-form-item>
                      </jc-col>
                    </jc-row>
                  </jc-form>
                </div>
              </jc-step>
            </jc-steps>
          </div>
        </div>
      </div>
      <div class="jc-panel jc-panel-action form-loading">
        <jc-button type="primary" @click.prevent="submitInfo" id="trialSucSubmitPhoto">提交</jc-button>
      </div>
    </div>
    <!-- 备案详情 -->
    <confirm-dialog ref="confirm"></confirm-dialog>
    <!--申请幕布-->
    <jc-dialog class="screen-form-loading" title="申请幕布" :visible.sync="applyShow" @close="applyClose">
      <div class="i-text mb20">
        <div class="tip-earlyWarning">
          请填写收件地址，申请后京东云将在 2 个工作日内进行邮寄
        </div>
      </div>
      <jc-form :model="screenForm" ref="screenForm" :rules="screenRules" v-if="applyShow" label-width="120px">
        <jc-form-item label="收件人：" prop="recipientName">
          <jc-input
            v-model.trim="screenForm.recipientName"
            type="text"
            placeholder="请输入收件人姓名"
            id="trialSucRecipientName"
          ></jc-input>
        </jc-form-item>
        <jc-form-item label="手机：" prop="recipientPhone">
          <jc-input
            v-model.trim="screenForm.recipientPhone"
            type="text"
            placeholder="请输入手机号"
            id="trialSucRecipientPhone"
          ></jc-input>
        </jc-form-item>
        <jc-form-item label="地址：" prop="region">
          <province-select v-model="screenForm.region" :provinces="provinceOptions"> </province-select>
        </jc-form-item>
        <jc-form-item label="详细地址：" prop="mailingAddress">
          <jc-input
            v-model.trim="screenForm.mailingAddress"
            type="text"
            placeholder="请输入详细地址"
            id="trialSucMailingAddress"
          ></jc-input>
        </jc-form-item>
      </jc-form>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="applyShow = false" id="trialSucScreenCancel">取 消</jc-button>
        <jc-button type="primary" @click="submitApply" id="trialSucScreenSure">确 定</jc-button>
      </span>
    </jc-dialog>
    <!--邮寄详情-->
    <jc-dialog class="detail-form-loading" title="邮寄详情" :visible.sync="detailShow">
      <h3 class="text-bold">收件信息：</h3>
      <ul class="listItem mb20">
        <li class="txt">
          <p class="tit">收件人：</p>
          <div class="con">{{ postDetail.recipientName }}</div>
        </li>
        <li class="txt">
          <p class="tit">手机：</p>
          <div class="con">{{ postDetail.recipientPhone }}</div>
        </li>
        <li class="txt">
          <p class="tit">地址：</p>
          <div class="con">
            <span class="mr10">{{ postDetail.provinceCode }}</span>
            <span class="mr10">{{ postDetail.cityCode }}</span>
            <span class="mr10">{{ postDetail.countyCode }}</span>
          </div>
        </li>
        <li class="txt">
          <p class="tit">详细地址：</p>
          <div class="con">{{ postDetail.mailingAddress }}</div>
        </li>
      </ul>
      <h3 class="text-bold">快递信息：</h3>
      <ul class="listItem">
        <li class="txt">
          <p class="tit">物流公司：</p>
          <div class="con">{{ postDetail.courierCompany || '暂无信息' }}</div>
        </li>
        <li class="txt">
          <p class="tit">快递编号：</p>
          <div class="con">{{ postDetail.trackingNumber || '暂无信息' }}</div>
          <div class="con color-9" v-if="postDetail.trackingNumber">
            请登录快递公司官网查询快递进展。
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <jc-button type="primary" @click="detailShow = false" id="trialSucScreenSure">确定</jc-button>
      </span>
    </jc-dialog>
    <!-- 拍照说明 -->
    <jc-dialog title="拍照说明" :visible.sync="instructionShow" size="large">
      <jc-row>
        <jc-col :span="10" :offset="2"><img src="~assets/images/temp/ad1.png"/></jc-col>
        <jc-col :span="11" :offset="1">
          <dl>
            <dt>1. 拍照人与网站负责人必须为同一人</dt>
            <dd style="margin-bottom: 10px;">
              <img src="~assets/images/temp/ad2.png" />
            </dd>
            <dt>2. 负责人需站在背景幕布中间位置，仅拍上半身即可</dt>
            <dd style="margin-bottom: 10px;">
              <img src="~assets/images/temp/ad3.png" />
            </dd>
            <dt>3. 整个背景画布必须都是京东云画布，幕布为蓝色且字迹清晰</dt>
            <dd><img src="~assets/images/temp/ad4.png" /></dd>
          </dl>
        </jc-col>
      </jc-row>
      <span slot="footer" class="dialog-footer">
        <jc-button type="primary" @click="instructionShow = false" id="trialSucPhotoSure">确 定</jc-button>
      </span>
    </jc-dialog>
  </div>
</template>
<script>
import apiParams from '@/mixins/upload.mixins.js'
import confirm from '@/mixins/confirm.mixins.js'
import quitMessage from '@/mixins/quit.mixins.js'
import { firstTrialPass, saveCurtainInfo, getPostDetail, updateStatus, getPrincipalInfo } from '@/service/record-api'
import { SCREEN_POST_RULE, SCREEN_UPLOAD_RULE } from '@/views/service/rules'
import profileMixins from '@/mixins/profile.mixins.js'
export default {
  props: [],
  components: {
    provinceSelect: () => import('@/views/submit/src/components/record.province.vue'),
    confirmDialog: () => import('@/views/submit/src/record.status/components/confirm.dialog.vue')
  },
  mixins: [apiParams, confirm, quitMessage, profileMixins], // 上传图片参数， 确认备案信息， 放弃本次流程
  data() {
    return {
      recordNo: this.$route.params.recordNo,
      infoData: {},
      screenForm: {
        recipientName: '',
        recipientPhone: '',
        region: {
          province: '',
          city: '',
          county: ''
        },
        mailingAddress: ''
      },
      imgForm: {
        screen: []
      },
      postDetail: {},
      instructionShow: false, // 幕布介绍弹窗
      applyShow: false, // 申请幕布弹窗
      detailShow: false, // 幕布详情
      screenRules: SCREEN_POST_RULE,
      imgRules: SCREEN_UPLOAD_RULE,
      isJiangsu: false,
      loading: false
    }
  },
  mounted() {},
  created() {
    this.getPrincipalInfo()
    this.getInit()
  },
  watch: {},
  computed: {
    nameList() {
      if (this.infoData.ownerNameList) {
        return this.infoData.ownerNameList.join(', ')
      }
    },
    step() {
      if (this.infoData.applicationStatus == -1) {
        return 0
      } else if (this.imgForm.screen[0] && this.imgForm.screen[0].imgUrl) {
        return 2
      } else {
        return 1
      }
    }
  },
  filters: {},
  methods: {
    uploadData(type, index) {
      switch (type) {
        case 'screenData': {
          // 域名证书
          return this.apiParams({
            attachmentType: 5,
            recordNo: this.recordNo,
            sortNum: 1,
            siteId: this.infoData.recordSiteList[index].id,
            attachmentId: this.imgForm.screen[index].attachmentId,
            recordType: this.recordType
          })
        }
      }
    },
    // 邮寄申请
    async submitApply() {
      this.$refs.screenForm.validate(async valid => {
        if (valid) {
          let params = {
            ...this.screenForm,
            provinceCode: this.screenForm.region.province,
            cityCode: this.screenForm.region.city,
            countyCode: this.screenForm.region.county,
            recordNo: this.recordNo
          }
          delete params.region
          try {
            await saveCurtainInfo(params)
            this.getInit()
            this.applyShow = false
          } catch (error) {
            console.error(error)
          }
        }
      })
    },
    applyClose() {
      this.$refs.screenForm.resetFields()
    },
    // 获取邮寄详情
    async getDetail() {
      let params = {
        recordNo: this.recordNo
      }
      try {
        let { data } = await getPostDetail(params)
        this.postDetail = data
        this.detailShow = true
      } catch (error) {
        console.error(error)
      }
    },
    async submitInfo() {
      this.$refs.imgForm.validate(async valid => {
        if (valid) {
          let params = {
            recordNo: this.recordNo,
            status: 'waitAudit'
          }
          try {
            const { data } = await updateStatus(params)
            if (data) {
              this.outMsg('success', '提交幕布成功，请等待审核')
              this.$router.push({
                name: 'audit',
                params: {
                  recordNo: this.recordNo
                }
              })
            } else {
              this.outMsg('error', '提交失败')
            }
          } catch (error) {
            console.error(error)
            this.outMsg('error', '提交幕布失败，请检查网络')
          }
        }
      })
    },
    show() {
      this.$refs.confirm.showDialog()
    },
    async getInit() {
      try {
        let { data } = await firstTrialPass({
          recordNo: this.recordNo
        })
        this.infoData = data
        data.ownerNameList.forEach(item => {
          data.recordSiteList.find(val => val.ownerName === item)
          let img = {
            imgUrl: item.attachmentUrlMb,
            attachmentId: item.attachmentMbId
          }
          this.imgForm.screen.push(img)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getPrincipalInfo() {
      this.loading = true
      let { data } = await getPrincipalInfo({
        recordNo: this.recordNo
      })
      if (data.provinceCode === '32') {
        this.isJiangsu = true
      }
      this.loading = false
    }
  }
}
</script>
<style>
.is-vertical .jc-step__icon {
  display: block;
}
.is-vertical .jc-step__head {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border-color: #e8e8e8;
  background-color: #e8e8e8;
}
.is-vertical .jc-step__title {
  color: #212121;
  font-size: 14px;
  margin-bottom: 16px;
}
.is-vertical .jc-step__main {
  text-align: left;
  position: static;
  display: inline-block;
  width: auto;
  height: auto;
}
.jc-step__line.is-vertical {
  left: 13px;
  top: 30px;
  bottom: 2px;
  background-color: #e8e8e8;
}
.is-vertical .jc-step__head:before,
.is-vertical .jc-step__head:after {
  display: none;
}
.jc-step.is-vertical {
  overflow: visible;
  height: auto;
}
.is-vertical .jc-step__description {
  color: #757575;
}
h3 small,
h4 small,
h5 small {
  font-size: 75%;
  color: #9e9e9e;
  margin-left: 10px;
}
.info-data-ol {
  padding-left: 10px;
}
.info-data-ol li,
.area-ul li {
  font-size: 12px;
  line-height: 20px;
}
.info-data-ol li a {
  font-size: 12px;
}
.info-data-ol li a:visited {
  color: #2aafff !important;
}
</style>
