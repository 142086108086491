export default {
  props: [],
  components: {
    confirmDialog: () => import('@/views/submit/src/record.status/components/confirm.dialog.vue')
  },
  mixins: [],
  data() {
    return {
      showDialog: false
    }
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    showConfirm() {
      this.showDialog = true
    }
  }
}
