import {
  REGION_RULE,
  CARD_RULE,
  VALIDITY_PERIOD_RULE,
  IP_RULE,
  DOMAIN_RULE,
  APP_NAME_RULE,
  APP_DOMAIN_RULE,
  UPLOAD_RULE,
  AGREEMENT_RULE,
  OWNER_NAME_RULE,
  STEP1_CARD_RULE
} from '@/views/service/rules/validate.rule.js'
export const VERIFY_RULE = function() {
  return {
    region: [
      {
        validator: REGION_RULE,
        trigger: 'change'
      }
    ],
    identityNo: [
      {
        required: true,
        message: '请输入证件号码',
        trigger: 'blur'
      },
      {
        validator: CARD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    ownerIdentityNoValidityPeriod: [
      {
        required: true,
        message: '请选择证件有效期'
      },
      {
        validator: VALIDITY_PERIOD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    recordIp: [
      {
        required: true,
        validator: IP_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    miitRecordNo: [
      {
        required: true,
        message: '请输入备案号',
        trigger: 'blur'
      }
    ],
    /* 新增服务类型相关 */
    // 服务类型
    serviceInfoType: [
      {
        required: true,
        message: '请选择服务类型',
        trigger: 'blur'
      }
    ],
    appServiceType: [
      {
        required: true,
        message: '请选择服务类型',
        trigger: 'blur'
      }
    ],
    // 域名
    domain: [
      {
        required: true,
        message: '请输入域名',
        trigger: 'blur'
      },
      {
        validator: DOMAIN_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    recordDomain: [
      {
        required: true,
        message: '请输入域名',
        trigger: 'blur'
      },
      {
        validator: DOMAIN_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    // APP名称
    siteName: [
      {
        required: true,
        message: '请输入APP名称',
        trigger: 'blur'
      },
      {
        validator: APP_NAME_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    recordAppName: [
      {
        required: true,
        message: '请输入APP名称',
        trigger: 'blur'
      },
      {
        validator: APP_NAME_RULE.bind(this),
        trigger: 'blur'
      }
    ]
  }
}

export const STEP1_RULE = function() {
  return {
    identityNo: [
      {
        required: true,
        message: '请输入证件号码',
        trigger: 'blur'
      },
      {
        validator: STEP1_CARD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    ownerIdentityNoValidityPeriod: [
      {
        required: true,
        message: '请选择证件有效期'
      },
      {
        validator: VALIDITY_PERIOD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    region: [
      {
        validator: REGION_RULE,
        trigger: 'change'
      }
    ]
  }
}
// 个人主体
export const PRINCIPAL_PERSONAL_RULE = function() {
  return {
    organizersName: [
      {
        required: true,
        message: '请输入主办人姓名',
        trigger: 'blur'
      }
    ],
    indentityAddress: [
      {
        required: true,
        message: '请输入身份证的地址',
        trigger: 'blur'
      }
    ],
    detailAddress: [
      {
        required: true,
        message: '请输入实际通信地址，必须精确到门牌号',
        trigger: 'blur'
      }
    ],
    miitRecordPwd: [
      {
        required: true,
        message: '请输入备案密码',
        trigger: 'blur'
      }
    ]
  }
}
// 非个人主体
export const PRINCIPAL_COMMUNITY_RULE = function() {
  return {
    organizersName: [
      {
        required: true,
        message: '请输入主办单位名称',
        trigger: 'blur'
      }
    ],
    // investorName: [
    //   {
    //     required: true,
    //     message: '请输入投资者或单位主管',
    //     trigger: 'blur'
    //   }
    // ],
    indentityAddress: [
      {
        required: true,
        message: '请输入证件地址',
        trigger: 'blur'
      }
    ],
    detailAddress: [
      {
        required: true,
        message: '请输入实际通信地址，必须精确到门牌号',
        trigger: 'blur'
      }
    ],
    miitRecordPwd: [
      {
        required: true,
        message: '请输入备案密码',
        trigger: 'blur'
      }
    ]
  }
}
// 个人负责人
export const OWNER_PERSONAL_RULE = {
  mobile: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      pattern: /^1\d{10}$/,
      message: '请输入合法手机号',
      trigger: 'blur'
    }
  ],
  emergencyCall: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      pattern: /^1\d{10}$/,
      message: '请输入合法手机号',
      trigger: 'blur'
    }
  ],
  email: [
    {
      required: true,
      type: 'email',
      message: '请输入正确的邮箱地址',
      trigger: 'blur'
    }
  ],
  smsCode: [
    {
      required: true,
      message: '请输入手机验证码',
      trigger: 'blur'
    }
  ]
}
// 非个人负责人
export const OWNER_COMMUNITY_RULE = function() {
  return {
    ownerName: [
      {
        required: true,
        validator: OWNER_NAME_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    ownerIdentityNo: [
      {
        required: true,
        message: '请输入证件号码'
      },
      {
        validator: CARD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    ownerIdentityNoValidityPeriod: [
      {
        required: true,
        message: '请选择证件有效期'
      },
      {
        validator: VALIDITY_PERIOD_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    address: [
      {
        required: true,
        message: '请选择地区',
        trigger: 'blur'
      }
    ],
    mobile: [
      {
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      {
        pattern: /^1\d{10}$/,
        message: '请输入合法手机号',
        trigger: 'blur'
      }
    ],
    emergencyCall: [
      {
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      },
      {
        pattern: /^1\d{10}$/,
        message: '请输入合法手机号',
        trigger: 'blur'
      }
    ],
    email: [
      {
        required: true,
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: 'blur'
      }
    ],
    phoneAreaCode: [
      // { required: true, message: '请输入区号', trigger: 'blur' },
      {
        pattern: /^\d{3,4}$/,
        message: '区号填写错误',
        trigger: 'blur'
      }
    ],
    phoneWithoutAreaCode: [
      // { required: true, message: '请输入电话号码', trigger: 'blur' },
      {
        pattern: /^\d{7,8}$/,
        message: '请输入合法的电话号码',
        trigger: 'blur'
      }
    ],
    phonGlobalRoaming: [
      {
        pattern: /^[a-zA-Z0-9]+$/,
        message: '国家编码填写错误',
        trigger: 'blur'
      }
    ],
    smsCode: [
      {
        required: true,
        message: '请输入手机验证码',
        trigger: 'blur'
      }
    ]
  }
}

export const WEBSITE_RULES = function() {
  return {
    appServiceType: [
      {
        required: true,
        message: '请选择服务类型',
        trigger: 'change'
      }
    ],
    siteName: [
      {
        required: true,
        message: '请输入服务名称',
        trigger: 'blur'
      }
    ],
    serviceValue: [
      {
        required: true,
        message: '请输入备案授权码',
        trigger: 'blur'
      },
      {
        validator: IP_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    domain: [
      {
        validator: DOMAIN_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    preApprovalNo: [
      {
        required: true,
        message: '请输入前置审批号',
        trigger: 'blur'
      },
      {
        max: 100,
        message: '限制100个字符',
        trigger: 'blur'
      }
    ],
    // appServiceType: [
    //   {
    //     required: true,
    //     message: '请选择应用服务类型',
    //     trigger: 'change'
    //   }
    // ],
    // siteType: [
    //   {
    //     required: true,
    //     message: '请选择主网站服务内容',
    //     trigger: 'change'
    //   }
    // ],
    // preApprovalOption: [
    //   {
    //     required: true,
    //     message: '请选择前置审批选项',
    //     trigger: 'change'
    //   }
    // ],
    // siteLanguage: [
    //   {
    //     required: true,
    //     message: '请选择语言类别',
    //     trigger: 'change'
    //   }
    // ]
    'appInfoVo.appIcon': [
      {
        required: true,
        message: '请上传APP图标',
        trigger: 'change'
      }
    ],
    'appInfoVo.sdkOfferFlag': [
      {
        required: true,
        message: '请选择APP是否提供SDK服务',
        trigger: 'change'
      }
    ]
  }
}
/* 新增APP信息校验 */
export const APP_INFO_RULES = function() {
  return {
    appName: [
      {
        required: true,
        message: '请输入APP名称',
        trigger: 'blur'
      }
    ],
    systemType: [
      {
        required: true,
        message: '请选择平台类型',
        trigger: 'blur'
      }
    ],
    buildName: [
      {
        required: true,
        message: '请输入包名',
        trigger: 'blur'
      }
    ],
    bundleID: [
      {
        required: true,
        message: '请输入Bundle_ID',
        trigger: 'blur'
      }
    ],
    processName: [
      {
        required: true,
        message: '请输入进程名称',
        trigger: 'blur'
      }
    ],
    systemName: [
      {
        required: true,
        message: '请输入平台名称',
        trigger: 'blur'
      }
    ],
    appIcon: [
      {
        required: true,
        message: '请上传APP图标',
        trigger: 'blur'
      }
    ],
    appKey: [
      {
        required: true,
        message: '请输入公钥',
        trigger: 'blur'
      }
    ],
    appMD5: [
      {
        required: true,
        message: '请输入MD5签名值',
        trigger: 'blur'
      }
    ],
    appDomain: [
      {
        required: true,
        message: '请输入域名',
        trigger: 'blur'
      },
      {
        validator: APP_DOMAIN_RULE.bind(this),
        trigger: 'blur'
      }
    ],
    isSdk: [
      {
        required: true,
        message: '请选择APP是否提供SDK服务',
        trigger: 'blur'
      }
    ],
    isThirdSdk: [
      {
        required: true,
        message: '请选择APP是否使用三方SDK',
        trigger: 'blur'
      }
    ],
    sdkService: [
      {
        required: true,
        message: '请选择SDK服务商',
        trigger: 'blur'
      }
    ],
    sdkServiceType: [
      {
        required: true,
        message: '请选择SDK服务类型',
        trigger: 'blur'
      }
    ]
  }
}

export const UPLOAD_FIRST_TRIAL_RUlE = function() {
  return {
    file: [
      {
        validator: UPLOAD_RULE.bind(this),
        trigger: 'change'
      }
    ],
    noValidate: [],
    siteCheck: [
      {
        validator: UPLOAD_RULE.bind(this),
        trigger: 'change'
      }
    ],
    domains: [
      {
        validator: UPLOAD_RULE.bind(this),
        trigger: 'change'
      }
    ],
    appRisk: [
      {
        validator: UPLOAD_RULE.bind(this),
        trigger: 'change'
      }
    ],
    commitmentPaper: [
      {
        validator: UPLOAD_RULE.bind(this),
        trigger: 'change'
      }
    ]
  }
}
export const CONFIRM_REULE = {
  agreement: [
    {
      validator: AGREEMENT_RULE,
      trigger: 'change'
    }
  ]
}
export const SCREEN_POST_RULE = {
  recipientName: [
    {
      required: true,
      message: '请填写收件人',
      trigger: 'blur'
    }
  ],
  recipientPhone: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      pattern: /^1\d{10}$/,
      message: '请输入合法手机号',
      trigger: 'blur'
    }
  ],
  region: [
    {
      validator: REGION_RULE,
      trigger: 'change'
    }
  ],
  mailingAddress: [
    {
      required: true,
      message: '请填写详细地址',
      trigger: 'blur'
    }
  ]
}
export const SCREEN_UPLOAD_RULE = {
  screen: [
    {
      type: 'object',
      required: true,
      fields: {
        imgUrl: {
          type: 'string',
          required: true,
          message: '请上传图片'
        }
      },
      trigger: 'change'
    }
  ]
}
