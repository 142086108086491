import { mapState } from 'vuex'
import { isShowField } from '@/utils/hiddenFields'

export default {
  computed: {
    ...mapState({
      role: state => state.record.role, // 主体证件类型 5 个人 其他 非个人
      recordType: state => state.record.recordType, // 备案类型 1 新增备案 2 无主题新增网站 3 有主题新增网站 4 主体变更 5 网站变更
      eleFlag: state => state.record.eleFlag // 电子核验标识 0 否 1 是
    })
  },
  methods: {
    isShowField(fieldName) {
      // console.log(
      //   'isShowField',
      //   fieldName,
      //   isShowField(fieldName, this.recordType, this.role),
      //   this.eleFlag
      // )
      // if (!this.eleFlag) {
      //   return true
      // }
      return isShowField(fieldName, this.recordType, this.role)
    }
  }
}
