import { isFit } from '../utils'
/**
 * 【姓名】仅掩码第一位 *梅娟
 * @param {String} name
 */
function name(name) {
  if (!isFit(name)) return ''

  if (typeof name === 'number') {
    name = String(name)
  }

  name = name.slice(0, 1).replace(/\S/g, '*') + name.slice(1)

  return name
}
export default name
