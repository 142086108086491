<template>
  <div class="innerCon">
    <div class="white-box">
      <jc-alert
        type="error"
        :closable="false"
        title="重要提示：首次在京东云提交备案信息请点击“开始备案”按钮，京东云备案审核员会根据您提交的备案信息判断您提交的备案类型，对您的已备案成功的备案信息无影响，请知悉！如有问题请拨打京东云客服热线：400-0988505转2再转9（7*24小时）。"
      ></jc-alert>
      <h3 class="large-title">互联网服务备案管理</h3>
      <p class="f14 dark-grey tcenter">
        京东云为您提供快速便捷的备案服务，助您快速完成备案，获得国内互联网服务发布和运营的能力。
      </p>
      <div class="pt45 mb50 tcenter">
        <jc-button type="primary" @click="toVerify">
          开始备案
        </jc-button>
      </div>
      <div class="reference-step-show"></div>
    </div>
  </div>
</template>
<script>
import { getRealNameStatus } from '@/service/record-api'
export default {
  data() {
    return {}
  },
  props: [],
  components: {},
  mixins: [],
  methods: {
    async toVerify() {
      try {
        const {
          data: { result }
        } = await getRealNameStatus()
        if (result.data === 0) {
          window.open(derivativeConfig.realname, '_blank')
        } else {
          this.$router.push({ name: 'verify' })
        }
      } catch (error) {
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_START_RECORD',
            msg: '开始备案错误：' + error.message
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {},
  filters: {}
}
</script>
