import { filterEmptyProperties } from '@/utils'
export default {
  props: [],
  components: {},
  mixins: [],
  data() {
    return {}
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    apiParams(params) {
      // attachmentType 附件类型 principalId 主体id sortNum 正面/反面 siteId 域名id attachmentId 附件id
      return filterEmptyProperties(params)
    }
  }
}
