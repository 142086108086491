/* eslint-disable */
/**
 * 时间戳转date类型
 */
export function timeStampToDate(nS) {
  let data = new Date()
  data.setTime(nS)
  return data
}
/**
 *
 * 判断false
 * @export
 * @param {Object} option:
 * @returns
 */
export function isNull(_name) {
  if (_name === '' || _name === undefined || _name === null) return true
  return false
}
/**
 * 深拷贝
 * @export
 * @param {Object} option:
 * @returns
 */
export function deepClone(currobj) {
  if (typeof currobj !== 'object' || currobj === null) {
    return currobj
  }
  let newobj
  if (currobj instanceof Array) {
    newobj = []
  } else {
    newobj = {}
  }
  let currQue = [currobj]
  let newQue = [newobj]
  while (currQue.length) {
    let obj1 = currQue.shift()
    let obj2 = newQue.shift()
    for (let key in obj1) {
      if (typeof obj1[key] !== 'object' || obj1[key] === null) {
        obj2[key] = obj1[key]
      } else {
        if (obj1[key] instanceof Array) {
          obj2[key] = []
        } else {
          obj2[key] = {}
        }
        currQue.push(obj1[key])
        newQue.push(obj2[key])
      }
    }
  }
  return newobj
}

/**
 * json对象中的数组转为字符串
 * @params Json
 * @return Json
 */
export function JsonArrayToString(jsonData) {
  Object.keys(jsonData).forEach(item => {
    if (Array.isArray(jsonData[item])) {
      jsonData[item] = jsonData[item].join(',')
    }
  })
  return jsonData
}

/**
 * 过滤 value 为空的字段
 *
 * @export
 * @param {Object} [obj={}]
 * @returns {Object}
 */
export function filterEmptyProperties(obj = {}) {
  return Object.keys(obj).reduce((preObj, curKey) => {
    if (obj[curKey] !== '' && obj[curKey] != null) {
      preObj[curKey] = obj[curKey]
    }
    return preObj
  }, {})
}

/**
 *
 * 筛选资源
 * @export
 * @param {*} value
 * @param {*} error
 */
export function filterParams(value, error) {
  let nullError = ''
  if (!isNull(error)) {
    nullError = error
  }
  try {
    if (isNUll(value)) {
      return nullError
    }
    return value
  } catch (err) {
    return nullError
  }
}

/**
 *
 * select过滤
 * @export
 * @param {Object} option:
 * @params val 选中值
 * @params list{Array} 数组
 * @params key{string} 键名
 * @returns 选中的对象
 */
export function filterArray(val, list, key) {
  if (!isNull(val) && !isNull(list) && !isNull(key)) {
    return list.find((cur, index, arr) => cur[key] === val)
  }
  return ''
}

/**
 * 获取cookie
 * @export
 * @param {Object} option:
 * @returns
 */
export function getCookie(val) {
  try {
    let cookie = document.cookie
    let cookieList = []
    cookieList = cookie.split('; ')
    for (let i = 0; i < cookieList.length; i++) {
      let splitCookie = cookieList[i].split('=')
      if (splitCookie[0] === val) {
        return decodeURI(splitCookie[1])
      }
    }
    return 0
  } catch (err) {
    console.log(err)
  }
}

/**
 * 获取控制台菜单展示的pin
 * @export
 * @param {Object} option:
 * @returns
 */
export function getMenuPin() {
  try {
    let menuDom = document.getElementsByClassName('user')
    if (menuDom && menuDom.length > 0) {
      let pinDom = menuDom[menuDom.length - 1].children
      return pinDom[0].innerText
    }
    return 0
  } catch (err) {
    console.log(err)
  }
}

/**
 * 类似于Object.assign obj1中没有的键值不会被拷贝
 *
 * @export
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
export function ObjectAssignSingle(obj1, obj2) {
  if (typeof obj1 !== 'object') {
    return obj2
  } else if (obj1 instanceof Array && obj2 instanceof Array) {
    return obj2
  } else {
    let obj1Key = Object.keys(obj1)
    let obj2Key = Object.keys(obj2)
    obj1Key.forEach(key1 => {
      obj2Key.forEach(key2 => {
        if (key1 === key2) {
          // ObjectAssignSingle(obj1[key1],obj2[key2])
          if (typeof obj1[key1] !== 'object') {
            obj1[key1] = obj2[key2]
          } else if (obj1 instanceof Array && obj2 instanceof Array) {
            obj1[key1] = obj2[key2]
          } else {
            ObjectAssignSingle(obj1[key1], obj2[key2])
          }
        }
      })
    })
  }
}

export function filterArrayNew(val, list, key = 'code', name = 'name') {
  if (!isNull(val) && !isNull(list) && !isNull(key)) {
    let data = list.find(cur => cur[key] === val)
    return data ? data[name] : val
  }
  return ''
}

export function atSort(sortArr) {
  console.log(sortArr)
  sortArr.sort((a, b) => {
    return b.modified - a.modified
  })
  let arr = sortArr.reduce((arr, cur) => {
    if (!arr.find(val => val.sortNum === cur.sortNum)) {
      arr.push(cur)
    }
    return arr
  }, [])
  arr.sort((a, b) => {
    return a.sortNum - b.sortNum
  })
  return arr
}

export const checkIDCard = id => {
  const regex = /^[1-9]\d{5}(18|19|20)?\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}(\d|[xX])$/
  if (!regex.test(id)) {
    return false
  }

  // 加权因子
  const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验位
  const parity = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
  let sum = 0
  let ai = 0
  let wi = 0
  for (let i = 0; i < 17; i++) {
    ai = id[i]
    wi = factor[i]
    sum += ai * wi
  }
  const last = parity[sum % 11]
  if (last != id[17].toUpperCase()) {
    return false
  }
  return true
}
