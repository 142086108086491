import idCard from './idCard'
import mail from './mail'
import mobile from './mobile'
import name from './name'
import phone from './phone'
import address from './address'
export default {
  idCard,
  mail,
  mobile,
  name,
  phone,
  address
}
