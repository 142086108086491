<template>
  <div>
    <rc-section title="主体信息">
      <jc-button v-if="desense" type="primary" @click="desense = false" style="margin-bottom:8px">查看全部</jc-button>
      <jc-button v-else type="primary" @click="desense = true" style="margin-bottom:8px">加密信息</jc-button>
      <table class="demo-table">
        <tr>
          <th>主体备案号</th>
          <td>{{ principalInfo.miitRecordNo }}</td>
        </tr>
        <tr v-if="principalInfo.organizersNature === 5">
          <th>主办人姓名</th>
          <td v-if="desense">
            {{ principalInfo.organizersName | desenseFilter('name') }}
          </td>
          <td v-else>{{ principalInfo.organizersName }}</td>
        </tr>
        <tr v-else>
          <th>主办单位名称</th>
          <td>{{ principalInfo.organizersName }}</td>
        </tr>
        <tr>
          <th>主办单位性质</th>
          <td>
            {{ principalInfo.organizersNature | organizersNatureFilter(organizersNatureOptions) }}
          </td>
        </tr>
        <tr>
          <th>证件类型</th>
          <td>
            {{ principalInfo.identityType | identityTypeFilter(identityTypeOptions) }}
          </td>
        </tr>
        <tr>
          <th>证件号</th>
          <td v-if="principalInfo.organizersNature === 5 && desense">
            {{ principalInfo.identityNo | desenseFilter('idCard') }}
          </td>
          <td v-else>{{ principalInfo.identityNo }}</td>
        </tr>
        <tr>
          <th>主办单位所属区域</th>
          <td>
            {{ `${principalInfo.provinceName}${principalInfo.cityName}${principalInfo.countyName}` }}
          </td>
        </tr>
        <!-- <tr>
          <th>主办单位名称</th>
          <td>{{principalInfo.organizersName}}</td>
        </tr>
        <tr>
          <th>投资者或单位主管</th>
          <td>{{principalInfo.investorName}}</td>
        </tr> -->
        <tr>
          <th>主办单位证件地址</th>
          <td v-if="desense">
            {{ principalInfo.indentityAddress | desenseFilter('address') }}
          </td>
          <td v-else>{{ principalInfo.indentityAddress }}</td>
        </tr>
        <tr>
          <th>主办单位通信地址</th>
          <td v-if="desense">
            {{ principalInfo.detailAddress | desenseFilter('address') }}
          </td>
          <td v-else>{{ principalInfo.detailAddress }}</td>
        </tr>
        <tr>
          <th>主体备注信息</th>
          <td>{{ principalInfo.remark }}</td>
        </tr>
      </table>
    </rc-section>
    <rc-section title="负责人信息">
      <jc-button v-if="ownerDesense" type="primary" @click="ownerDesense = false" style="margin-bottom:8px"
        >查看全部</jc-button
      >
      <jc-button v-else type="primary" @click="ownerDesense = true" style="margin-bottom:8px">加密信息</jc-button>
      <table class="demo-table">
        <tr>
          <th>姓名</th>
          <td v-if="ownerDesense">
            {{ principalInfo.ownerName | desenseFilter('name') }}
          </td>
          <td v-else>{{ principalInfo.ownerName }}</td>
        </tr>
        <tr>
          <th>证件类型</th>
          <td>
            {{ principalInfo.ownerIdentityType | identityTypeFilter(identityTypeOptions) }}
          </td>
        </tr>
        <tr>
          <th>证件号码</th>
          <td v-if="ownerDesense">
            {{ principalInfo.ownerIdentityNo | desenseFilter('idCard') }}
          </td>
          <td v-else>{{ principalInfo.ownerIdentityNo }}</td>
        </tr>
        <tr>
          <th>办公电话</th>
          <td>{{ principalInfo.phone }}</td>
        </tr>
        <tr>
          <th>手机号</th>
          <td v-if="ownerDesense">
            {{ principalInfo.mobile | desenseFilter('mobile') }}
          </td>
          <td v-else>{{ principalInfo.mobile }}</td>
        </tr>
        <tr>
          <th>应急电话号码</th>
          <td v-if="ownerDesense">
            {{ principalInfo.emergencyCall | desenseFilter('mobile') }}
          </td>
          <td v-else>{{ principalInfo.emergencyCall }}</td>
        </tr>
        <tr>
          <th>电子邮箱地址</th>
          <td v-if="ownerDesense">
            {{ principalInfo.email | desenseFilter('mail') }}
          </td>
          <td v-else>{{ principalInfo.email }}</td>
        </tr>
        <tr>
          <th>备注信息</th>
          <td>{{ principalInfo.remark }}</td>
        </tr>
      </table>
    </rc-section>
  </div>
</template>
<script>
import { getPrincipalInfoByPin } from '@/service/record-api'
import profileMixins from '@/mixins/profile.mixins.js'
export default {
  props: {
    desense: Boolean,
    ownerDesense: Boolean
  },
  components: {},
  mixins: [profileMixins],
  data() {
    return {
      principalInfo: {}
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getPrincipalInfoByPin()
  },
  created() {},
  filters: {},
  methods: {
    async getPrincipalInfoByPin() {
      let { data } = await getPrincipalInfoByPin()
      console.log('getPrincipalInfoByPin', data)
      this.principalInfo = data
    }
  }
}
</script>
