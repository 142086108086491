const state = {
  upgradeInfo: {},
  identityTypeOptions: [],
  provinceOptions: [],
  serviceTypeOptions: [],
  organizersNatureOptions: [],
  preApprovalOptions: [],
  languageOptions: [],
  appServiceTypeOptions: [],
  sdkCsOptions: [],
  sdkFwlxOptions: [],
  appTypeOptions: [],
  siteTypesTreeOptions: []
}

const siteTypeListToTree = siteTypeList => {
  let siteTypesTreeOptions = []
  try {
    siteTypeList
      .filter(x => !x.gjParentId)
      .map(item => {
        siteTypesTreeOptions.push({
          value: item.gjId,
          label: item.localName,
          children: []
        })
      })

    siteTypeList
      .filter(x => !!x.gjParentId)
      .map(item => {
        const parent = siteTypesTreeOptions.find(x => x.value === item.gjParentId)
        if (parent) {
          parent.children.push({
            value: item.gjId,
            label: item.localName
            // children: []
          })
        }
      })
  } catch (error) {
    console.log(error)
  }

  return siteTypesTreeOptions
}

const mutations = {
  ADD_UPGRADE_INFO(state, value) {
    state.upgradeInfo = value
  },
  ADD_IDENTI_TYPE_OPTION(state, value) {
    state.identityTypeOptions = value
  },
  ADD_PROVINCE_OPTION(state, value) {
    state.provinceOptions = value
  },
  ADD_SERVICE_TYPE_OPTION(state, value) {
    state.serviceTypeOptions = value
  },
  ADD_ORGANIZERS_NATURE_OPTION(state, value) {
    state.organizersNatureOptions = value
  },
  ADD_PREAPPROVAL_OPTIONS(state, value) {
    state.preApprovalOptions = value
  },
  ADD_LANGUAGE_OPTIONS(state, value) {
    state.languageOptions = value
  },
  ADD_APP_SERVICE_TYPE_OPTIONS(state, value) {
    state.appServiceTypeOptions = value
  },
  ADD_BY_TYPE_OPTIONS(state, value) {
    state.sdkCsOptions = value.sdkCsOptions || []
    state.sdkFwlxOptions = value.sdkFwlxOptions || []
    state.appTypeOptions = value.appTypeOptions || []
    state.siteTypesTreeOptions = siteTypeListToTree(value.appTypeOptions)
  }
}

export default {
  state,
  mutations
}
