<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <rc-step :step="eleFlag ? 3 : recordType === RECORD_TYPE_MAP.CHANGE_IMAGE ? 0 : 2"></rc-step>
      <div class="jc-panel jc-panel-padding form-loading">
        <rc-section title="主体信息">
          <span slot="link" v-if="[1, 4].includes(recordType)">
            <router-link
              :to="{
                name: 'step1',
                params: { recordNo: recordNo },
                query: { step: 'confirm' }
              }"
              >编辑</router-link
            >
          </span>
          <component :is="principalUser" :infoData="infoData"></component>
        </rc-section>
        <rc-section title="主体资料信息">
          <confirm-picture :type="1" :infoData="infoData" key="1" v-if="role !== 5"></confirm-picture>
          <confirm-picture :type="2" :infoData="infoData" key="2"></confirm-picture>
        </rc-section>
        <rc-section v-for="item in infoData.siteInfo" :key="item.siteName" :title="`服务信息`">
          <span slot="link">
            <router-link
              :to="{
                name: 'step2',
                params: { recordNo: recordNo },
                query: { step: 'confirm' }
              }"
              >编辑</router-link
            >
          </span>
          <rc-section title="基本信息">
            <component :is="websiteUser" :siteData="item" :infoData="infoData"></component>
          </rc-section>
          <rc-section title="资料信息">
            <div v-if="eleFlag !== 1">
              <confirm-picture :type="role !== 5 ? 3 : 4" :infoData="infoData" :siteData="item"></confirm-picture>
            </div>
            <div v-else>
              <confirm-picture :type="5" :infoData="infoData" :siteData="item"></confirm-picture>
            </div>
          </rc-section>
        </rc-section>

        <rc-section title="承诺书" v-if="eleFlag === 1">
          <confirm-picture :type="6" :infoData="infoData"></confirm-picture>
        </rc-section>
        <jc-form :model="form" ref="form" :rules="rules" id="agreement-anchor">
          <jc-form-item prop="agreement">
            <jc-checkbox v-model="form.agreement">
              我已阅读并同意
              <a :href="agreement.informationSecurity" target="_blank">《信息安全管理协议》</a>和
              <a :href="agreement.assistChange" target="_blank">《协助更改备案信息服务在线服务条款》</a>
            </jc-checkbox>
          </jc-form-item>
        </jc-form>
      </div>
      <div class="jc-panel jc-panel-action">
        <jc-button @click="back">上一步</jc-button>
        <jc-button type="primary" @click.prevent="nextStep">提交信息</jc-button>
      </div>
    </div>
    <jc-dialog title="重要提示" :visible.sync="dialogVisible" size="tiny">
      <span>
        <span
          >{{
            recordType === RECORD_TYPE_MAP.BODY_ADD_ACCESS || recordType === RECORD_TYPE_MAP.NO_BODY_ADD_ACCESS
              ? '您将会收到工信部发出的短信息验证码（工信部短信将发送至原接入商备案信息中网站负责人手机号码），请您收到短信验证码24 小时内完成短信验证，若未完成验证则备案信息将被退回。具体手机号短信息验证规则表查看帮助中心文档'
              : '主体负责人及网站负责人手机号码将会收到工信部发出的短信息验证码，请您收到短信验证码24 小时内完成短信验证，若未完成验证则备案信息将被退回。具体手机号短信息验证规则表查看帮助中心文档'
          }}<a :href="agreement.sms" target="_blank" class="blueLink">帮助中心文档</a>。</span
        >
      </span>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="dialogVisible = false" id="confirmInfoCancel">取 消</jc-button>
        <jc-button type="primary" @click="continueSubmit" id="confirmInfoContinueSubmit">继续提交</jc-button>
      </span>
    </jc-dialog>
  </div>
</template>
<script>
import { getAttachmentInfo, updateStatus, saveRecordNumber, getSiteInfo, getPrincipalInfo } from '@/service/record-api'
import { AGREEMENT, RECORD_TYPE_MAP } from '@/views/service/constant.js'
import { CONFIRM_REULE } from '@/views/service/rules'
export default {
  props: [],
  components: {
    confirmPrincipalCommunity: () =>
      import('@/views/submit/src/record.status/components/confirm.principal.community.vue'),
    confirmPrincipalPersonal: () =>
      import('@/views/submit/src/record.status/components/comfirm.principal.personal.vue'),
    confirmPicture: () => import('@/views/submit/src/record.status/components/confirm.picture.vue'),
    confirmWebsiteCommunity: () => import('@/views/submit/src/record.status/components/confirm.website.community.vue'),
    confirmWebsitePersonal: () => import('@/views/submit/src/record.status/components/confirm.website.personal.vue')
  },
  mixins: [],
  data() {
    return {
      recordNo: this.$route.params.recordNo,
      form: {
        agreement: false
      },
      agreement: AGREEMENT,
      dialogVisible: false,
      rules: CONFIRM_REULE,
      infoData: {
        siteInfo: [],
        domainInfo: [],
        principalInfo: {},
        attachmentInfo: {}
      },
      RECORD_TYPE_MAP
    }
  },
  watch: {},
  computed: {
    principalUser() {
      if (this.role === 5) {
        return 'confirmPrincipalPersonal'
      } else {
        return 'confirmPrincipalCommunity'
      }
    },
    websiteUser() {
      // 1. 区分企业和个人
      if (this.role === 5) {
        return 'confirmWebsitePersonal'
      } else {
        return 'confirmWebsiteCommunity'
      }
    }
  },
  mounted() {},
  created() {
    this.getInit()
  },
  filters: {},
  methods: {
    nextStep() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.dialogVisible = true
        } else {
          window.location.href = '#agreement-anchor'
        }
      })
    },
    async continueSubmit() {
      const { data } = await saveRecordNumber({
        recordNo: this.recordNo
      })
      if (data) {
        this.dialogVisible = false
        this.outMsg('success', '提交成功')
        this.$router.push({
          name: 'audit',
          params: { recordNo: this.recordNo }
        })
      } else {
        this.outMsg('error', '提交失败')
      }
    },
    async back() {
      const { data } = await updateStatus({
        recordNo: this.recordNo,
        status: this.eleFlag ? 'eleCheck' : 'step3'
      })
      if (data) {
        this.$router.push({
          name: this.eleFlag ? 'eleCheck' : 'step3',
          params: { recordNo: this.recordNo }
        })
      } else {
        this.outMsg('error', '修改备案状态失败')
      }
    },
    async getInit() {
      const promise = [this.getSiteInfo(), this.getPrincipalInfo(), this.getAttachmentInfo()]
      await Promise.all(promise)
    },
    async getAttachmentInfo() {
      let { data } = await getAttachmentInfo({ recordNo: this.recordNo })
      this.infoData.attachmentInfo = data
    },
    async getSiteInfo() {
      let { data } = await getSiteInfo({ recordNo: this.recordNo })
      this.infoData.siteInfo = data.recordSites
      this.infoData.domainInfo = data.recordDomains
    },
    async getPrincipalInfo() {
      let { data } = await getPrincipalInfo({
        recordNo: this.recordNo
      })
      this.infoData.principalInfo = data
    }
  }
}
</script>
<style scoped>
.jc-datashow-list {
  width: 100% !important;
}
.jc-datashow-list .jc-form-item__content img {
  width: 100%;
}
</style>
