import { RECORD_TYPE } from '../mutation.types'
const state = {
  role: '', //单位性质
  recordType: 1,
  // 备案类型 1新增备案 3有主体新增网站 4主体变更 5网站变革 6变更接入 7有主体新增接入 8无主体新增接入 9无主体新增网站 10主体注销 11网站注销 12取消接入
  eleFlag: 0 // 电子化核验标识 0 否，1 是
}
const mutations = {
  [RECORD_TYPE](state, value) {
    state.role = value
  },
  ADD_RECORD_TYPE(state, value) {
    state.recordType = value
  },
  ADD_ELEFLAG(state, value) {
    state.eleFlag = value
  }
}

export default {
  state,
  mutations
}
