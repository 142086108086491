import filters from './filters'

/**
 *
 *
 * @param {Object} descriptor
 * @param {Object.type} 掩码类型
 * @param {Object.value} 掩码原值
 *
 */
function desense(descriptor) {
  return filters[descriptor.type](descriptor.value)
}

export default desense
