<template>
  <!--<p style="font-size:20px;text-align: center;color:rgb(192, 204, 218);">404</p>-->
  <div class="wrap">
    <div class="page404">
      <div class="e404"></div>
      <div class="error-tips">
        <p>页面访问错误，请稍后访问...</p>
        <a :href="overviewLink" class="blueLink f14">前往概览页 &gt;</a>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from '@/mixins/menu.mixins.js'
export default {
  mixins: [Menu],
  data() {
    return {
      overviewLink: '//console.jdcloud.com'
    }
  }
}
</script>
