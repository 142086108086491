<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <rc-step :step="1"></rc-step>
      <div class="form-loading">
        <jc-alert type="warning" title="请至少勾选一个需要变更的服务" :closable="false"></jc-alert>
        <jc-table :data="siteList" ref="siteTable" @selection-change="handleSelectionChange">
          <jc-table-column type="selection" width="55"> </jc-table-column>
          <jc-table-column label="服务备案号">
            <template slot-scope="scope">
              {{ scope.row.miitRecordNo || '--' }}
            </template>
          </jc-table-column>
          <jc-table-column label="服务名称">
            <template slot-scope="scope">
              {{ scope.row.siteName || '--' }}
            </template>
          </jc-table-column>
          <jc-table-column label="域名">
            <template slot-scope="scope">
              <span slot="reference" class="info-detail item-tips info_tip">{{
                scope.row.domains.map(item => item).join(',') || '--'
              }}</span>
            </template>
          </jc-table-column>
          <jc-table-column label="服务负责人">
            <template slot-scope="scope">
              {{ scope.row.ownerName || '--' }}
            </template>
          </jc-table-column>
        </jc-table>
        <div class="jc-panel jc-panel-action">
          <jc-button @click="back">上一步</jc-button>
          <jc-button type="primary" @click="changeSite()" :disabled="!multipleSelection.length">下一步</jc-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPrincipalInfo,
  getValidRegion,
  getSiteInfoByPin,
  getServiceList,
  startCreateSelectedWebsite,
  updateStatus
} from '@/service/record-api'
import { RECORD_TYPE_MAP } from '@/views/service/constant.js'
export default {
  props: [],
  components: {},
  mixins: [],
  data() {
    return {
      recordNo: this.$route.params.recordNo,
      siteList: [],
      multipleSelection: [],
      region: [],
      recordPrincipal: {}
    }
  },
  async mounted() {
    await Promise.all([this.getList(), this.getValidRegion(), this.getPrincipalInfo()])
    // 主体变更跳过，变更备案修改服务
    if (this.recordType === RECORD_TYPE_MAP.CHANGE_PRINCIPAL) {
      this.confirmModify()
    }
  },
  created() {},
  watch: {},
  computed: {
    eleFlagStatus() {
      const principal = this.recordPrincipal
      // 企业，工商营业执照，主体负责人身份证
      if (!this.region.includes(principal.provinceCode)) {
        return 0
      }
      // 主体变更负责人统一默认为主体负责人。判断是否为电子核验通过此判断
      if (principal.identityType === 2) {
        return 1
      } else {
        return 0
      }
    }
  },
  filters: {},
  methods: {
    // 获取可以进行进行电子核验地域
    async getValidRegion() {
      const { data } = await getValidRegion()
      this.region = data ? data.value.split(',') : []
    },
    async getList() {
      // const { data } = await getSiteInfoByPin()
      // this.siteList = data.filter(site => site.status === 1)
      const { data } = await getServiceList()
      if (this.recordType === RECORD_TYPE_MAP.UPGRADE_RECORD) {
        this.siteList = data.filter(x => x.waitUpgrade)
        return
      } else if (this.recordType === RECORD_TYPE_MAP.CHANGE_WEBSITE) {
        this.siteList = data.filter(x => !x.waitUpgrade)
        return
      }
      this.siteList = data
    },
    // 获取主体信息
    async getPrincipalInfo() {
      let recordNo = this.recordNo
      try {
        let { data } = await getPrincipalInfo({
          recordNo
        })
        this.recordPrincipal = data
      } catch (err) {
        console.error(err)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async confirmModify() {
      // this.$jcConfirm('请确认是否修改服务备案信息？', '提示', {
      //   confirmButtonText: '是',
      //   cancelButtonText: '否'
      // })
      //   .then(() => {})
      //   .catch(async () => {
      //     this.siteList.forEach(row => {
      //       this.$refs.siteTable.toggleRowSelection(row)
      //     })
      //     await this.changeSite()
      //     const { data } = await updateStatus({
      //       recordNo: this.recordNo,
      //       status: 'step3'
      //     })
      //     if (data) {
      //       this.$router.push({
      //         name: 'step3',
      //         params: { recordNo: this.recordNo }
      //       })
      //     }
      //   })

      // remove 24.1.5
      // this.siteList.forEach(row => {
      //   this.$refs.siteTable.toggleRowSelection(row)
      // })
      // await this.changeSite()
      const { data } = await updateStatus({
        recordNo: this.recordNo,
        status: 'step3'
      })
      if (data) {
        this.$router.push({
          name: 'step3',
          params: { recordNo: this.recordNo }
        })
      }
    },
    async changeSite() {
      const multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection))
      // const params = {
      //   recordType: this.recordType,
      //   recordNo: this.recordNo,
      //   principalId: this.recordPrincipal.id,
      //   eleFlag: this.eleFlagStatus,
      //   sites: multipleSelection.map(site => {
      //     const siteParams = Object.assign(site, {
      //       recordNo: this.recordNo,
      //       jcloudServiceType: site.jcloudServiceType || 1, // 关联服务
      //       siteName: site.siteName || '', // 网站名称
      //       serviceValue: site.serviceValue || '', // 公网ip、负载均衡
      //       domains: JSON.stringify(
      //         site.domains.map(val => {
      //           return { domain: val.domain }
      //         })
      //       ), // 网站域名
      //       homeUrl: site.domains[0] ? site.domains[0].homeUrl : '',
      //       remark: site.remark || '', // 备注
      //       preApprovalOption: site.preApprovalOption || 0, // 前置审批项
      //       siteType: site.siteType || 0, // 主网站服务内容
      //       isContinue: 'n', // 保存成功后不修改备案状态
      //       preApprovalNo: site.preApprovalNo || '', // 前置审批号
      //       eleFlag: this.eleFlagStatus || 0, // 电子核验标识
      //       recordType: this.recordType
      //     })
      //     return {
      //       ...siteParams
      //     }
      //   })
      // }

      await startCreateSelectedWebsite({
        recordNo: this.recordNo,
        serviceMiitRecordNos: multipleSelection.map(site => {
          return site.miitRecordNo
        })
      })
      this.$router.push({ name: 'step2', params: { recordNo: this.recordNo } })
    },
    back() {
      this.$router.push({ name: 'step1', params: { recordNo: this.recordNo } })
    }
  }
}
</script>
<style lang="css" scoped></style>
