<template>
  <div class="date-item__input">
    <jc-date-picker
      v-model="time.startTime"
      type="date"
      placeholder="请选择起始日期"
      :editable="false"
      :disabled="disabled"
      :picker-options="startPickerOptions"
    >
    </jc-date-picker>
    <span class="data-item__input-separator">至</span>
    <jc-date-picker
      v-model="time.endTime"
      type="date"
      align="right"
      placeholder="请选择失效日期"
      :editable="false"
      :disabled="disabled || time.isIndefinite"
      :picker-options="endPickerOptions"
    >
    </jc-date-picker>
    <jc-checkbox-group v-model="time.isIndefinite" ref="checkboxRef" :disabled="disabled" @change="handleSelected">
      <jc-checkbox class="date-item__checkbox" :label="loneTermText"></jc-checkbox>
    </jc-checkbox-group>
  </div>
</template>
<script>
import moment from 'moment'
import { computed, defineComponent, nextTick, reactive, ref, unref, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'ValidityPeriod',
  props: {
    // 时间值
    value: {
      type: String,
      default: ''
    },
    // 时间分隔符
    separator: {
      type: String,
      default: '-'
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 长期展示字符
    loneTermText: {
      type: String,
      default: '长期'
    }
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const { value, separator, loneTermText } = toRefs(props)
    const checkboxRef = ref(null)

    // 页面渲染变量
    const state = reactive({
      time: {
        startTime: '',
        endTime: '',
        // 长期有效
        isIndefinite: false
      }
    })

    // 内部应用变量
    const privateState = reactive({
      // 时间格式
      tiemFormate: 'YYYY.MM.DD',
      showTiemFormate: 'YYYY-MM-DD',
      startTime: '1900-01-01',
      todayTime: moment().valueOf()
    })

    // 获取时间毫秒值
    const getTimeValue = (timeStr = '') => moment(unref(timeStr)).valueOf()

    // 开始时间起始范围
    const originalTimeValue = computed(() => getTimeValue(privateState.startTime))
    // 开发时间截止范围
    const endTimeValue = computed(() => privateState.todayTime)

    // 起始日期配置
    const startPickerOptions = computed(() => ({
      disabledDate: time => time.getTime() < unref(originalTimeValue) || time.getTime() >= unref(endTimeValue)
    }))

    // 失效日期配置
    const endPickerOptions = computed(() => ({
      disabledDate: time => time.getTime() <= privateState.todayTime
    }))

    // 变更长期失效时间
    const handleSelected = async () => {
      await nextTick()
      // 触发表单校验
      unref(checkboxRef).dispatch('JcFormItem', 'el.form.change', [state.time.isIndefinite])
    }

    // 时间格式化
    const getSubmitTime = dateTime => (unref(dateTime) ? moment(unref(dateTime)).format(privateState.tiemFormate) : '')
    const submitStartTime = computed(() => getSubmitTime(state.time.startTime))
    const submitEndTime = computed(() =>
      unref(state.time.isIndefinite) ? unref(loneTermText) : getSubmitTime(state.time.endTime)
    )
    // 提交日期变更
    const submitChange = () => {
      const submitValue = `${unref(submitStartTime)}${unref(separator)}${unref(submitEndTime)}`
      emit('input', submitValue)
      emit('change', submitValue)
    }

    // 强校验日期格式是否正确
    const validtorTime = dateTime =>
      unref(dateTime) ? moment(unref(dateTime), privateState.tiemFormate, true).isValid() : false

    const getRenderTime = dateTime => {
      return unref(dateTime)
        ? moment(unref(dateTime), privateState.tiemFormate, true).format(privateState.showTiemFormate)
        : ''
    }
    const renderShowTime = showTimeValue => {
      let [startStr = '', endStr = ''] = showTimeValue.split(unref(separator)) || []
      startStr = validtorTime(startStr) ? getRenderTime(startStr) : ''
      let renderTime = {}
      const { startTime, isIndefinite, endTime } = state.time || {}
      // 设置开始时间
      if (startStr !== startTime) {
        renderTime.startTime = startStr
      }

      // 设置长期选中状态
      const isSelect = endStr === unref(loneTermText)
      if (isSelect && !isIndefinite) {
        renderTime.isIndefinite = true
      } else if (!isSelect && isIndefinite) {
        renderTime.isIndefinite = false
      }

      // 非长期，设置结束时间
      if (!isSelect) {
        endStr = validtorTime(endStr) ? getRenderTime(endStr) : ''

        if (endStr !== endTime) {
          renderTime.endTime = endStr
        }
      }
      state.time = {
        startTime,
        isIndefinite,
        endTime,
        ...renderTime
      }
    }

    watch(
      () => state,
      val => {
        submitChange(val)
      },
      {
        deep: true
      }
    )

    watch(
      value,
      (val, oldVal) => {
        if (val === oldVal) {
          return false
        }
        renderShowTime(val)
      },
      {
        immediate: true
      }
    )

    return {
      ...toRefs(state),
      checkboxRef,
      startPickerOptions,
      endPickerOptions,
      handleSelected
    }
  }
})
</script>
<style scoped>
.date-item__input {
  display: flex;
  align-items: center;
}
.data-item__input-separator {
  overflow: hidden;
  margin: 0 8px;
  border-radius: 1px;
  width: 8px;
  height: 1px;
  font-size: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.date-item__checkbox {
  margin-left: 8px;
}
</style>
