<template>
  <div class="jdcloud-assistant">
    <el-tooltip class="item" effect="light" placement="left">
      <div slot="content" style="font-size: 14px;color: rgba(0,0,0,0.65);">
        京东云言犀小助手已上线！<br />
        快来体验一下吧。
      </div>
      <div class="assistant-icon" @click="handleClick" />
    </el-tooltip>
    <!-- <img
      src="https://img1.jcloudcs.com/lavm/assistant/icon-active.png"
      style="display: none"
    /> -->
    <link rel="preload" as="image" href="https://img1.jcloudcs.com/lavm/assistant/icon-active.png" />

    <no-ssr>
      <iframe
        v-if="showFrame"
        :class="'assistant-iframe ' + frameStyle"
        :src="iframeSrc"
        width="100%"
        height="100%"
        sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms"
      />
      <!-- <vue-draggable-resizable
        class-name="assistant-drag"
        :w="500"
        :h="750"
        :parent="false"
        :active="false"
        :enable-native-drag="false"
        drag-handle=".assistant-header"
      >
        <div class="assistant-header"></div>
        <iframe
          class="assistant-iframe"
          v-if="iframeSrc"
          :src="iframeSrc"
          width="100%"
          height="100%"
          sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </vue-draggable-resizable> -->
    </no-ssr>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: 'https://portal-agent.jdcloud.com/publish?app_id=app-tw9n6qjrzm5e',
      showFrame: false,
      frameStyle: ''
    }
  },

  mounted() {
    console.log('jdcloud-assistant mounted')
    if (this.$route.query.yxagent === 'open') {
      this.showFrame = true
    }
    this.registerEvent()
  },
  methods: {
    registerEvent() {
      window.addEventListener('message', event => {
        // console.log("event", event);
        if (event.data === 'AGENT_CLOSE') {
          this.showFrame = false
          this.frameStyle = ''
        } else if (event.data === 'AGENT_LOGIN') {
          let url = new URL(window.location.href)
          url.searchParams.set('yxagent', 'open')
          window.location.href = `https://login.jdcloud.com/?returnUrl=${url.href}`
        } else if (event.data === 'AGENT_REGISTER') {
          let url = new URL(window.location.href)
          url.searchParams.set('yxagent', 'open')
          window.location.href = `https://user.jdcloud.com/register?returnUrl=${url.href}`
        } else if (event.data === 'AGENT_FULL') {
          this.frameStyle = 'chat-full'
          // @ts-ignore
          document.documentElement.style.scrollbarWidth = 'none'
        } else if (event.data === 'AGENT_CEIL') {
          this.frameStyle = 'chat-ceil'
          // @ts-ignore
          document.documentElement.style.scrollbarWidth = 'none'
        } else if (event.data === 'AGENT_SMALL') {
          this.frameStyle = ''
          // @ts-ignore
          document.documentElement.style.scrollbarWidth = 'auto'
        } else {
          console.log('unsupported message')
        }
      })
    },
    handleClick() {
      this.showFrame = !this.showFrame
    }
  }
}
</script>

<style lang="scss">
.el-tooltip__popper.is-light {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 2px rgba(0, 0, 0, 0.02), 0 3px 8px 4px rgba(0, 0, 0, 0.02);
  border: none !important;
}
.el-tooltip__popper {
  padding: 8px;
  line-height: 23px;
}
.el-tooltip__popper.is-light[x-placement^='left'] .popper__arrow {
  border-left-color: #fff;
}
.el-tooltip__popper.is-light[x-placement^='left'] .popper__arrow {
  border-left-color: #fff;
}
.el-tooltip__popper.is-light[x-placement^='right'] .popper__arrow {
  border-right-color: #fff;
}
.el-tooltip__popper.is-light[x-placement^='right'] .popper__arrow {
  border-right-color: #fff;
}
.jdcloud-assistant {
  .assistant-icon {
    position: fixed;
    right: -10px;
    bottom: 280px;
    cursor: pointer;
    z-index: 999;

    width: 78px;
    height: 150px;
    background: url(https://img1.jcloudcs.com/lavm/assistant/icon-default.png);
    background-size: 78px 150px;
  }
  .assistant-icon:hover {
    background: url(https://img1.jcloudcs.com/lavm/assistant/icon-active.png);
    background-size: 78px 150px;
  }
  .assistant-iframe {
    box-shadow: 0 2px 18px 0 rgba(226, 225, 225, 1);
    background: #fff;
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    right: 68px;
    width: 380px;
    height: min(700px, calc(100vh - 68px));
    border: none;

    &.chat-ceil {
      top: 0;
      bottom: 0;
      right: 0;
      width: 500px;
      height: 100%;
    }

    &.chat-full {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
