export function recordSchedule(result) {
  let status = result.status
  switch (status) {
    case -3:
      return 'verify'
    case -2:
      return 'step1'
    case -1:
      return 'step2'
    case 1:
      return 'step3'
    case 100:
      return 'confirm'
    // 电子核验
    case 101:
      return 'eleCheck'
    // 电子核验等待审核
    case 102:
      return 'audit'
    // 电子核验审核驳回
    case 103:
      return 'audit'
    // 电子核验审核通过
    case 104:
      return 'audit'
    // 等待初审
    case 2:
      return 'audit'
    // 初审通过
    case 3:
      return 'screen'
    // 初审失败
    case 4:
      return 'audit'
    // 等待审核照片
    case 5:
      return 'audit'
    // 照片审核驳回
    case 7:
      return 'audit'
    // 等待提交管局
    case 11:
      return 'audit'
    // 提交管局待审核
    case 12:
      return 'audit'
    // 管局审核通过
    case 13:
      return 'manage'
    // 管局拒绝
    case 14:
      return 'audit'
    // 变更接入等待审核
    case 19:
      return 'audit'
    // 变更接入审核通过
    case 20:
      return 'audit'
    // 变更接入审核驳回
    case 21:
      return 'audit'
    case 121:
      // 短信核验
      return 'audit'
    case 122:
      // 管局审核
      return 'audit'
    default:
      throw new Error('no match to the router')
  }
}
