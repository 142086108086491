<template>
  <div>
    <rc-card title="备案管理">
      <a v-if="site.length" href="javascript:;" slot="link" @click="showTransfer">转移备案</a>
    </rc-card>
    <div class="jc-page-content">
      <div class="jc-panel jc-panel-padding" v-if="recordList.length">
        <rc-section title="当前备案状态">
          <!-- <div class="jc-container-table"> -->
          <div>
            <jc-table :data="recordList" class="mb20">
              <jc-table-column label="备案订单号">
                <template slot-scope="scope">
                  {{ scope.row.recordNo || '--' }}
                </template>
              </jc-table-column>
              <jc-table-column label="服务备案号">
                <template slot-scope="scope">
                  <p v-for="item in scope.row.serviceRecordList" :key="item.miitRecordNo">
                    {{ item.miitRecordNo || '--' }}
                  </p>
                  <p v-if="!scope.row.serviceRecordList || scope.row.serviceRecordList.length == 0">--</p>
                </template>
              </jc-table-column>
              <jc-table-column label="服务名称">
                <template slot-scope="scope">
                  <p v-for="item in scope.row.serviceRecordList" :key="item.siteName">
                    {{ item.siteName || '--' }}
                  </p>
                  <p v-if="!scope.row.serviceRecordList || scope.row.serviceRecordList.length == 0">--</p>
                </template>
              </jc-table-column>
              <jc-table-column label="服务类型">
                <template slot-scope="scope">
                  <p v-for="item in scope.row.serviceRecordList" :key="item.siteName">
                    {{ item.appServiceType === 'app' ? 'APP' : '网站' }}
                  </p>
                  <p v-if="!scope.row.serviceRecordList || scope.row.serviceRecordList.length == 0">--</p>
                </template>
              </jc-table-column>
              <!-- <jc-table-column label="域名" show-overflow-tooltip>
                <template slot-scope="scope">
                  <p slot="reference" v-for="item in scope.row.serviceRecordList" :key="item.id">
                    {{ (item.domains && item.domains.map(val => val.domain).join(',')) | limiteStr(20) }}
                  </p>
                </template>
              </jc-table-column> -->
              <jc-table-column label="备案类型">
                <template slot-scope="scope">
                  {{ scope.row.recordType | recordTypeFilter }}
                </template>
              </jc-table-column>
              <jc-table-column label="更新时间" sortable>
                <template slot-scope="scope">
                  {{ scope.row.updateTime | timeFilter }}
                </template>
              </jc-table-column>
              <jc-table-column label="状态">
                <template slot-scope="scope">
                  {{ scope.row.status | recordStatusFilter }}
                </template>
              </jc-table-column>

              <jc-table-column label="操作">
                <template slot-scope="scope">
                  <jc-button type="text" @click="continueRecord(scope.row)">继续本次流程</jc-button>
                  <jc-button
                    type="text"
                    :disabled="[12, 121, 122, 13].includes(scope.row.status)"
                    @click="quitMessage(scope.row)"
                    >放弃本次流程</jc-button
                  >
                </template>
              </jc-table-column>
            </jc-table>
            <div class="jc-container-table-actionBar" style="min-height: 40px">
              <jc-button
                type="primary"
                style="margin-left: 45%"
                v-if="principalInfo.whiteFlag"
                @click="dialogVisible.newSite = true"
                :disabled="!principalInfo.whiteFlag && principalInfo.siteCount >= 5"
                >新增服务</jc-button
              >
              <jc-pagination
                class="align-right"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount"
                :page-count="totalCount"
              >
              </jc-pagination>
            </div>
          </div>
          <!-- <div class="text_center"> -->
          <!-- <jc-button @click="quitMessage(scope.row)" :disabled="[11, 12, 13, 104].includes(recordList[0].status)"
              >放弃本次流程</jc-button
            >
            <jc-button type="primary" @click="continueRecord(scope.row)">继续本次流程</jc-button> -->
          <!-- </div> -->
        </rc-section>
      </div>
      <div class="jc-panel jc-panel-padding">
        <rc-section title="主体信息">
          <span slot="link">
            <router-link :to="{ name: 'principalDetail' }">查看详情</router-link>
            <!-- <router-link
              :to="{ name: 'step1', params: { recordNo: 'changePrincipal' } }"
              v-if="!recordList.length"
              style="margin-left: 15px"
              >主体变更</router-link
            >
            <router-link
              :to="{
                name: 'step1',
                params: { recordNo: 'changePrincipal' },
                query: { recordType: 14 }
              }"
              v-if="!recordList.length"
              style="margin-left: 15px"
              >变更备案</router-link
            > -->
            <a href="#" @click="cancelDailog('principal')" style="margin-left: 15px" v-if="!recordList.length"
              >主体注销</a
            >
            <!-- <jc-button type="text" @click="cancelPrincipal">主体注销</jc-button> -->
          </span>
          <div class="info-show cl">
            <dl>
              <dt>ICP主体备案号</dt>
              <dd>{{ principalInfo.miitRecordNo || '--' }}</dd>
            </dl>
            <dl>
              <dt>ICP主体状态</dt>
              <dd>正常</dd>
            </dl>
            <dl>
              <dt v-if="principalInfo.organizersNature == 5">主办人姓名</dt>
              <dt v-else>主办单位全称</dt>
              <dd v-if="principalInfo.organizersNature == 5">
                {{ principalInfo.organizersName | desenseFilter('name') }}
              </dd>
              <dd v-else>{{ principalInfo.organizersName || '--' }}</dd>
            </dl>
            <dl v-if="principalInfo.organizersNature != 5">
              <dt>主办单位负责人</dt>
              <dd>{{ principalInfo.ownerName | desenseFilter('name') }}</dd>
            </dl>
          </div>
        </rc-section>
      </div>
      <div class="jc-panel jc-panel-padding">
        <rc-section title="备案信息">
          <!-- <jc-button type="primary"><i  class="jc-icon-plus"></i>新增服务</jc-button> -->
          <jc-button
            type="primary"
            icon="plus"
            style="margin-bottom: 20px"
            @click="dialogVisible.newSite = true"
            v-if="!recordList.length"
            :disabled="!principalInfo.whiteFlag && principalInfo.siteCount >= 5"
            >新增服务</jc-button
          >

          <!-- <jc-button
            type="primary"
            style="margin-bottom: 20px"
            @click="changeAllSite"
            v-if="!recordList.length"
            >网站变更</jc-button
          > -->
          <jc-tooltip v-if="site && site.findIndex(x => x.waitUpgrade) > -1" effect="dark" placement="top-end">
            <template slot="content">
              <div>由于备案系统主体升级为京东科技，您需要先切换到新的主体京东科技上；</div>
              <div>
                原主体的备案信息在新主体升级后会进行注销，升级期间不影响您的业务正
              </div>
              <div>常使用备案信息，升级后可以使用京东科技主体的备案信息进行变更等操作</div>
            </template>
            <jc-button type="primary" disabled> 变更类操作<i class="jc-icon-arrow-down jc-icon--right"></i> </jc-button>
          </jc-tooltip>
          <jc-dropdown @command="handleCommand" v-else-if="!recordList.length">
            <jc-button type="primary"> 变更类操作<i class="jc-icon-arrow-down jc-icon--right"></i> </jc-button>
            <jc-dropdown-menu slot="dropdown">
              <jc-dropdown-item command="toChangePrincipal" v-if="!recordList.length"
                >主体变更（只变更您的主体信息）</jc-dropdown-item
              >
              <jc-dropdown-item command="changeAllSite" v-if="!recordList.length"
                >服务变更（只变更您的服务信息）</jc-dropdown-item
              >
              <jc-dropdown-item command="toChangeRecord" v-if="!recordList.length"
                >变更备案（变更您的主体和服务信息）</jc-dropdown-item
              >
            </jc-dropdown-menu>
          </jc-dropdown>
          <!-- <div class="jc-container-table"> -->
          <div>
            <jc-table :data="site" class="table-loading">
              <jc-table-column label="服务备案号">
                <template slot-scope="scope">
                  {{ scope.row.miitRecordNo || '--' }}
                  <div v-if="scope.row.waitUpgrade">
                    <jc-button type="primary" @click="handleUpgrade(scope.row)"> 待升级 </jc-button>

                    <jc-tooltip effect="dark" placement="top-end">
                      <template slot="content">
                        <div style="max-width: 450px">
                          <div>
                            1.
                            由于京东云备案系统主体升级为京东科技，为避免原主体的下线影响您的域名和APP正常使用，您需要将已备案完成的服务切换到新的主体京东科技上，辛苦您点击待升级完成切换操作
                          </div>
                          <div>
                            2.
                            升级期间不影响您的业务正常使用备案信息，升级后可以使用京东科技主体的备案信息进行变更等操作
                          </div>
                          <div>3. 点击任意待升级按钮，在下一个页面可以把需要升级为相同负责人的服务，一次性进行升级</div>
                          <div>4. 如果有疑问和问题，请拨打客服热线：400-0988505转2再转9（7*24小时）</div>
                        </div>
                      </template>
                      <i class="el-icon-question" style="font-size: 16px; margin-left: 4px"></i>
                    </jc-tooltip>
                  </div>
                </template>
              </jc-table-column>
              <jc-table-column label="服务名称">
                <template slot-scope="scope">
                  {{ scope.row.siteName || '--' }}
                </template>
              </jc-table-column>
              <jc-table-column label="服务类型">
                <template slot-scope="scope">
                  {{ scope.row.appServiceType === 'app' ? 'APP' : '网站' }}
                </template>
              </jc-table-column>
              <jc-table-column label="域名">
                <template slot-scope="scope">
                  <span slot="reference" class="info-detail item-tips info_tip">{{
                    scope.row.domains.join(',') || '--'
                  }}</span>
                </template>
              </jc-table-column>
              <jc-table-column label="服务负责人">
                <template slot-scope="scope">
                  {{ scope.row.ownerName | desenseFilter('name') }}
                </template>
              </jc-table-column>
              <jc-table-column label="状态">
                <template slot-scope="scope"> 正常 </template>
              </jc-table-column>
              <jc-table-column label="操作">
                <template slot-scope="scope">
                  <jc-button type="text" @click="toSite(scope.row)">详情</jc-button>

                  <jc-tooltip v-if="scope.row.waitUpgrade" effect="dark" placement="top-end">
                    <template slot="content">
                      <div>由于备案系统主体升级为京东科技，您需要先切换到新的主体京东科技上；</div>
                      <div>
                        原主体的备案信息在新主体升级后会进行注销，升级期间不影响您的业务正
                      </div>
                      <div>常使用备案信息，升级后可以使用京东科技主体的备案信息进行变更等操作</div>
                    </template>
                    <jc-button type="text" disabled>
                      服务信息变更
                    </jc-button>
                  </jc-tooltip>
                  <jc-button v-else-if="!recordList.length" type="text" @click="webSiteChange(scope.row)"
                    >服务信息变更</jc-button
                  >
                  <!-- <jc-button
                    v-if="!recordList.length"
                    type="text"
                    @click="
                      $router.push({
                        name: 'step2',
                        params: { recordNo: 'changeSiteIp' },
                        query: { siteId: scope.row.id }
                      })
                    "
                    >变更接入</jc-button
                  > -->
                  <jc-button v-if="!recordList.length" type="text" @click="cancelDailog('site', scope.row)"
                    >服务注销</jc-button
                  >
                  <jc-button type="text" @click="cancelDailog('insert', scope.row)" v-if="!recordList.length"
                    >取消接入</jc-button
                  >
                  <!-- <jc-button
                    type="text"
                    @click="changeImage(scope.row)"
                    v-if="!recordList.length"
                    >变更图片</jc-button
                  > -->
                </template>
              </jc-table-column>
            </jc-table>
          </div>
        </rc-section>
      </div>
    </div>
    <jc-dialog title="信息" :visible.sync="dialogVisible.principal" size="tiny">
      <span>
        <p>您确认需要注销此备案号的主体信息吗？</p>
        <p>
          注销主体后，该备案主体下的全部域名将不能访问京东云大陆节点服务器。如您需要重新备案，原有备案服务号无法重复使用。点击提交后您的注销申请将由通信管理局处理，我们无法撤回您的注销申请。
        </p>
        <p>
          2018年1月1日起，工信部要求新备案的域名必须符合服务备案域名核验规则（工信部未批复后缀将无法重新备案，如.org/.me/.hk/.name等），如不符合会被管局驳回，请点击查看服务备案域名核验规则。
        </p>
        <p>
          提示：注销主体是指注销主体信息的备案号，主体备案号注销之后，您主体下的所有服务的备案号都会被一起注销，根据相关法律法规要求没有备案号的服务不能开通访问。
        </p>
        <p>
          您作为服务所有者或服务管理者，本次备案请求是您的真实意思表达，
          您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。
        </p>
        <p>主体备案号 :{{ principalInfo.miitRecordNo }}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="dialogVisible.principal = false">放弃注销</jc-button>
        <jc-button type="primary" @click="cancelPrincipal">确认注销</jc-button>
      </span>
    </jc-dialog>
    <jc-dialog title="信息" :visible.sync="dialogVisible.site" size="tiny">
      <span>
        <p>您确认需要注销此备案号的服务信息吗？</p>
        <p>
          注销服务后，此服务下的全部域名将不能访问京东云大陆节点服务器。如您需要重新备案，原有备案服务号无法重复使用。点击提交后您的注销申请将由通信管理局处理，我们无法撤回您的注销申请。
        </p>
        <p>
          2018年1月1日起，工信部要求新备案的域名必须符合服务备案域名核验规则（工信部未批复后缀将无法重新备案，如.org/.me/.hk/.name等），如不符合会被管局驳回，请点击查看服务备案域名核验规则。
        </p>
        <p>
          提示：注销服务是指注销您的服务备案号，注销服务备案号不会影响到您的主体备案号与其他未申请注销的服务备案号，
          只注销您当前选中的服务备案号，根据相关法律法规要求没有备案号的服务不能开通访问。
        </p>
        <p>
          您作为服务所有者或服务管理者，本次备案请求是您的真实意思的表达，您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。
        </p>
        <p>服务备案号 :{{ selectedSiteInfo.miitRecordNo }}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="dialogVisible.site = false">放弃注销</jc-button>
        <jc-button type="primary" @click="cancelSite">确认注销</jc-button>
      </span>
    </jc-dialog>

    <jc-dialog title="信息" :visible.sync="dialogVisible.insert" size="tiny">
      <span>
        <p>您确认需要取消此备案号的接入信息吗？</p>
        <p>
          服务被取消接入后，该服务下备案的所有域名将无法访问京东云大陆节点服务器，如果您的备案信息没有对应的接入商，工信部可能会注销您的备案号。如您需要重新备案，原有备案服务号无法重复使用。点击提交后您的取消接入申请将由通信管理局处理，我们无法撤回您的取消接入申请。
        </p>
        <p>
          2018年1月1日起，工信部要求新备案的域名必须符合服务备案域名核验规则（工信部未批复后缀将无法重新备案，如.org/.me/.hk/.name等），如不符合会被管局驳回，请点击查看服务备案域名核验规则。
        </p>
        <p>
          提示：取消接入是指将您的备案信息从京东云的备案系统中取消，取消接入不会注销您的主体备案号，取消接入成功后如果您继续使用京东云接入商的服务器，您的服务将会被阻断访问，如果您已经不再使用京东云接入商的服务品，就不会出现此问题，另外请您尽快将备案信息接入至您正在使用服务器的接入商备案系统。
          如果您的备案信息没有对应的接入商，根据相关法律法规，相关部门可能会注销您的备案号。
        </p>
        <p>
          您作为服务所有者或服务管理者，本次备案请求是您的真实意思的表达，
          您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。
        </p>
        <p>服务备案号 :{{ selectedSiteInfo.miitRecordNo }}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="dialogVisible.insert = false">放弃</jc-button>
        <jc-button type="primary" @click="cancelInsert">确认</jc-button>
      </span>
    </jc-dialog>

    <jc-dialog title="新增服务" :visible.sync="dialogVisible.newSite" size="tiny" @close="handleCloseNewSite">
      <jc-form
        :model="newServiceForm"
        ref="newServiceForm"
        label-width="100px"
        class="newServiceForm"
        :rules="websiteRules"
      >
        <jc-form-item prop="appServiceType" label="服务类型：">
          <jc-radio-group
            v-model="newServiceForm.appServiceType"
            @change="handleClearFormRule('newServiceForm', ['domain', 'siteName'])"
          >
            <jc-radio
              v-for="serviceItem in appServiceTypeOptions"
              :key="serviceItem.value"
              :label="serviceItem.value"
              >{{ serviceItem.label }}</jc-radio
            >
          </jc-radio-group>
          <p slot="hint">请选择您需要备案的服务类型</p>
        </jc-form-item>
        <jc-form-item
          prop="domain"
          label="网站域名："
          v-if="newServiceForm.appServiceType === serviceInfoValueMap.SERVICE_INFO_WEB"
        >
          <jc-input
            v-model.trim="newServiceForm.domain"
            type="text"
            placeholder="请输入域名，格式如：hijdcloud.com"
            @change="toLower"
          >
            <template slot="prepend">www.</template>
          </jc-input>
        </jc-form-item>
        <jc-form-item
          prop="siteName"
          label="APP名称："
          v-if="newServiceForm.appServiceType === serviceInfoValueMap.SERVICE_INFO_APP"
        >
          <jc-input v-model.trim="newServiceForm.siteName" type="text" placeholder="请输入APP名称" :maxlength="128">
          </jc-input>
          <p slot="hint">APP名称请填写该APP下载安装后，显示在图标下的名称</p>
        </jc-form-item>
      </jc-form>
      <span slot="footer" class="dialog-footer">
        <jc-button @click="dialogVisible.newSite = false" :disable="newSiteLoading">取消</jc-button>
        <jc-button type="primary" @click="confirmNewSite" :loading="newSiteLoading">确认</jc-button>
      </span>
    </jc-dialog>
    <!-- 转移备案弹窗 -->
    <jc-dialog
      title="备案转移"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible.transfer"
      @close="transferClose"
      size="tiny"
    >
      <jc-form :model="toTransfer" v-if="!isTransferSuccess" ref="toTransfer" label-width="160px" class="toTransfer">
        <div class="transfer-title">验证身份信息</div>
        <jc-form-item prop="appServiceType" label="验证方式：">
          <div>手机号码</div>
        </jc-form-item>
        <jc-form-item prop="appServiceType" label="您的手机号码：">
          <div v-if="toTransfer.mobileShow">{{ toTransfer.mobileShow }}</div>
          <div v-else>
            请先<a href="https://uc.jdcloud.com/account/security-settings" target="_blank">绑定手机号</a>再进行备案转移
          </div>
        </jc-form-item>
        <jc-form-item prop="siteName" label="手机验证码：">
          <jc-input
            v-model.trim="toTransfer.smsCode"
            @focus="smsCodeError = false"
            type="text"
            placeholder="请输入手机验证码"
            :maxlength="128"
            style="width: 162px"
          >
          </jc-input>
          <jc-button :disabled="!isSend" @click="sendMessage" style="margin-left: 12px; width: 92px">{{
            isSend ? '发送验证码' : sendTime + '秒后重试'
          }}</jc-button>
          <p slot="hint">
            <span v-if="smsCodeError" class="sms-code-error">验证码错误</span>
          </p>
        </jc-form-item>
        <jc-form-item prop="siteName" label="要转入的京东云账号：">
          <jc-input
            v-model.trim="toTransfer.targetLoginName"
            type="text"
            placeholder="请输入要转入的京东云账号"
            @focus="userNameErr = ''"
            :maxlength="128"
          >
          </jc-input>
          <p slot="hint">
            <span
              >请输入对方云账号名。<a
                href="javascript:;"
                @click="$jdochelp('https://docs.jdcloud.com/cn/account-management/check-jdcloud-account')"
                >如何查看京东云账号名</a
              ></span
            ><br />
            <span v-if="userNameErr" class="sms-code-error">{{ userNameErr }}</span>
            <span></span>
          </p>
        </jc-form-item>
        <div style="display: flex;">
          <jc-checkbox style="margin-right: 5px;" v-model="agreePermissions" />
          <div>
            转移到新账户后，新账户将具有备案信息的管理权限，管理权限包括但不限于备案信息修改、注销备案等。请谨慎确认是否转移
          </div>
        </div>
      </jc-form>
      <div v-else class="transfer-success">
        转移成功
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="!isTransferSuccess">
          <jc-button @click="dialogVisible.transfer = false">取消</jc-button>
          <jc-button :disabled="canTransfer" @click="submitTransfer" type="primary">确认</jc-button>
        </div>
        <div v-else>
          <jc-button @click="successClose">关闭</jc-button>
        </div>
      </span>
    </jc-dialog>
  </div>
</template>
<script>
import {
  checkDomainExist,
  checkServiceExist,
  getRecordList,
  curRecordList,
  getServiceList,
  getPrincipalInfoByPin,
  deleteRecord,
  newProcess,
  startNewCancelProcess,
  updateStatus,
  sendVerificationCode,
  getUserInfo,
  checkUserName,
  transferRecord
} from '@/service/record-api'
import { placement } from '@/utils/tableXplacement.js'
import { recordSchedule } from '@/utils/status.utils.js'
import { RECORD_TYPE_MAP, SERVICE_INFO_VALUE_MAP, SERVICE_INFO_TYPE_OPTIONS } from '@/views/service/constant.js'
import { VERIFY_RULE } from '@/views/service/rules'

const DEFAULT_NEW_SERVICE = {
  appServiceType: '',
  domain: '',
  // 服务名称
  siteName: ''
}

const TO_TRANSFER = {
  mobile: '',
  mobileShow: '',
  pin: '',
  smsCode: '',
  targetLoginName: ''
}

export default {
  data() {
    return {
      principalInfo: {},
      site: [],
      services: [],
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      recordList: [],
      // eslint-disable-next-line
      placement: new placement(),
      baseRecordType: '',
      dialogVisible: {
        principal: false,
        site: false,
        insert: false,
        newSite: false,
        transfer: false // 转移备案
      },
      selectedSiteInfo: {},
      RECORD_TYPE_MAP,
      newServiceForm: {
        ...DEFAULT_NEW_SERVICE
      },
      toTransfer: {
        ...TO_TRANSFER
      },
      isSend: true,
      sendTime: 60,
      sendInter: null,
      smsCodeError: false,
      userNameErr: '',
      newSiteLoading: false,
      agreePermissions: false,
      isTransferSuccess: false,
      websiteRules: VERIFY_RULE.call(this),
      /* 新增服务类型相关 */
      // 服务类型选择列表
      serviceInfoValueMap: { ...SERVICE_INFO_VALUE_MAP },
      appServiceTypeOptions: [...SERVICE_INFO_TYPE_OPTIONS]
    }
  },
  created() {
    this.getList()
    this.getPrincipalInfoByPin()
  },
  computed: {
    // 用户没有填完整信息的情况下，按钮是不可以点击
    canTransfer() {
      if (
        this.toTransfer.mobile &&
        this.toTransfer.pin &&
        this.toTransfer.smsCode &&
        this.toTransfer.targetLoginName &&
        this.agreePermissions
      ) {
        return false
      }
      return true
    }
  },
  mounted() {
    this.placement.setParams({
      isDealPage: true, // 是否处理 分页信息
      withPaddingClassName: 'innerCon', // table外面带有padding-left的那个div的class
      pageClassName: 'page-wrap', // 分页所在div的class
      divClassName: 'white-box' // table所在div的class
    })
    this.placement.mounted()
    this.getUserInfo() // 获取用户手机号等信息
  },
  methods: {
    successClose() {
      window.location.reload()
    },
    transferClose() {
      // @closed 失效，使用setTimeout代替
      setTimeout(() => {
        this.toTransfer.smsCode = ''
        this.toTransfer.targetLoginName = ''
        this.isTransferSuccess = false
        this.agreePermissions = false
      }, 300)
    },
    async getUserInfo() {
      const { data } = await getUserInfo()
      this.toTransfer.mobile = data.mobile
      this.toTransfer.mobileShow = data.mobileShow
      this.toTransfer.pin = data.pin
    },
    async sendMessage() {
      if (this.isSend) {
        this.isSend = false
        await sendVerificationCode({
          mobile: this.toTransfer.mobile
        })
        this.sendInter = setInterval(() => {
          this.sendTime--
          if (this.sendTime <= 1) {
            clearInterval(this.sendInter)
            this.sendInter = null
            this.isSend = true
            this.sendTime = 60
          }
        }, 1000)
      }
    },
    async submitTransfer() {
      await checkUserName({
        userName: this.toTransfer.targetLoginName
      }).then(
        async res => {
          if (res.code === 0) {
            const { mobile, smsCode, targetLoginName } = this.toTransfer
            await transferRecord({
              mobile,
              smsCode,
              targetLoginName
            })
            this.$message({
              type: 'success',
              message: '成功'
            })
            this.isTransferSuccess = true
          }
        },
        err => {
          if (err.message == '用户不存在') {
            this.userNameErr = '您输入的云账号不存在'
          } else {
            this.userNameErr = err.message
          }
        }
      )
    },
    showTransfer() {
      this.dialogVisible.transfer = true
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNumber = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getList()
    },
    // 查询列表
    async getList() {
      // let { data } = await getRecordList({
      //   pageSize: this.pageSize,
      //   pageNumber: this.pageNumber
      // })
      // this.site = data.reduce((acc, cur) => {
      //   if (cur.status === 13) {
      //     acc = acc.concat(cur.websites.filter(val => val.status === 1))
      //   }
      //   return acc
      // }, [])
      // this.recordList = data.filter(val =>
      //   ![1, 8, 9].includes(val.recordType) ? val.status !== 13 && val.status !== 15 : false
      // )
      // this.totalCount = data.totalCount || this.recordList.length
      // data.forEach(val => {
      //   if ([1, 8, 9].includes(val.recordType)) {
      //     this.baseRecordType = val.recordType
      //   }
      // })

      this.curRecordList()
      this.getServiceList()
    },
    async curRecordList() {
      let { data, page } = await curRecordList({
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.pageNumber
      })
      this.recordList = data
      this.totalCount = page.total
    },
    async getServiceList() {
      const { data } = await getServiceList()
      this.site = data
    },
    // 获取主体信息
    async getPrincipalInfoByPin() {
      try {
        let { data } = await getPrincipalInfoByPin()
        this.principalInfo = data
      } catch (err) {
        console.error(err)
      }
    },
    // 跳转网址详情
    toSite(row) {
      this.$router.push({
        name: 'siteDetail',
        query: { id: row.id, recordNo: row.recordNo, miitRecordNo: row.miitRecordNo }
      })
    },
    handleCloseNewSite() {
      this.newServiceForm = {
        ...DEFAULT_NEW_SERVICE
      }
    },
    confirmNewSite() {
      this.$refs['newServiceForm'].validate(async valid => {
        if (valid) {
          this.newSiteLoading = true
          try {
            const { appServiceType, domain, siteName } = this.newServiceForm || {}

            const {
              data: { statusSecurity: domainSecurity, mainPhylicnum: domainPhylicnum }
            } =
              appServiceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP
                ? await checkServiceExist({
                    appServiceType: SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP,
                    identityNo: this.principalInfo.identityNo || '',
                    siteName: siteName
                  })
                : await checkDomainExist({
                    domain: domain
                  })

            if (domainSecurity) {
              let result = await getPrincipalInfoByPin()
              let cardPhylicnum = result.data.miitRecordNo
              if (cardPhylicnum && domainPhylicnum.indexOf(cardPhylicnum) === -1) {
                this.$message({
                  type: 'error',
                  message: '此域名已备案且与当前帐号下的备案主体不符，请修改'
                })
                this.newSiteLoading = false
                return
              }

              this.newSiteLoading = false

              // 新增接入服务
              this.newSite(7)
            } else {
              this.newSiteLoading = false

              // 新增服务
              this.newSite(3)
            }
          } catch (error) {
            this.newSiteLoading = false
            console.log(error)
          }
        } else {
          return false
        }
      })
    },
    // 有主体新增服务
    newSite(val) {
      this.$router.push({
        name: 'newSite',
        query: {
          recordType: val,
          appServiceType: this.newServiceForm.appServiceType,
          siteName: this.newServiceForm.siteName,
          domain: this.newServiceForm.domain
        }
      })
    },
    // 继续本次流程
    async continueRecord(row) {
      try {
        const currRecord = row
        let redirectRouter = recordSchedule(currRecord)
        this.$router.push({
          name: redirectRouter,
          params: { recordNo: currRecord.recordNo }
        })
      } catch (error) {
        this.outMsg('error', '获取备案状态失败')
        this.$router.push({ name: 'index' })
      }
    },
    // 放弃本次流程提示
    async quitMessage(row) {
      try {
        await this.$confirm('如您放弃当前备案，当前备案中所填信息将被删除！', '放弃本次流程', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: ''
        })
      } catch (err) {
        return false
      }
      this.quitRecord(row)
    },
    //放弃本次流程
    async quitRecord(row) {
      try {
        // const currRecord = this.recordList[0]
        let params = {
          recordNo: row.recordNo
        }
        await deleteRecord(params)
        this.outMsg('success', '放弃本次流程成功')
        // 备案三期修改
        this.getList()
      } catch (err) {
        console.error(err)
      }
    },
    handleSiteParams(site) {
      let params = {
        ...site,
        oldSite: site.id,
        domains: JSON.stringify(
          site.domains.map(val => {
            return { domain: val.domain }
          })
        ),
        isContinue: 'y'
      }
      delete params.id
      delete params.status
      delete params.yn
      delete params.pin
      delete params.modified
      delete params.principalId
      delete params.recordNo
      return params
    },
    async webSiteChange(site) {
      const params = this.handleSiteParams(site)
      try {
        const { data } = await newProcess({
          recordType: RECORD_TYPE_MAP.CHANGE_WEBSITE,
          site: params
        })
        this.$router.push({ name: 'step2', params: { recordNo: data.recordNo } })
      } catch (error) {
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.CHANGE_WEBSITE,
            msg: '新建备案流程错误：' + error.message + ' 参数：' + JSON.stringify(params)
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    cancelDailog(val, opt = {}) {
      this.selectedSiteInfo = opt
      this.dialogVisible[val] = true
    },
    // 主体注销
    async cancelPrincipal() {
      const { data } = await startNewCancelProcess({
        recordType: RECORD_TYPE_MAP.SUBJECT_LOGOUT,
        principalMiitRecordNo: this.principalInfo.miitRecordNo
      })
      if (data.recordNos && data.recordNos.length > 0) {
        this.updateStatus(data.recordNos[0])
      } else {
        this.$message({ type: 'error', message: '操作失败，请稍后重试' })
        try {
          window.__sgm__.custom({
            type: 2,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.SUBJECT_LOGOUT,
            msg: '新建备案流程错误： 主体备案号：' + this.principalInfo.miitRecordNo
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    // 服务注销
    async cancelSite() {
      const { data } = await startNewCancelProcess({
        recordType: RECORD_TYPE_MAP.WEBSITE_LOGOUT,
        principalMiitRecordNo: this.principalInfo.miitRecordNo,
        serviceMiitRecordNos: [this.selectedSiteInfo.miitRecordNo]
      })
      if (data.recordNos && data.recordNos.length > 0) {
        this.updateStatus(data.recordNos[0])
      } else {
        this.$message({ type: 'error', message: '操作失败，请稍后重试' })
        try {
          window.__sgm__.custom({
            type: 2,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.WEBSITE_LOGOUT,
            msg: '新建备案流程错误： 主体备案号：' + this.principalInfo.miitRecordNo
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    // 取消接入
    async cancelInsert() {
      const { data } = await startNewCancelProcess({
        recordType: RECORD_TYPE_MAP.CANCEL_ACCESS,
        principalMiitRecordNo: this.principalInfo.miitRecordNo,
        serviceMiitRecordNos: [this.selectedSiteInfo.miitRecordNo]
      })
      if (data.recordNos && data.recordNos.length > 0) {
        this.updateStatus(data.recordNos[0])
      } else {
        this.$message({ type: 'error', message: '操作失败，请稍后重试' })
        try {
          window.__sgm__.custom({
            type: 2,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.CANCEL_ACCESS,
            msg: '新建备案流程错误： 主体备案号：' + this.principalInfo.miitRecordNo
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async updateStatus(recordNo) {
      const { data } = await updateStatus({
        recordNo,
        status: 'firstAudit'
      })
      if (data) {
        this.$router.push({
          name: 'audit',
          params: { recordNo }
        })
      } else {
        this.$message({ type: 'error', message: '操作失败，请稍后重试' })
      }
    },
    async changeAllSite() {
      let site = (this.site && this.site[0]) || {}
      const params = this.handleSiteParams(site)
      try {
        const { data } = await newProcess({
          recordType: RECORD_TYPE_MAP.CHANGE_WEBSITE,
          site: params,
          // isBatchModifySite是否批量修改服务: 1修改 0不修改
          isBatchModifySite: 1
        })
        this.$router.push({
          name: 'siteList',
          params: { recordNo: data.recordNo }
        })
      } catch (error) {
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.CHANGE_WEBSITE,
            msg: '新建备案流程错误：' + error.message + ' 参数：' + JSON.stringify(params)
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async handleUpgrade() {
      let site = (this.site && this.site[0]) || {}
      const params = this.handleSiteParams(site)

      try {
        const { data } = await newProcess({
          recordType: RECORD_TYPE_MAP.UPGRADE_RECORD,
          site: params,
          // isBatchModifySite是否批量修改服务: 1修改 0不修改
          isBatchModifySite: 1
        })
        this.$router.push({
          name: 'siteList',
          params: { recordNo: data.record.recordNo }
        })
      } catch (error) {
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.UPGRADE_RECORD,
            msg: '新建备案流程错误：' + error.message + ' 参数：' + JSON.stringify(params)
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async changeImage(site) {
      const params = this.handleSiteParams(site)
      try {
        const { data } = await newProcess({
          recordType: RECORD_TYPE_MAP.CHANGE_IMAGE,
          site: params
        })
        const { data: statusData } = await updateStatus({
          recordNo: data.recordNo,
          status: 'step3'
        })
        if (statusData) {
          this.$router.push({
            name: 'step3',
            params: { recordNo: data.recordNo }
          })
        } else {
          this.$message({ type: 'error', message: '操作失败，请稍后重试' })
        }
      } catch (err) {
        console.error(err)
        try {
          window.__sgm__.custom({
            type: 1,
            code: 'ERROR_NEW_PROCESS' + RECORD_TYPE_MAP.CHANGE_IMAGE,
            msg: '新建备案流程错误：' + err.message + ' 参数：' + JSON.stringify(params)
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleCommand(command) {
      console.log('handleCommand', command)
      if (command === 'changeAllSite') {
        this.changeAllSite()
      }
      if (command === 'toChangePrincipal') {
        this.toChangePrincipal()
      }
      if (command === 'toChangeRecord') {
        this.toChangeRecord()
      }
    },
    toChangePrincipal() {
      this.$router.push({
        name: 'step1',
        params: { recordNo: 'changePrincipal' }
      })
    },
    toChangeRecord() {
      this.$router.push({
        name: 'step1',
        params: { recordNo: 'changePrincipal' },
        query: { recordType: RECORD_TYPE_MAP.CHANGE_RECORD }
      })
    },
    handleClearFormRule(formName, formItems) {
      this.$refs[formName].clearValidate(formItems)
    }
  }
}
</script>
<style scoped>
.transfer-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 12px;
  font-weight: bold;
  text-align: center;
}
.toTransfer .jc-form-item {
  margin-bottom: 12px;
}
.transfer-success {
  font-size: 24px;
  padding: 24px;
  text-align: center;
}
a:hover {
  color: #5686ff;
}
.sms-code-error {
  color: #e2231a;
}
</style>
