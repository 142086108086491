<template>
  <div>
    <rc-children-card title="备案管理" @back="back"></rc-children-card>
    <div class="jc-page-content">
      <div class="jc-panel jc-panel-padding">
        <jc-alert
          :closable="false"
          type="warning"
          title="请认真核验您的主体信息，如果有变动请先进行变更备案；如果信息无误，请点击“信息准确，下一步”继续增加服务"
        ></jc-alert>
        <principal-detail :desense="false"></principal-detail>
      </div>
      <div class="jc-panel jc-panel-action">
        <!-- <jc-button type="primary" @click.prevent="nextStep()">下一步</jc-button> -->
        <jc-button type="primary" @click="newSite">信息准确，下一步</jc-button>
        <jc-button
          @click="
            $router.push({
              name: 'step1',
              params: { recordNo: 'changePrincipal' }
            })
          "
          >变更主体信息</jc-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import PrincipalDetail from '@/views/entry/src/components/PrincipalInfo'
import { newProcess } from '@/service/record-api'
export default {
  props: {},
  components: {
    PrincipalDetail
  },
  mixins: [],
  data() {
    return {
      siteRecordType: this.$route.query.recordType
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  filters: {},
  methods: {
    async newSite() {
      const { data } = await newProcess({
        recordType: this.siteRecordType
      })
      this.$router.push({
        name: 'step2',
        params: {
          recordNo: data.record.recordNo
        },
        query: {
          domain: this.$route.query.domain,
          siteName: this.$route.query.siteName,
          appServiceType: this.$route.query.appServiceType
        }
      })
    },
    back() {
      this.$router.push({ name: 'manage' })
    }
  }
}
</script>
