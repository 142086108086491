<template>
  <div class="recordForm">
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <!-- recordType 6: 变更接入-->
      <rc-step :step="recordType === 6 ? 0 : 1"></rc-step>
      <div class="form-loading">
        <div v-for="(item, index) in recordSites" :key="`${item.id}-${index}`">
          <div class="jc-panel jc-panel-selected-webInfo" v-show="curIndex !== index">
            <span class="jc-webInfo-index">{{ index + 1 }}</span>
            <div class="jc-webInfo">
              <h3>服务名称：{{ item.siteName }}</h3>
              <h4>负责人：{{ item.ownerName }}</h4>
              <h4>服务类型：{{ item.appServiceType === 'app' ? 'APP' : '网站' }}</h4>
              <h4 v-if="item.appServiceType === 'web'">
                域名：{{
                  infoData.recordDomains
                    .filter(domain => domain.siteId === item.id)
                    .map(domain => domain.domain)
                    .join(',')
                }}
              </h4>
            </div>
            <div class="jc-webInfo-action">
              <jc-button size="mini" @click.prevent="changeDomain('modify', item.id, index)">修改</jc-button>
              <jc-button
                size="mini"
                type="danger"
                @click.prevent="changeDomain('delete', item.id)"
                v-show="index == 0 ? false : true"
                v-if="[1, 3, 7, 8, 9].includes(recordType)"
                >删除</jc-button
              >
            </div>
          </div>
          <div class="jc-panel jc-panel-padding" v-if="index === curIndex">
            <website-information
              ref="website"
              :infoData="infoData"
              :siteInfo="item"
              :siteIndex="index"
              :domainInfo="selectedDomainInfo(item)"
              @submitFail="loading = false"
              @saveSuccess="saveSuccess"
              @abandonNew="abandonNew"
              @abandonEdit="abandonEdit"
              @next="redirectRouter"
            ></website-information>
          </div>
        </div>
        <p class="mb20" v-if="curIndex === -1">
          <!-- 仅能添加3条服务 recordType 4:主体变更,5:服务变更,6:变更接入,8:无主体新增接入不能添加服务 -->
          <a
            href="#"
            class="blueLink"
            @click.prevent="changeDomain('add')"
            v-if="recordSites.length < getOnceSitesCount() && ![4, 5, 6, 8, 14, 24].includes(recordType)"
          >
            <i class="i45"></i>继续添加服务
          </a>
        </p>
        <div class="jc-panel jc-panel-action">
          <jc-button v-if="['changeSite', 'changeSiteIp'].includes(recordNo)" @click="cancel">取消</jc-button>
          <!--  recordType : 1:新增备案 4:主体变更 8:无主体新增接入 9:无主体新增服务-->
          <jc-button @click="back" v-if="[1, 4, 8, 9].includes(recordType)">上一步</jc-button>
          <jc-button type="primary" @click.prevent="nextStep" v-loading="loading">下一步</jc-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getSiteInfo,
  deleteWebSite,
  getPrincipalInfo,
  updateStatus,
  getValidRegion,
  getPrincipalInfoByPin
} from '@/service/record-api'
export default {
  provide() {
    return {
      modifyIdentitype: val => {
        this.ownerIdentityType = val
      }
    }
  },
  props: {},
  components: {
    ownerCommunity: () => import('@/views/submit/src/record.information/components/owner.community.vue'),
    websiteInformation: () => import('@/views/submit/src/record.information/components/service.form.vue')
  },
  mixins: [],
  data() {
    return {
      recordNo: this.$route.params.recordNo || '',
      siteId: this.$route.query.siteId || '',
      step: this.$route.params.step || '',
      infoData: {
        recordSites: [],
        recordPrincipal: {},
        recordDomains: []
      },
      recordSites: [],
      curIndex: -1,
      showAddWebsite: true,
      ownerIdentityType: '',
      region: [],
      firstChange: false,
      loading: false
    }
  },
  watch: {},
  computed: {
    eleFlagStatus() {
      const principal = this.infoData.recordPrincipal
      const sites = this.infoData.recordSites
      // 企业，工商营业执照，主体负责人身份证
      if (!this.region.includes(principal.provinceCode)) {
        return 0
      }
      if (this.role !== 5) {
        if (principal.organizersNature !== 4 || principal.identityType !== 1 || principal.ownerIdentityType !== 2) {
          return 0
        }
        if (this.curIndex === -1) {
          if (sites[0].identityType === 2) {
            return 1
          } else {
            return 0
          }
        } else {
          if (this.ownerIdentityType === 2) {
            return 1
          } else {
            return 0
          }
        }
      } else {
        if (principal.identityType === 2) {
          return 1
        } else {
          return 0
        }
      }
    }
  },
  mounted() {},
  updated() {
    // console.log('this.recordSites', this.recordSites)
    // console.log('this.curIndex', this.curIndex)
  },
  async created() {
    await Promise.all([this.getValidRegion(), this.getPrincipalInfo()])
    if (this.recordNo === 'changeSite') {
      // 服务变更首次进入
      this.$store.commit('ADD_RECORD_TYPE', 5)
      this.$store.commit('ADD_ROLE', this.infoData.recordPrincipal.organizersNature)
      this.getWebsiteInfo({ siteId: this.siteId })
    } else if (this.recordNo === 'changeSiteIp') {
      // 变更接入首次进入
      this.$store.commit('ADD_RECORD_TYPE', 6)
      this.$store.commit('ADD_ROLE', this.infoData.recordPrincipal.organizersNature)
      this.getWebsiteInfo({ siteId: this.siteId })
    } else {
      // 新增备案，有主体新增服务，主体变更
      this.getWebsiteInfo()
    }
  },
  filters: {},
  methods: {
    getOnceSitesCount() {
      if (this.infoData.recordPrincipal.whiteFlag) {
        return 3
      }
      return 2
    },
    // 获取可以进行进行电子核验地域
    async getValidRegion() {
      const { data } = await getValidRegion()
      this.region = data ? data.value.split(',') : []
    },
    // 查询服务信息
    async getWebsiteInfo(params) {
      try {
        let searchParams
        if (params) {
          // 服务变更、变更接入
          searchParams = params
          this.firstChange = true
        } else {
          // 新增备案 有主体新增服务 主体变更
          searchParams = { recordNo: this.recordNo }
        }
        let { data } = await getSiteInfo(searchParams)
        this.infoData.recordSites = data.recordSites
        this.infoData.recordDomains = data.recordDomains
        this.recordSites = data.recordSites
        if (this.recordSites.length === 1) {
          this.curIndex = 0
        } else if (this.recordSites.length === 0) {
          this.curIndex = 0
          this.recordSites = [{}]
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 获取主体信息
    async getPrincipalInfo() {
      let recordNo = this.recordNo
      if (['changeSite', 'changeSiteIp'].includes(recordNo)) {
        recordNo = ''
      }
      try {
        let { data } = recordNo ? await getPrincipalInfo({ recordNo }) : await getPrincipalInfoByPin()
        this.infoData.recordPrincipal = data
      } catch (err) {
        console.error(err)
      }
    },
    // 筛选出选择的服务的域名信息
    selectedDomainInfo(site) {
      return this.infoData.recordDomains.filter(domain => domain.siteId === site.id)
    },
    // 已修改状态进行其他操作进行提示
    async changeDomain(operate, id, index) {
      if (this.curIndex !== -1) {
        try {
          await this.$confirm('您确认放弃此次编辑？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (err) {
          return false
        }
      }
      if (operate === 'modify') {
        this.modifyDomin(id, index)
      } else if (operate === 'add') {
        this.addSite()
      } else {
        this.delDomin(id)
      }
    },
    // 查看已填写成功的服务信息
    async modifyDomin(id, index) {
      try {
        let { data } = await getSiteInfo({ recordNo: this.recordNo })
        this.infoData.recordSites = data.recordSites
        this.infoData.recordDomains = data.recordDomains
        this.recordSites = data.recordSites
        this.curIndex = index
      } catch (err) {
        console.error(err)
      }
    },
    // 删除服务信息
    async delDomin(siteId) {
      try {
        await deleteWebSite({ recordNo: this.recordNo, siteId: siteId })
        this.outMsg('success', '删除成功')
        this.getWebsiteInfo()
      } catch (err) {
        console.error(err)
      }
    },
    async nextStep() {
      this.loading = true
      // 展示服务缩略详情下一步进入下一页
      if (this.curIndex === -1) {
        this.redirectRouter('step3')
      } else {
        this.$refs.website[0].submitWebsite()
      }
    },
    // 下一步跳转
    async redirectRouter(step, reordNo) {
      if (reordNo) {
        this.recordNo = reordNo
      }
      if (this.recordType === 6) {
        const { data } = await updateStatus({
          recordNo: this.recordNo,
          status: 'firstAudit'
        })
        if (data) {
          this.$router.push({
            name: 'audit',
            params: { recordNo: this.recordNo }
          })
        }
      } else if (this.$route.query.step === 'confirm') {
        const { data } = await updateStatus({
          recordNo: this.recordNo,
          status: 'confirm'
        })
        if (data) {
          this.$router.push({
            name: 'confirm',
            params: { recordNo: this.recordNo }
          })
        }
      } else {
        const { data } = await updateStatus({
          recordNo: this.recordNo,
          status: step
        })
        if (data) {
          this.$router.push({
            name: step,
            params: { recordNo: this.recordNo }
          })
        }
      }
      this.loading = false
    },
    // 上一步
    back() {
      this.$router.push({ name: 'step1', params: { recordNo: this.recordNo } })
    },
    // 服务变更，变更接入取消流程
    cancel() {
      this.$router.push({ name: 'manage' })
    },
    // 保存成功(不进行下一步)回调
    async saveSuccess() {
      await this.getWebsiteInfo()
      this.curIndex = -1
    },
    // 增加服务
    addSite() {
      this.recordSites.push({})
      this.curIndex = this.recordSites.length - 1
    },
    // 放弃编辑（未保存过的服务）
    abandonNew() {
      this.recordSites.pop()
      this.curIndex = -1
    },
    // 放弃修改(服务已保存)
    abandonEdit() {
      this.curIndex = -1
    }
  }
}
</script>
<style>
.jc-panel.jc-panel-selected-webInfo {
  padding: 10px 30px;
  position: relative;
  transition: all 0.2s;
  overflow: hidden;
}
.jc-panel-selected-webInfo h3 {
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 500;
  margin-bottom: 12px;
}
.jc-panel-selected-webInfo h4 {
  font-size: 12px;
  color: #757575;
  margin-top: 8px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jc-panel-selected-webInfo .jc-webInfo-index {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  width: 18px;
  font-family: Helvetica;
}
.jc-panel-selected-webInfo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30px solid #2aafff;
  border-right: 30px solid transparent;
  line-height: 30px;
}
.jc-panel-selected-webInfo .jc-webInfo {
  float: left;
}
.jc-panel-selected-webInfo .jc-webInfo-action {
  vertical-align: middle;
  float: right;
  font-size: 0;
  opacity: 1;
  transition: all 0.2s;
  padding-top: 24px;
}
.jc-panel-selected-webInfo:hover {
  border-color: #2aafff;
  transition: all 0.2s;
}
</style>
