<template>
  <div>
    <div class="jc-container-table">
      <div class="jc-container-table-actionBar">
        <jc-form class="jc-table-actionBar-item" :inline="true">
          <jc-form-item label="地域">
            <jc-select v-model="form.regionId" placeholder="请选择" style="width: 120px;" @change="regionChange">
              <jc-option v-for="item in REGION" :key="item.value" :label="item.label" :value="item.value"> </jc-option>
            </jc-select>
          </jc-form-item>
        </jc-form>
      </div>
      <jc-table :data="tableData">
        <jc-table-column label="实例名称">
          <template slot-scope="scope">
            {{ scope.row.instanceName | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="实例ID">
          <template slot-scope="scope">
            {{ scope.row.instanceId | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="公网IP">
          <template slot-scope="scope">
            {{ scope.row.ipAddress | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="资源有效期" width="290px">
          <template slot-scope="scope">
            {{ scope.row.chargeStartTime | timeFilter }}
            -
            {{ scope.row.chargeExpiredTime | timeFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="可申请个数">
          <template slot-scope="scope">
            {{ scope.row.count | NumberFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="操作">
          <template slot-scope="scope">
            <jc-button type="text" disabled v-if="isNotNumber(scope.row.count)">{{ scope.row.count }}</jc-button>
            <div v-else>
              <apply-button
                v-if="scope.row.count !== 0"
                :instance="scope.row"
                :instance-id="scope.row.instanceId"
                :auth-type="AUTH_CODE_TYPE.LAVM"
                :regionId="form.regionId"
                title="资源ID"
                @success="getList"
              />
              <jc-button type="text" @click="jump(scope.row)">查看</jc-button>
            </div>
          </template>
        </jc-table-column>
      </jc-table>
      <div class="jc-container-table-actionBar">
        <jc-pagination
          class="align-right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNumber"
          :page-sizes="[20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :page-count="totalCount"
        >
        </jc-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { REGION, AUTH_CODE_TYPE } from '@/views/service/constant'
import { getVMList, betchGetAuthCodeCount } from '@/service/record-api'
import ApplyButton from './ApplyButton.vue'
import moment from 'moment'
export default {
  name: 'LAVMList',
  props: {},
  components: { ApplyButton },
  mixins: [],
  data() {
    return {
      form: {
        instanceName: '轻量云主机',
        regionId: 'cn-north-1',
        pageNumber: 1,
        pageSize: 20
      },
      tableData: [],
      totalCount: 0,
      REGION: [
        REGION[0], // 北京
        REGION[3], // 广州
        REGION[1] // 宿迁
        // REGION[2] // 上海
      ],
      AUTH_CODE_TYPE
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getList()
  },
  created() {},
  filters: {},
  methods: {
    isNotNumber(num) {
      return typeof num !== 'number'
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getList()
    },
    async getList() {
      const { data = {} } = await getVMList({
        ...this.form,
        authCodeType: AUTH_CODE_TYPE.LAVM
      })
      const authCodeList = data.authCodeInstanceVos || []
      if (authCodeList.length) {
        this.tableData = authCodeList.map(val => {
          let count = 5
          if (!val.ipAddress) {
            count = '未绑定公网IP'
          }
          if (
            !(
              val.chargeExpiredTime &&
              moment(val.chargeExpiredTime).valueOf() >
                moment()
                  .add(3, 'months')
                  .valueOf()
            )
          ) {
            count = '购买时长不足3个月'
          }
          return {
            ...val,
            count: typeof count === 'number' ? count - val.authCodeCount : count
          }
        })
      } else {
        this.tableData = []
      }
      this.totalCount = data.totalCount
    },
    async getAuthCodeCount(params) {
      return betchGetAuthCodeCount(params)
    },
    jump(row) {
      this.$router.push({
        name: 'authCodeManage',
        query: { instanceId: row.instanceId }
      })
    },
    regionChange() {
      this.form.pageNumber = 1
      this.getList()
    }
  }
}
</script>
<style lang="css" scoped></style>
