<template>
  <div>
    <rc-card :type="recordType"></rc-card>
    <div class="jc-page-content">
      <rc-step :step="0"></rc-step>
      <div class="form-loading">
        <div class="jc-panel jc-panel-padding">
          <p class="tip-earlyWarning mb20">{{ warningTitle }}</p>
          <div class="jc-section">
            <div class="jc-section-header">
              <h3 class="jc-section-title">主办单位信息</h3>
            </div>
            <div class="jc-section-content">
              <jc-form :model="form" ref="form" label-width="140px" :rules="rules" class="jc-form-tiny-width">
                <jc-form-item :label="recordType === 10 ? '主体备案号：' : '网站备案号：'">
                  <span>{{ form.miitRecordNo | emptyFilter }}</span>
                </jc-form-item>
                <jc-form-item label="主体备案密码：">
                  <jc-row>
                    <jc-col :span="21">
                      <jc-form-item prop="miitRecordPwd">
                        <jc-input
                          v-model.trim="form.miitRecordPwd"
                          type="password"
                          placeholder="请输入备案密码"
                        ></jc-input>
                      </jc-form-item>
                    </jc-col>
                    <jc-col :span="2" :offset="1">
                      <jc-popover ref="popover2" placement="top-start" title="提示" width="300" trigger="hover">
                        <div>
                          <p>
                            ICP备案密码提交后将直接由通管局审核验证，如果您忘记密码，请通过各地通管局网站重新获取。具体操作请参见<a
                              :href="AGREEMENT.password"
                              class="agrrement-link"
                              target="_blank"
                              >备案密码找回办法</a
                            >
                          </p>
                        </div>
                      </jc-popover>
                      <a href="#" v-popover:popover2 class="jc-icon-my-help" style="font-size: 14px"></a>
                    </jc-col>
                  </jc-row>
                </jc-form-item>
              </jc-form>
            </div>
          </div>
        </div>
        <div class="jc-panel jc-panel-action">
          <jc-button type="primary" @click="nextStep()">下一步</jc-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPrincipalInfoByPin,
  getPrincipalInfo,
  getSiteInfo,
  newProcess,
  updateStatus,
  modifyCancelFlow
} from '@/service/record-api'
import { AGREEMENT } from '@/views/service/constant'
export default {
  props: [],
  components: {},
  mixins: [],
  data() {
    return {
      recordNo: this.$route.params.recordNo,
      form: {
        siteId: '',
        principalId: '',
        miitRecordNo: '',
        miitRecordPwd: '',
        domains: '',
        homeUrl: ''
      },
      AGREEMENT,
      rules: {
        miitRecordPwd: [
          {
            required: true,
            message: '请输入主体备案密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {},
  created() {
    if (/principal/g.test(this.recordNo)) {
      this.$store.commit('ADD_RECORD_TYPE', 10)
      this.form.principalId = this.recordNo.replace('principal', '')
      this.recordNo = ''
      this.getPrincipalInfoByPin()
    } else if (/site/g.test(this.recordNo)) {
      this.$store.commit('ADD_RECORD_TYPE', 11)
      this.form.siteId = this.recordNo.replace('site', '')
      this.recordNo = ''
      this.getSiteInfo({ siteId: this.form.siteId })
    } else if (/insert/g.test(this.recordNo)) {
      this.$store.commit('ADD_RECORD_TYPE', 12)
      this.form.siteId = this.recordNo.replace('insert', '')
      this.recordNo = ''
      this.getSiteInfo({ siteId: this.form.siteId })
    } else {
      if (this.recordType !== 10) {
        this.getSiteInfo({ recordNo: this.recordNo })
      }
      this.getPrincipalInfo()
    }
  },
  watch: {},
  computed: {
    warningTitle() {
      switch (this.recordType) {
        case 10: {
          return '提示：注销主体是指注销主体信息的备案号，主体备案号注销之后，您主体下的所有网站的备案号都会被一起注销，根据相关法律法规要求没有备案号的网站不能开通访问。您作为网站所有者或网站管理者，本次备案请求是您的真实意思表达， 您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。'
        }
        case 11: {
          return '提示：注销网站是指注销您的网站备案号，注销网站备案号不会影响到您的主体备案号与其他未申请注销的网站备案号， 只注销您当前选中的网站备案号，根据相关法律法规要求没有备案号的网站不能开通访问。您作为网站所有者或网站管理者，本次备案请求是您的真实意思的表达，您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。'
        }
        case 12: {
          return '提示：取消接入是指将您的备案信息从京东云的备案系统中取消，取消接入不会注销您的主体备案号，取消接入成功后如果您继续使用京东云接入商的服务器，您的网站将会被阻断访问，如果您已经不再使用京东云接入商的服务品，就不会出现此问题，另外请您尽快将备案信息接入至您正在使用服务器的接入商备案系统。 如果您的备案信息没有对应的接入商，根据相关法律法规，相关部门可能会注销您的备案号。您作为网站所有者或网站管理者，本次备案请求是您的真实意思的表达， 您已经清楚本次请求可能产生的后果，与代替您提交请求的接入商无关。'
        }
      }
    }
  },
  filters: {},
  methods: {
    async getPrincipalInfoByPin() {
      let { data } = await getPrincipalInfoByPin()
      this.form.miitRecordNo = data.miitRecordNo
    },
    async getSiteInfo(params) {
      let searchParams
      if (params) {
        // 网站变更、变更接入
        searchParams = params
        this.firstChange = true
      } else {
        // 新增备案 有主体新增网站 主体变更
        searchParams = { recordNo: this.recordNo }
      }
      let { data } = await getSiteInfo(searchParams)
      this.form.miitRecordNo = data.recordSites.length ? data.recordSites[0].miitRecordNo : ''
      this.form.siteId = data.recordSites.length ? data.recordSites[0].id : ''
      let filterDomains = data.recordDomains.filter(val => val.siteId === this.form.siteId)
      this.form.domains = JSON.stringify(
        filterDomains.map(val => {
          return { domain: val.domain }
        })
      )
      this.form.homeUrl = filterDomains.length ? filterDomains[0].homeUrl : ''
    },
    async getPrincipalInfo() {
      let { data } = await getPrincipalInfo({ recordNo: this.recordNo })
      if (this.recordType === 10) {
        this.form.miitRecordNo = data.miitRecordNo
      }
      if (this.recordNo) {
        this.form.miitRecordPwd = data.miitRecordPwd
      }
    },
    async nextStep() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let params = {
            recordType: this.recordType
          }
          if (this.recordType !== 10) {
            params = {
              ...params,
              site: {
                recordSiteId: this.form.siteId,
                domains: this.form.domains,
                homeUrl: this.form.homeUrl
              },
              principal: {
                miitRecordPwd: this.form.miitRecordPwd
              }
            }
          } else {
            params = {
              ...params,
              principal: {
                miitRecordPwd: this.form.miitRecordPwd
              }
            }
          }
          if (this.recordNo) {
            await modifyCancelFlow({
              ...params,
              recordNo: this.recordNo
            })
          } else {
            const { data } = await newProcess({
              ...params
            })
            if (data.recordNo) {
              this.recordNo = data.recordNo
            } else {
              this.$message({ type: 'error', message: '失败' })
              return
            }
          }
          // const { data } = await updateStatus({
          //   recordNo: this.recordNo,
          //   status: 'firstAudit'
          // })
          if (this.recordNo) {
            this.$router.push({
              name: 'audit',
              params: { recordNo: this.recordNo }
            })
          } else {
            this.$message({ type: 'error', message: '失败' })
          }
        }
      })
    }
  }
}
</script>
<style lang="css" scoped>
.agrrement-link:hover {
  color: #548aff;
}
</style>
