import { continueRecord } from '@/service/record-api'
import { recordSchedule } from '@/utils/status.utils.js'
export const toCurrentPage = async function(to, next) {
  try {
    let params = {
      recordNo: to.params.recordNo
    }
    let { result } = await continueRecord(params)
    let redirectRouter = recordSchedule(result)
    console.log(111, redirectRouter)
    if (to.name === redirectRouter) {
      next()
    } else {
      next({ name: redirectRouter, params: { recordNo: to.params.recordNo } })
    }
  } catch (err) {
    console.error(err)
    next('/index')
  }
}

export const validateSchedule = function(result, pathName) {
  const recordScheduleList = ['verify', 'audit', 'step1', 'siteList', 'step2', 'step3', 'eleCheck', 'confirm', 'screen']
  let redirectRouter = recordSchedule(result) // 获得当前状态
  let scheduleIndex = recordScheduleList.findIndex(router => router === redirectRouter) // 当前状态位置
  let toPathIndex = recordScheduleList.findIndex(router => router === pathName) // 目标路由的位置
  if (scheduleIndex === -1) {
    return pathName
  }
  if (scheduleIndex < toPathIndex) {
    return redirectRouter
  } else {
    return pathName
  }
}
