import verify from './src/verify.vue'
import step1 from './src/record.information/step1.vue'
import siteList from './src/record.information/siteList.vue'
import step2 from './src/record.information/step2.vue'
import step3 from './src/record.information/step3.vue'
import screen from './src/record.information/screen.vue'
import eleCheck from './src/record.information/eleCheck.vue'
import cancelRecord from './src/record.information/cancelRecord.vue'
import confirm from './src/record.status/confirm.vue'
import audit from './src/record.status/audit.js'
import { toCurrentPage } from '@/router/toCurrentPage'
import { getRecordStatus, getSiteInfo } from '@/service/record-api'
import store from '@/stores'

export default function(router) {
  router.push({
    path: '/verify',
    name: 'verify',
    component: verify,
    meta: {},
    beforeEnter: async (to, from, next) => {
      try {
        let { result } = await getRecordStatus({ router: 'index' })
        if (result.data) {
          next()
        } else {
          next({ name: result.router })
        }
      } catch (err) {
        console.error(err)
        next('/index')
      }
    }
  })

  router.push({
    path: '/step1/:recordNo',
    name: 'step1',
    component: step1,
    meta: {
      validator: true,
      order: true
    }
  })
  // 主体变更 网站选择列表
  router.push({
    path: '/siteList/:recordNo',
    name: 'siteList',
    component: siteList,
    meta: {
      validator: true,
      order: true
    },
    beforeEnter: async (to, from, next) => {
      // recordType: 4主体变更 5是网站变更 14变更备案
      const recordType = store.state.record.recordType
      if (recordType === 4 || recordType === 5 || recordType === 14 || recordType === 24) {
        try {
          let { data } = await getSiteInfo({ recordNo: to.params.recordNo })
          if (!(data.recordSites && data.recordSites.length)) {
            next()
          } else {
            next({ name: 'step2', params: { recordNo: to.params.recordNo } })
          }
        } catch (err) {
          console.error(err)
          next('/index')
        }
      } else {
        next('/index')
      }
    }
  })

  router.push({
    path: '/step2/:recordNo',
    name: 'step2',
    component: step2,
    meta: {
      validator: true,
      order: true
    },
    beforeEnter: async (to, from, next) => {
      const recordType = store.state.record.recordType
      if (recordType === 4 || recordType === 5 || recordType === 14 || recordType === 24) {
        try {
          let { data } = await getSiteInfo({ recordNo: to.params.recordNo })
          if (data.recordSites && data.recordSites.length) {
            next()
          } else {
            next({ name: 'siteList', params: { recordNo: to.params.recordNo } })
          }
        } catch (err) {
          console.error(err)
          next('/index')
        }
      } else {
        next()
      }
    }
  })

  router.push({
    path: '/step3/:recordNo',
    name: 'step3',
    component: step3,
    meta: {
      validator: true,
      order: true
    }
  })

  router.push({
    path: '/confirm/:recordNo',
    name: 'confirm',
    component: confirm,
    meta: {
      validator: true,
      order: true
    }
  })

  router.push({
    path: '/screen/:recordNo',
    name: 'screen',
    component: screen,
    meta: {
      validator: true,
      order: true
    }
  })

  router.push({
    path: '/eleCheck/:recordNo',
    name: 'eleCheck',
    component: eleCheck,
    meta: {
      validator: true,
      order: true
    }
  })
  // 主体注销、网站注销、取消接入
  router.push({
    path: '/cancelRecord/:recordNo',
    name: 'cancelRecord',
    component: cancelRecord,
    meta: {
      validator: true
    }
  })

  router.push({
    path: '/audit/:recordNo',
    name: 'audit',
    component: audit,
    meta: {
      validator: true
    },
    beforeEnter: async (to, from, next) => {
      try {
        await toCurrentPage(to, next)
      } catch (err) {
        next('/index')
      }
    }
  })
}
