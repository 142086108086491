/* eslint-disable */
/**
 * Created by zhaijian on 2017/6/6.
 * version 1.4 @ 2017-07-10 解决table列的className有变更时 idx 获取错误的问题
 * version 1.3 @ 2017-07-04 解决类似于page-warp的div浮动
 * version 1.2 modify at : 2017-06-28 解决page-warp浮动时遮挡数据的问题
 * version 1.1 modify at : 2017-06-21 解决同一页面多次调用的问题
 * adjust elementUi-table filter's place
 * 使用说明:
 *      1、在页面初始化时 先调用hover方法
 *      2、在Vue的 mounted 方法中调用 setParams 和 mounted 方法
 *      3、其他设置请修改 params 中的相关参数
 *
 */
export const placement = function () {
  let statHasShow = new Map()
  let params = {
    hasAll: true, // 是否有“全部”
    isDealPage: false, // 是否处理 分页信息
    withPaddingClassName: 'innerCon', // table外面带有padding-left的那个div的class
    pageClassName: 'page-wrap', // 分页所在div的class
    divClassName: 'redisBox', // table所在div的class
    tableFilterClassName: 'jc-table-filter', // elementui的table Filter's className
    feeTypePaddingLeft: 18, // 计费类型弹层向右移动的距离
    feeType: '计费信息',
    changeValue: null, // 处理table's filter的回调函数（filter的实际操作函数
    globalDivClassName: '', // mainCon下的ClassName,当前VUE中最外层的DIV -- 为了添加paddingBottom使用
    isDealFixed: false, // 是否处理 pageClassName 所在的div的position 为fixed version 1.2
    fixedFunction: null, // isDealFixed为true时所需要执行的function version 1.3
    colNum: 0// table表格的列数（总列数）//必填项
  }
  /**
   * 设置弹层所需要的信息
   * @param inputParam 为json Object
   * .hasAll 必填 ，默认为 true
   * .callback 处理table's filter的回调函数（filter的实际操作函数）
   * .withPaddingClassName table外面带有padding-left的那个div的class
   * .pageClassName 分页所在div的class
   * .divClassName table所在div的class
   * .tableFilterClassName elementui的table Filter's className
   * .feeTypePaddingLeft 计费类型弹层向右移动的距离
   * .feeType "计费信息"
   * .changeValue 处理table's filter的回调函数（filter的实际操作函数）
   */
  let setParams = function (inputParam) {
    if (inputParam) {
      if (typeof inputParam.hasAll === 'boolean') params.hasAll = inputParam.hasAll
      if (typeof inputParam.isDealPage === 'boolean') params.isDealPage = inputParam.isDealPage
      if (inputParam.callback) params.changeValue = inputParam.callback
      if (inputParam.withPaddingClassName) params.withPaddingClassName = inputParam.withPaddingClassName
      if (inputParam.pageClassName) params.pageClassName = inputParam.pageClassName
      if (inputParam.divClassName) params.divClassName = inputParam.divClassName
      if (inputParam.feeTypePaddingLeft) params.feeTypePaddingLeft = inputParam.feeTypePaddingLeft
      if (inputParam.tableFilterClassName) params.tableFilterClassName = inputParam.tableFilterClassName
      if (inputParam.feeType) params.feeType = inputParam.feeType
      if (inputParam.globalDivClassName) params.globalDivClassName = inputParam.globalDivClassName
      if (inputParam.isDealFixed) params.isDealFixed = inputParam.isDealFixed
      if (inputParam.fixedFunction) params.fixedFunction = inputParam.fixedFunction
      if (inputParam.colNum) params.colNum = inputParam.colNum
    } else {
      params.hasAll = true
    }
  }
  /**
   * 把 table filter's click 转换为 hover
   * @param iconClassName 可以为空，为空使用默认值  jc-table__column-filter-trigger
   */
  let hover = function (iconClassName) {
    if (!iconClassName) iconClassName = 'jc-table__column-filter-trigger'
    // 表格状态旁边的小图标...
    let arr = getElements(iconClassName)
    let idx = 0
    if (arr && arr.length > 0) {
      for (let v of arr) {
        // console.info("鼠标移动的方向:"+this.mouseDirection(v.parentNode));
        // this.mouseDirection(v);
        mouseDirection(v)
        v.setAttribute('idx', idx++)
        v.addEventListener('mouseenter', filterToggleShow)
      }
    }
  }
  /**
   * 页面加载的时候调用此方法，用来调整 filter's的宽度
   */
  let mounted = function () {
    // if (params.isDealPage) // before version 1.3
    if (params.isDealPage || params.isDealFixed) { // since version 1.3
      try {
        let menu = getElements('mainMenu')
        if (menu && menu.length > 0) {
          menu[0].addEventListener('click', changeWidthLet)
        }
      } catch (e) {
      }
      try {
        let subMenu = getElements('subMenu')
        if (subMenu && subMenu.length > 0) {
          subMenu[0].addEventListener('click', changeWidthLet)
        }
      } catch (e) {
      }
      window.setTimeout(function () {
        try {
          let subMenuTog = getElements('subMenuToggle')
          if (subMenuTog && subMenuTog.length > 0) {
            subMenuTog[0].addEventListener('click', changeWidthLet)
          }
        } catch (e) {
        }
      }, 500)
      changeWidthLet()
      // if (params.isDealPage || params.isDealFixed) { since version 1.3
      window.onresize = function () {
        changeWidthLet()
      }
      // }
    }
  }
  // this.filterHide=function(e){//隐藏filter stat
  let filterHide = function () { // 隐藏filter stat
    let filterDiv = getElements(params.tableFilterClassName)
    if (filterDiv) {
      for (let v of filterDiv) {
        v.style.display = 'none'
      }
    }
  }
  let filterToggleShow = function (e) {
    filterHide()
    moveRealLeft()
    let obj = e.target
    let idxObj = getFilterIdx(obj)
    let idx = idxObj[0]
    let txt = idxObj[1]
    let filterDiv1 = getElements(params.tableFilterClassName)

    let filterDiv1Len = filterDiv1 ? filterDiv1.length : 0
    let showDivIdx = 0
    if (statHasShow) {
      let temp = statHasShow.get(idx)
      if (temp) {
        temp = temp.idx
        if (temp) showDivIdx = temp
        else {

        }
      } else {
        showDivIdx = statHasShow.size
      }
    }
    if (!canFilterRun(statHasShow, idx)) {
      obj.click()
      moveRealLeft()
    }
    // console.info(["filterDiv1Len:",filterDiv1Len,"showDivIdx",showDivIdx]);
    if (filterDiv1Len > 0 && filterDiv1Len > showDivIdx) {
      let tempFilter = filterDiv1[showDivIdx]
      if (tempFilter.style.display === 'none') {
        // statHasShow=true;
        tempFilter.style.display = 'block'
        window.setTimeout(function () {
          tempFilter.style.display = 'block'
        }, 201)
      } else {
        tempFilter.style.display = 'none'
      }
    }
    window.setTimeout(function () {
      let filterDiv = getElements(params.tableFilterClassName)
      let filterDivLen = filterDiv ? filterDiv.length : 0
      if (filterDivLen > 0) {
        // 是否移动弹层
        movePopover(idx, filterDiv[showDivIdx], txt, 1)
        // filterDiv[showDivIdx].style.display = "block";
      }
    }, 100)
    window.setTimeout(function () {
      let filterDiv = getElements(params.tableFilterClassName)
      let filterDivLen = filterDiv ? filterDiv.length : 0
      if (filterDivLen > 0) {
        // _this.moveToRealLeft(showDivIdx, idx, txt, filterDiv[showDivIdx]);
        if (!canFilterRun(statHasShow, idx)) {
          let dealDiv = filterDiv[showDivIdx]
          if (!dealDiv) return false
          dealDiv.setAttribute('idx', showDivIdx)
          dealDiv.addEventListener('mouseleave', null)
          dealDiv.addEventListener('mouseleave', filterHide)
          dealDiv.style.display = 'block'
          // _statHasShow.set(idx,{isFinished:true,idx:showDivIdx,top:top,canClick:false});
          let divTop = parseInt(dealDiv.style.top)

          statHasShow.set(idx, {isFinished: true, idx: showDivIdx, 'top': divTop, txt: txt})
          let dealUl = dealDiv.children
          if (dealUl && dealUl.length > 0) {
            dealUl = dealUl[0].children
            dealUl = dealUl ? Array.prototype.slice.call(dealUl) : null
            /*
             //过滤状态，向上移动，并添加“状态” --
             console.info("dealDiv.offsetHeight："+obj.parentNode.parentNode.offsetHeight);
             if(dealUl&&dealUl.length>0){
             let divOffsetHeight = obj.parentNode.parentNode.offsetHeight;
             let orgTop = NaN;
             if(_statHasShow&&_statHasShow.size>0){
             orgTop= _statHasShow.get(idx).top;
             if(!isNaN(divTop)&&divTop==orgTop){
             dealDiv.style.top=(divTop-divOffsetHeight)+"px";
             }
             }else{
             dealDiv.style.top=(divTop-divOffsetHeight)+"px";
             }
             console.info([divTop,orgTop,divOffsetHeight])
             let newNode = document.createElement("li");
             let spanNode = document.createElement("span");
             spanNode.innerText="状态";
             let iNode = document.createElement("i");
             iNode.className="arrFilter";
             newNode.appendChild(spanNode);
             newNode.appendChild(iNode);
             //newNode.innerHTML="状态<i class='arr'></i>";
             newNode.className="jc-table-filter__list-item";
             console.info(dealUl[0].parentNode);
             (dealUl[0].parentNode).insertBefore(newNode,dealUl[0]);
             } */
            if (dealUl) {
              for (let temp of dealUl) {
                let className = temp.className
                // if (className && className.includes("is-active") && temp.innerHTML == "全部") {
                if (className && className.indexOf('is-active') !== -1 && temp.innerHTML === '全部') {
                  if (params.hasAll) {
                    if (params.changeValue) {
                      temp.addEventListener('click', null)
                      temp.addEventListener('click', function () {
                        params.changeValue(getRealIdx(idx))
                      })
                    }
                  } else {
                    temp.parentNode.removeChild(temp)
                  }
                  // temp.parentNode.removeChild(temp);
                  // break;
                } else {
                  if (params.changeValue) {
                    temp.addEventListener('click', null)
                    temp.addEventListener('click', function (e) {
                      let target = e.target
                      let label = target.getAttribute('label')
                      params.changeValue(getRealIdx(idx), label)
                    })
                  }
                }
              }
            }
          }
        }
        // if(filterDiv[showDivIdx].style.display=="none"){
        moveToRealLeft(showDivIdx, idx, txt, filterDiv[showDivIdx])
      }
    }, 201)
  }
  let getRealIdx = function (idx) {
    if (params.colNum > 0) {
      if (idx > params.colNum) {
        let temp = Math.floor(idx / params.colNum)
        idx -= temp * params.colNum
        return idx
      }
    } else {
      return idx
    }
  }
  let changeWidth = function () {
    window.setTimeout(function () {
      realChangeWidth()
    }, 180)
    realChangeWidth()
    moveRealLeft()
    filterHide()
    // paddingBottom();
  }
  let changeWidthLet = function () {
    window.setTimeout(function () {
      realChangeWidth()
    }, 180)
    realChangeWidth()
    moveRealLeft()
    paddingBottom()
    filterHide()
  }
  let realChangeWidth = function () {
    let obj = getElements(params.divClassName)
    if (obj && obj.length > 0) {
      let tableWidth = obj[0].offsetWidth
      let page = getElements(params.pageClassName)
      if (page && page.length > 0) {
        let pageWidth = page[0].offsetWidth
        // console.info(["pageWidth-tableWidth",tableWidth,pageWidth]);
        if (pageWidth !== tableWidth) {
          let availWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth
          // availWidth<1051 不能使用 table's width;
          // console.info("pageWidth********:"+pageWidth);
          if (availWidth > 1051) {
            page[0].style.width = tableWidth + 'px'
          } else {
            let minWidth = pageWarpWidth(availWidth, tableWidth)
            // console.info("##############...............minWidth:"+minWidth);
            page[0].style.width = minWidth + 'px'
          }
        }
      }
    }
  }
  /**
   * <1051时调整分页信息
   * @param availWidth
   * @param tableWidth
   * @param tableDivClassName table外部的div的className
   * @returns {*}
   */
  let pageWarpWidth = function (availWidth, tableWidth, tableDivClassName) {
    let menuLeft = 0
    let mainCon = getElements('mainCon')
    if (mainCon) {
      menuLeft = parseInt(mainCon[0].style.marginLeft)
      // console.info("mainMenu's width:"+mainCon[0].style.marginLeft);
      let innerCon = getElements(params.withPaddingClassName)
      if (innerCon) {
        let innerCss = document.defaultView.getComputedStyle(innerCon[0], null)
        let paddingLeft = innerCss.paddingLeft
        // console.info("innerCon's padding-left:"+innerCss.paddingLeft);
        if (isNaN(menuLeft)) menuLeft = 0
        menuLeft += parseInt(paddingLeft)
        // console.info("menuLeft---："+[menuLeft,tableWidth,(tableWidth+menuLeft),availWidth]);
        if (availWidth > (tableWidth + menuLeft)) {
          return tableWidth
        } else {
          let temp = availWidth - menuLeft
          if (temp > 0) { return menuLeft = temp }
        }
      }
    }
    return menuLeft
  }
  // 判断鼠标移动
  // let mouseDirection = function(element, opts) {
  let mouseDirection = function (element) {
    let dirs = ['top', 'right', 'bottom', 'left']
    let calculate = function (element, e) {
      /* 以浏览器可视区域的左上角建立坐标系 */
      // 表示左上角和右下角及中心点坐标
      let x1, y1, x4, y4, x0, y0
      // 表示左上角和右下角的对角线斜率
      let k
      // 用getBoundingClientRect比较省事，而且它的兼容性还不错
      let rect = element.getBoundingClientRect()
      if (!rect.width) {
        rect.width = rect.right - rect.left
      }
      if (!rect.height) {
        rect.height = rect.bottom - rect.top
      }
      // 求各个点坐标 注意y坐标应该转换为负值，因为浏览器可视区域左上角为(0,0)，整个可视区域属于第四象限
      x1 = rect.left
      y1 = -rect.top
      x4 = rect.left + rect.width
      y4 = -(rect.top + rect.height)
      x0 = rect.left + rect.width / 2
      y0 = -(rect.top + rect.height / 2)
      // 矩形不够大，不考虑
      if (Math.abs(x1 - x4) < 0.0001) return 4
      // 计算对角线斜率
      k = (y1 - y4) / (x1 - x4)
      let range = [k, -k]
      // 表示鼠标当前位置的点坐标
      let x, y
      x = e.clientX
      y = -e.clientY
      // 表示鼠标当前位置的点与元素中心点连线的斜率
      let kk
      kk = (y - y0) / (x - x0)
      // 如果斜率在range范围内，则鼠标是从左右方向移入移出的
      if (isFinite(kk) && range[0] < kk && kk < range[1]) {
        // 根据x与x0判断左右
        return x > x0 ? 1 : 3
      } else {
        // 根据y与y0判断上下
        return y > y0 ? 0 : 2
      }
    }
    /*
     * v.addEventListener("mouseenter",this.filterToggleShow); */
    // let $element = $(element);
    // enter leave代表鼠标移入移出时的回调
    /* opts = $.extend({}, {
     enter: $.noop,
     leave: $.noop
     }, opts || {}); */

    element.addEventListener('mouseleave', function (e) {
      let r = calculate(this, e)
      // console.info("鼠标："+dirs[r]);
      if (dirs[r] !== 'bottom') {
        // filterHide(element.parentNode);
        filterHide()
      }
    })
  }
  // 调整弹窗的位置
  let moveRealLeft = function () {
    if (statHasShow && statHasShow.size > 0) {
      statHasShow.forEach(function (val, key) {
        let filterDiv = getElements(params.tableFilterClassName)
        moveToRealLeft(val.idx, key, val.txt, filterDiv[val.idx])
      })
    }
  }
  let moveToRealLeft = function (showDivIdx, idx, txt, filterDiv) { // filterDiv[showDivIdx]
    let arr = getElements('jc-table__column-filter-trigger')
    let lenArr = arr ? arr.length : 0
    if (showDivIdx <= lenArr) {
      let temp = arr[showDivIdx]
      if (temp && temp.hasChildNodes()) { temp = temp.childNodes } else temp = null
      if (temp && temp.length > 0) {
        let className = temp[0].className
        // if (className && className.includes("jc-icon-arrow-up")) {
        if (className && className.indexOf('jc-icon-arrow-up') !== -1) {
          temp[0].className = className.replace(' jc-icon-arrow-up', '')
        }
        // if (className.includes("jc-icon-arrow-down")) {
        if (className.indexOf('jc-icon-arrow-down') !== -1) {
          // 是否移动弹层
          movePopover(idx, filterDiv, txt)
          // filterDiv.style.display = "block";
        } else { // 这里不会走的 ^_^ ....
          // filterDiv.style.display = "none";
        }
      }
    }
  }
  // 是否移动弹层，如果需要移动，直接移动
  let movePopover = function (idx, filterDiv, txt, isBlock) {
    if (!filterDiv) return false
    let tempWidth = getWindowWidth()
    if (tempWidth > 0) {
      let marginLeft = 0
      let tempObj = getElements('mainCon')

      if (tempObj && tempObj.length > 0) { marginLeft = parseInt(tempObj[0].style.marginLeft) }
      marginLeft = isNaN(marginLeft) ? 0 : marginLeft
      let beforeWidth = getAllWidth(idx)
      if (tempWidth < (marginLeft + beforeWidth)) {
        // let left = _this.getRealLeft(idx,marginLeft);
        // filterDiv[showDivIdx].style.left=this.getRealLeft(idx,marginLeft)+"px";
        filterDiv.style.left = getRealLeft(idx, marginLeft) + 'px'
      } else {
        if (txt && txt === params.feeType) {
          marginLeft += params.feeTypePaddingLeft
        }
        filterDiv.style.left = getRealLeft(idx, marginLeft) + 'px'
        if (isBlock) {
          filterDiv.style.display = 'block'
        }
      }
    }
  }
  // 获取窗口的宽度
  let getWindowWidth = function () {
    let width = 0
    if (window.innerWidth) {
      width = window.innerWidth
    } else {
      width = document.documentElement.clientWidth || document.body.clientWidth
    }
    return width
  }
  // 获取前面几列的宽度
  let getAllWidth = function (length) {
    if (length < 0) length = 0
    let width = 0
    for (let i = 1; i <= length; i++) {
      width += getWidth(i)
    }
    return width
  }
  let getWidth = function (idx) {
    let obj = getElements('jc-table_1_column_' + idx)
    if (obj && obj.length > 0) {
      return obj[0].offsetWidth
    }
    return 0
  }
  // 获取弹层需要出现的位置
  let getRealLeft = function (idx, marginLeft) {
    let left = getAllWidth(idx - 1) + marginLeft
    // console.info("left:"+left+" beforeWidth:"+this.getAllWidth(idx-1));
    let tempObj = getElements(params.withPaddingClassName)
    let innerLeft = 0
    if (tempObj && tempObj.length > 0) {
      let objCss = document.defaultView.getComputedStyle(tempObj[0], null)
      if (objCss) {
        // console.info("marginLeft:"+objCss["marginLeft"]+" paddingLeft:"+objCss["paddingLeft"]);
        innerLeft = parseInt(objCss['marginLeft'])
        if (isNaN(innerLeft)) innerLeft = 0
        let tempPadding = parseInt(objCss['paddingLeft'])
        if (!isNaN(tempPadding)) {
          innerLeft += tempPadding
        }
      }
    }
    left += innerLeft
    return left
  }
  let getFilterIdx = function (obj) {
    // 获取筛选条件处在列的索引
    let className = ''
    let txt = ''
    try {
      className = obj.className
      if (className === 'cell') {
        obj = obj.parentNode
      } else {
        txt = obj.parentNode.innerText
        obj = obj.parentNode.parentNode
      }
      className = obj.className
    } catch (e) {
      console.info(e)
    }
    let idx = 0
    if (className) {
      let regex = 'table_([0-9]+)_column_([0-9]+).*'
      let result = className.match(regex)
      if (result && result.length === 3) {
        idx = result[2]
      }
    }
    return [idx, txt]
  }
  // 是否已经执行过点击事件
  let canFilterRun = function (obj, idx) {
    let len = obj ? obj.size : 0
    if (len <= 0) return false
    else {
      let hasShow = obj.get(idx)
      if (!hasShow) return false
      else {
        if (!hasShow.isFinished) return false
        else return hasShow.isFinished
      }
    }
  }
  /* version 1.2 begin */
  let getWindowHeight = function () {
    let height = 0
    if (window.innerHeight) {
      height = window.innerHeight
    } else {
      height = document.documentElement.clientHeight || document.body.clientHeight
    }
    return height
  }
  let getEleHeight = function (className) {
    let topObj = getElements(className)
    if (topObj && topObj.length > 0) {
      let objCss = document.defaultView.getComputedStyle(topObj[0], null)
      let cssHeight = parseFloat(objCss.height)
      let styleHeight = parseFloat(topObj[0].style.height)
      let height = -1
      if (isNaN(cssHeight)) {
        if (!isNaN(styleHeight)) {
          height = styleHeight
        }
      } else {
        if (!isNaN(styleHeight)) {
          height = styleHeight > cssHeight ? styleHeight : cssHeight
        } else {
          height = cssHeight
        }
      }
      return height
    } else {
      return -1
    }
  }
  let paddingBottom = function () {
    let mainConHeight = getEleHeight('mainCon')
    // let navBar = getEleHeight("navbar");
    let barHeight = getEleHeight(params.pageClassName)
    let winHeight = getWindowHeight()
    // let conHeight = mainConHeight+navBar;
    let conHeight = mainConHeight
    // console.info(["conHeight:"+conHeight,"winHeight:"+winHeight,"barHeight:"+barHeight]);
    if (conHeight < winHeight) {
      let obj = getElements(params.globalDivClassName)// renewListpay renewList
      if (obj && obj.length > 0) {
        obj[0].style.paddingBottom = barHeight + 'px'
        if (params.isDealFixed && typeof params.fixedFunction === 'function') { // version 1.3 继续完善此功能
          params.fixedFunction()// version 1.3 新增功能
        }
      }
    }
  }
  let getStyleOrCss = function (obj, styleKey) {
    if (!obj) return
    let styleVal = obj.style[styleKey]
    if (!styleVal) {
      let objCss = document.defaultView.getComputedStyle(obj, null)
      if (objCss) {
        styleVal = objCss[styleKey]
      }
    }
    styleVal = styleVal || ''
    return styleVal
  }
  /* version 1.2 end */
  /**
   * 利用className获取到元素数组
   * @param className
   * @returns {NodeList}
   */
  let getElements = function (className) {
    // let mainCon = document.getElementsByClassName("mainCon");
    let mainCon = document.getElementsByClassName(className)
    mainCon = mainCon ? Array.prototype.slice.call(mainCon) : null
    return mainCon
  }
  return {
    hover: hover,
    setParams: setParams,
    mounted: mounted,
    changeWidth: changeWidth
  }
}
