<template>
  <div class="innerCon">
    <div class="white-box">
      <img class="disabled-img" src="~assets/images/disabled.png" />

      <div class="disabled-desc1">尊敬的京东云用户，您好</div>
      <div class="disabled-desc2">
        <div v-show="upgradeInfo.content" v-html="upgradeInfo.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  props: [],
  components: {},
  methods: {},
  mounted() {},
  created() {},
  watch: {},
  computed: {
    ...mapState({
      upgradeInfo: state => state.profile.upgradeInfo
    })
  },
  filters: {}
}
</script>
<style scoped>
.disabled-img {
  height: 280px;
  width: 280px;
  margin: 0 calc(50% - 140px);
}
.disabled-desc1 {
  width: 100%;
  text-align: center;
  height: 20px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.disabled-desc2 {
  height: 40px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  margin: 20px calc(50% - 400px) 60px;
  text-align: center;
  line-height: 24px;
  width: 800px;
}
</style>
