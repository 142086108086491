import { get, post, Delete, put } from '@/base/http.base.js'

/**
 * 查询用户备案记录
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getRecordList(params) {
  return get('/recordcenter/api/record/list', params, {})
}

/**
 * 删除备案记录
 *
 * @export
 * @param {recordId} params
 * @param params.recordId: 备案主体id
 * @returns
 */
export function deleteRecord(params) {
  return post('/recordcenter/api/record/drop', params)
}
/**
 * 继续备案
 *
 * @export
 * @param {*} params
 * @returns
 */
export function continueRecord(params) {
  return get('/localAPI/continueRecord', params, { isLoading: false })
}
/**
 * 获取备案号详情
 *
 * @export
 * @param {id} params
 * @param params.id: 备案主体id
 * @returns
 */
export function getRecordInfo(params) {
  return get('/recordcenter/api/record/query', params)
}
/**
 * 查询主体详情
 *
 * @export
 * @param {provinceCode, cityCode, countyCode, organizersNature, identityType, identityNo, domain, jcloudServiceType, serviceValue} params
 * @param params.provinceCode: 省份
 * @param params.cityCode: 市区
 * @param params.countyCode: 县
 * @param params.organizersNature: 单位性质
 * @param params.identityType: 证件类型
 * @param params.identityNo: 证件号
 * @param params.domain: 域名
 * @param params.jcloudServiceType: 京东云服务类型
 * @param params.serviceValue: ip地址
 * @returns
 */
export function getPrincipalInfo(params) {
  return get('/recordcenter/api/principal/query', params, {
    isRemoveField: true
  })
}

/**
 * 省市县3级联动/ 获取证件类型
 *
 * @export
 * @param {code, type} params
 * @param params.code: 省市县code
 * @param params.type: 查询内容
 * @returns
 */
export function getDictionary(params) {
  return post('/recordcenter/api/dictionary', params, { isLoading: false })
}

/**
 * 提交备案校验信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function saveOrganizerWebsiteInfo(params, opt) {
  return post('/recordcenter/api/principal/save', params, {
    isRemoveField: true,
    once: true,
    ...opt
  })
}

/**
 * 保存主体信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function saveOrganizerInfo(params, opt) {
  return post('/recordcenter/api/organizer/save', params, {
    isRemoveField: true,
    once: true,
    ...opt
  })
}

/**
 * 网站信息回显 获取网站信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getSiteInfo(params) {
  return post('/recordcenter/api/website/query', params, {})
}
/**
 * 保存网站信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function saveWebsiteInfo(params, opt) {
  return post('/recordcenter/api/website/save', params, {
    once: true,
    ...opt
  })
}
/**
 * 删除网站
 *
 * @export
 * @param {*} params
 * @returns
 */
export function deleteWebSite(params) {
  return Delete('/recordcenter/api/website/del', params)
}
/**
 * 第三步信息回显 获取附件详情
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getAttachmentInfo(params) {
  return post('/recordcenter/api/attachment/query', params, {})
}

/**
 * 生成或下载承诺书
 *
 * @export
 * @param {*} params
 * @returns
 */
export function generateCommiment(params, opts) {
  return post('/recordcenter/api/recordAttachment/generateCommiment', params, opts)
}

/**
 * 保存备案识别号 信息确认页保存信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function saveRecordNumber(params) {
  return post('/recordcenter/api/record/save', params, {
    once: true
  })
}
/**
 * 初验通过
 *
 * @export
 * @param {*} params
 * @returns
 */
export function firstTrialPass(params) {
  return post('/recordcenter/api/firstTrialPass', params)
}

/**
 * 保存幕布
 *
 * @export
 * @param {*} params
 * @returns
 */
export function saveCurtainInfo(params) {
  return post('/recordcenter/api/curtain/save', params, {
    target: '.screen-form-loading'
  })
}

/**
 * 查询邮寄详情
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getPostDetail(params) {
  return post('/recordcenter/api/post/query', params, {
    target: '.detail-form-loading'
  })
}

/**
 * 更新备案状态
 *
 * @export
 * @param {*} params
 * @returns
 */
export function updateStatus(params) {
  return put('/localAPI/changeStatus', params.status, params)
}

/**
 * 更新核验状态
 *
 * @export
 * @param {*} params
 * @returns
 */
export function updateEleFlag(params) {
  return post('/recordcenter/api/eleFlag/modify', params)
}

/**
 * 查询域名是否已经备案
 *
 * @export
 * @param {*} params
 * @returns
 */
export function checkDomainExist(params) {
  return post('/recordcenter/api/v3/checkDomainExist', params, {
    isLoading: false
  })
}

/**
 * 查询域名/app是否已经备案
 *
 * @export
 * @param {*} params
 * @returns
 */
export function checkServiceExist(params) {
  return post('/recordcenter/api/v3/checkServiceExist', params, {
    isLoading: false
  })
}

export function checkIdentityCardExist(params) {
  return get('/recordcenter/api/v3/checkIdentityCardExist', params, {
    isLoading: false
  })
}

/**
 * 获取全部数据字典
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getDictionaryAll(params) {
  return post('/recordcenter/api/dictionary/all', params, { isLoading: false })
}

/**
 * 开始新流程
 *
 * @export
 * @param {*} params
 * @returns
 */
export function newProcess(params, opt) {
  return post('/recordcenter/api/startNewProcess', params, {
    isRemoveField: true,
    once: true,
    ...opt
  })
}

/**
 * 查询审核历史
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getHistory(params) {
  return post('/recordcenter/api/history', params, { isLoading: false })
}

/**
 * 创建二维码
 *
 * @export
 * @param {*} params
 * @returns
 */
export function createqrCode(params) {
  return post('/v1/user:generatePersonAuthQRCode', params, {
    target: '.qrcode-loading',
    isOutMsg: false
  })
}

/**
 * 验证二维码是否有效
 *
 * @export
 * @param {*} params
 * @returns
 */
export function verifyqrCode(params) {
  return get('/v1/user:verifyPersonAuthToken', params, { isLoading: false })
}

/**
 * 验证地域是否支持电子化核验
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getValidRegion(params) {
  return post('/recordcenter/api/dictionary/region', params, {
    isLoading: false
  })
}

/**
 * 根据PIN查询用户主体信息（主体变更使用,用于回显主体信息）
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getPrincipalInfoByPin(params) {
  // return get('/recordcenter/api/principal/findByPin', params, {})
  return post('/recordcenter/suc/principal', params, {})
}

/**
 * 根据PIN查询用户下所有的网站（主体变更使用）
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getSiteInfoByPin(params) {
  return get('/recordcenter/api/site/findByPin', params, {})
}

export function selectSite(params) {
  return post('/recordcenter/api/website/modify', params, {
    once: true
  })
}

export function startCreateSelectedWebsite(params) {
  return post('/manage/api/website/startCreateSelectedWebsite', params, {
    once: true
  })
}

// 修改注销信息
export function modifyCancelFlow(params) {
  return post('/recordcenter/api/modifyCancelFlow', params, {
    once: true
  })
}
// 查询当前备案状态，供入口页使用
export function getRecordStatus(params) {
  return get('/localAPI/getRecordStatus', params, {
    once: true
  })
}

export function getVMList(params) {
  return post('/recordcenter/api/v3/getAuthCodeInstanceList', params, {})
}

// 批量查询可申请授权码数量
export function betchGetAuthCodeCount(params) {
  return post('/recordcenter/api/describeApplyCountList', params, {})
}
// 获取code 列表
export function getAuthCodeList(params) {
  return post('/recordcenter/api/describeAuthorizationCode', params, {
    isRemoveField: true
  })
}

export function createAuthCode(params) {
  return post('/recordcenter/api/createAuthorizationCode', params, {})
}

export function validateAuthCode(params) {
  return get('/recordcenter/api/checkInstanceCode', params, {
    isLoading: false
  })
}

export function validatePic(params) {
  return get('/recordcenter/api/checkAttachment', params, {})
}

export function deleteDomain(params) {
  return post('/recordcenter/api/domain/del', params, {})
}
// 查询证件号，手机号，紧急联系方式在非本主体下出现频次
export function getFrequencyInfo(params) {
  return post('/manage/api/queryPrincipalInfoFrequency', params, {})
}
// 查询授权码列表
export function getRecordIp(params) {
  return post('/recordcenter/api/dictionary/all', params, {})
}
// 发送短信验证码
export function sendMobileSms(params) {
  return post('/recordcenter/api/v3/sendMobileSms', params, {})
}
// 主体负责人/网站负责人信息验证
export function validOwnerInfo(params) {
  return post('/recordcenter/api/v3/validOwnerInfo', params, {})
}
// 控制台删除附件
export function delAttachment(params) {
  return post('/recordcenter/api/v3/delAttachment', params, {})
}

// 获取二维码
export function generateQrCode(params) {
  return post('/recordcenter/ai/generateQrCode', params, {})
}

// 获取二维码
export function getSubmitStatus(params) {
  return get('/record/polling/status', params, { isLoading: false })
}

// 获取电子核验状态
export function queryEleCheck(params) {
  return post('/recordcenter/api/changeStatus', params, {})
}

export function getRealNameStatus(params) {
  return get('/localAPI/getRealNameStatus', params, {
    isLoading: false
  })
}

export function getSiteList(params) {
  return get('/localAPI/siteList', params, {
    isLoading: false
  })
}

export function getMailList(params) {
  return get('/localAPI/mailList', params, {
    isLoading: false
  })
}

export function getVMList2(params) {
  return get('/localAPI/vmList', params, {
    isLoading: false
  })
}
//查询文本规则库
export function queryRule(params) {
  return post('/manage/rule/queryRule', params, {
    isLoading: false
  })
}

export function queryUpgradeInfo(params) {
  return post('/manage/upgrade/recordCenter/queryUpgradeInfo', params, {
    isLoading: false
  })
}

export function uploadAppPic(params) {
  return get('/recordcenter/app/manage/uploadPic', params, {
    isLoading: false
  })
}

export function getAppPic(params) {
  return post('/recordcenter/app/getAppPic/' + params.url, params, {
    isLoading: false
  })
}

export function getByType(params) {
  return post('/recordcenter/app/appDic/getByType', params, {
    isLoading: false
  })
}

// 备案完成的服务
export function getServiceList(params) {
  return post('/recordcenter/suc/serviceList', params, {
    isLoading: false
  })
}

// 备案完成的服务详情
export function queryServiceDetail(params) {
  return post('/recordcenter/suc/queryServiceDetail', params, {
    isLoading: false
  })
}

// 备案中的订单列表
export function curRecordList(params) {
  return post('/recordcenter/record/curRecordList', params, {
    isLoading: false
  })
}

// 开启一个新的注销流程
export function startNewCancelProcess(params) {
  return post('/recordcenter/api/startNewCancelProcess', params, {
    isLoading: false
  })
}

// 发起注销时查询备案号
export function queryMiitInfo(params) {
  return post('/recordcenter/suc/queryMiitInfo', params, {
    isLoading: false
  })
}

// TODO: 发送备案转移短信验证码
export function sendVerificationCode(params) {
  return post('/recordcenter/transfer/sendVerificationCode', params, {
    isLoading: false
  })
}

/**
 * 查询用户信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getUserInfo(params) {
  return get('/localAPI/getUserInfo', params, { isLoading: false })
}

/**
 * 根据用户名查询用户信息
 *
 * @export
 * @param {*} params
 * @returns
 */
export function checkUserName(params) {
  return get('/localAPI/checkUserName', params, { isLoading: false, isOutMsg: false })
}

/**
 * 账号转移
 *
 * @export
 * @param {*} params
 * @returns
 */
export function transferRecord(params) {
  return post('/recordcenter/transfer/transferRecord', params, { isLoading: false })
}
