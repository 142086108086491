import Vue from 'vue'
import axios from 'axios'
import { Message, Notification } from '@jd/element-ui'
import { isNull } from '@/utils'
import Cookies from 'js-cookie'
import Raven from 'raven-js'
import _array from 'lodash/array'
// import {LINK} from ''
const service = axios.create({
  timeout: 20000,
  withCredentials: true
})
// loading 组
// loading 组
let ARR_LOADING = []
// let IS_LOADING = false
let LOADING_INSTANCE = []

let ARR_LOADING_TIME = []
service.interceptors.request.use(config => {
  if (config.isLoading) {
    if (config.once) {
      if (ARR_LOADING.find(val => config.url === val.url)) {
        return false
      }
    }
    let loadingConfig = {
      // text: '努力加载中',
      target: config.target || '.mainCon'
    }
    if (!ARR_LOADING.find(val => val.target === loadingConfig.target)) {
      LOADING_INSTANCE.push({
        loading: Vue.$loading(loadingConfig),
        target: loadingConfig.target
      })
    }
    ARR_LOADING.push({ url: config.url, target: loadingConfig.target })
  }
  if (config.computeResponseTime) {
    let apiData = {}
    apiData[`${config.method}${config.url}`] = new Date()
    ARR_LOADING_TIME.push(apiData)
  }
  Object.assign(config.headers, {
    'x-lang': Cookies.get('jdcloud_sitelang') || 'cn'
  })
  if (config.isCsrf && Cookies.get('csrfToken')) {
    Object.assign(config.headers, {
      'x-csrf-token': Cookies.get('csrfToken')
    })
  }
  Object.assign(config.headers, {
    'Content-Type': 'application/json'
  })

  return config
})
service.interceptors.response.use(
  response => {
    let {
      data: { code, success, message, data, result, error },
      config
    } = response
    if (config.isLoading) {
      let index = ARR_LOADING.findIndex(val => val.url === config.url)
      ARR_LOADING.splice(index, 1)
      let target = config.target || '.mainCon'
      if (!ARR_LOADING.find(val => val.target === target)) {
        let VUE_LOADINGIndex = LOADING_INSTANCE.findIndex(val => val.target === target)
        if (VUE_LOADINGIndex !== -1) {
          LOADING_INSTANCE[VUE_LOADINGIndex].loading.close()
          LOADING_INSTANCE.splice(VUE_LOADINGIndex, 1)
        }
      }
    }
    if (config.computeResponseTime) {
      let key = `${config.method}${config.url}`
      let arr = ARR_LOADING_TIME.filter(val => val[key])
      if (arr.length > 0) {
        let time = new Date() - arr[0][key]
        if (time > config.loadingTime) {
          Raven.captureException(
            new Error(
              JSON.stringify({
                requestId: response.data ? response.data.requestId : '',
                time,
                message: 'time out'
              })
            )
          )
        }
        _array.remove(ARR_LOADING_TIME, arr[0])
      }
    }
    // 如果不是code 返回值
    if (isNull(code) && isNull(success)) {
      return response.data
    }
    if (code !== 0 || success === false) {
      // 是否自动提示消息
      if (config.isOutMsg) {
        Message({
          // showClose: true,
          // duration: message ? 10000 : 3000,
          type: 'error',
          message: message || '出错啦，请确认网络是否正常，稍后再试'
        })
      }
      return Promise.reject({ code, error, message, result, data }) // eslint-disable-line
    } else {
      return response.data
    }
  },
  error => {
    const { config, response } = error
    if (config.isLoading) {
      let index = ARR_LOADING.findIndex(val => val.url === config.url)
      ARR_LOADING.splice(index, 1)
      let target = config.target || '.mainCon'
      if (!ARR_LOADING.find(val => val.target === target)) {
        let VUE_LOADINGIndex = LOADING_INSTANCE.findIndex(val => val.target === target)
        if (VUE_LOADINGIndex !== -1) {
          LOADING_INSTANCE[VUE_LOADINGIndex].loading.close()
          LOADING_INSTANCE.splice(VUE_LOADINGIndex, 1)
        }
      }
    }
    if (config.computeResponseTime) {
      let key = `${config.method}${config.url}`
      let arr = ARR_LOADING_TIME.filter(val => val[key])
      if (arr.length > 0) {
        let time = new Date() - arr[0][key]
        if (time > config.loadingTime) {
          Raven.captureException(
            new Error(
              JSON.stringify({
                time,
                message: 'time out'
              })
            )
          )
        }
        _array.remove(ARR_LOADING_TIME, arr[0])
      }
    }
    if (!response) {
      Message({
        type: 'error',
        message: error || '出错啦，请确认网络是否正常，稍后再试'
      })
      Raven.captureException(error)
      return Promise.reject(error)
    }
    const { status, data } = response
    if (status === 401) {
      if (data && data.isRole) {
        let redirectUrl = Cookies.get('r_url')
        window.location.href = redirectUrl + `?returnUrl=${encodeURIComponent(window.location.href)}`
      } else {
        let url = data.loginUrl || derivativeConfig.login
        window.location.href = url
      }
      return Promise.reject({ status: 401 })
    }
    if (status === 402) {
      window.location.href = derivativeConfig.active + encodeURIComponent(window.location.href)
      return Promise.reject({ status: 402 })
    }
    if (status === 403) {
      if (data.message.indexOf('[gw]' !== -1)) {
        Notification({
          type: 'error',
          title: '您没有权限执行此操作，请联系管理员为您添加权限。',
          message: data.message,
          duration: 10000
        })
      } else {
        window.location.href = derivativeConfig.permission
      }
      return Promise.reject({ status: 403 })
    }
    Raven.captureException(error)
    if (config.isOutMsg) {
      Message({
        type: 'error',
        message: data ? data.message : '出错啦，请确认网络是否正常，稍后再试'
      })
    }
    return Promise.reject(data)
  }
)
export default service
