<template>
  <div>
    <rc-children-card title="备案管理" @back="back"></rc-children-card>
    <div class="jc-page-content">
      <jc-table :data="tableData">
        <jc-table-column label="状态">
          <template scope="scope">
            {{ scope.row.state | recordStatusFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="时间">
          <template scope="scope">
            {{ scope.row.updateTime | timeFilter }}
          </template>
        </jc-table-column>
        <jc-table-column prop="remark" label="原因"> </jc-table-column>
      </jc-table>
    </div>
  </div>
</template>
<script>
import { getHistory } from '@/service/record-api'
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      recordNo: this.$route.query.recordNo,
      tableData: []
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.getHistory()
  },
  filters: {},
  methods: {
    async getHistory() {
      const { data } = await getHistory({
        recordNo: this.recordNo
      })
      this.tableData = data
    },
    back() {
      this.$router.push({ name: 'manageList' })
    }
  }
}
</script>
