<template>
  <div class="jc-section">
    <div class="jc-section-header">
      <h3 class="jc-section-title">
        <slot name="title">{{ title }}</slot>
        <small><slot name="add"></slot><slot name="link"></slot></small>
      </h3>
    </div>
    <div class="jc-section-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rc-section',
  props: {
    title: String
  },
  components: {},
  mixins: [],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  filters: {},
  methods: {}
}
</script>
