import Card from './card'
import Section from './section'
import Upload from './upload'
import Step from './step'
import Layouts from './layouts'
import DzInput from './input'
import ChildrenCard from './childrenCard'
// import Ele from '@/components/element.js'
import Jcele from '@jd/element-ui'
import Vue from 'vue'

const components = [Card, ChildrenCard, Section, Upload, Step, Layouts, DzInput]

Vue.$loading = Vue.prototype.$loading = Jcele.Loading.service
const install = Vue => {
  if (install.installed) {
    return
  }
  // Vue.use(Ele)
  Vue.use(Jcele)

  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install
}
