// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@babel/polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import stores from './stores'
import component from './components'
import role from '@/mixins/role.mixins'
import outMsg from '@/mixins/message.mixins'
import JElementBiz from '@jdcloud/jelement-biz'
import '@jdcloud/jelement-biz/dist/theme-default/index.css'
import './filters'
import '@/assets/common/total.scss'
import VueCompositionAPI from '@vue/composition-api'
import { storeKey } from '@/hooks/useStore'
import { Cascader } from '@jdcloud/jelement-next'
// import '@jdcloud/jelement-ui-default/lib/index.css'

Vue.config.devtools = true

Vue.config.productionTip = process.env.NODE_ENV === 'development'

Vue.use(component)
Vue.mixin(outMsg)
Vue.use(VueCompositionAPI)

Vue.use(Cascader)
Vue.use(JElementBiz)

window.toRoutePath = function(url) {
  if (!url || url.length <= 0) {
    return
  }
  if (url.charAt(0) !== '/') {
    url = '/' + url
  }
  router.push(url)
}

new Vue({
  el: '#app',
  store: stores,
  provide() {
    return {
      [storeKey]: stores
    }
  },
  router,
  components: { App },
  template: '<App/>'
})

Vue.mixin(role)
