import { deleteRecord } from '@/service/record-api'
export default {
  props: [],
  components: {},
  mixins: [],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  filters: {},
  methods: {
    async quitMessage() {
      try {
        await this.$confirm('如您放弃当前备案，当前备案中所填信息将被删除！', '放弃本次流程', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: ''
        })
      } catch (err) {
        return false
      }
      this.quitRecord()
    },
    async quitRecord() {
      try {
        let params = {
          recordNo: this.recordNo
        }
        await deleteRecord(params)
        this.outMsg('success', '放弃本次流程成功')
        // 备案三期修改
        this.$router.push({ path: '/index' })
      } catch (err) {
        console.error(err)
      }
    }
  }
}
