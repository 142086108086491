<template>
  <div>
    <div class="jc-container-table">
      <div class="jc-container-table-actionBar">
        <jc-form class="jc-table-actionBar-item" :inline="true">
          <jc-form-item label="站点名称">
            <jc-input placeholder="请输入站点名称" v-model="form.webAdmin" />
          </jc-form-item>
          <jc-form-item label="">
            <jc-button @click="getList" type="primary">查询</jc-button>
          </jc-form-item>
        </jc-form>
      </div>
      <jc-table :data="tableData">
        <jc-table-column label="站点名称">
          <template slot-scope="scope">
            {{ scope.row.webAdmin | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="产品类型">
          <template slot-scope="scope">
            {{ scope.row.productType | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="公网IP">
          <template slot-scope="scope">
            {{ scope.row.ipAddress | emptyFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="资源到期时间" width="290px">
          <template slot-scope="scope">
            {{ scope.row.chargeExpiredTime | timeFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="已申请个数">
          <template slot-scope="scope">
            {{ scope.row.authCodeCount | NumberFilter }}
          </template>
        </jc-table-column>
        <jc-table-column label="操作">
          <template slot-scope="scope">
            <apply-button
              v-if="scope.row.authCodeCount < maxNumber"
              :instance="scope.row"
              :instance-id="scope.row.webAdmin"
              :auth-type="AUTH_CODE_TYPE.SITE"
              title="站点名称"
              @success="getList"
            />
            <jc-button
              type="text"
              @click="
                $router.push({
                  name: 'authCodeManage',
                  query: { instanceId: scope.row.webAdmin }
                })
              "
              >查看</jc-button
            >
          </template>
        </jc-table-column>
      </jc-table>
      <div class="jc-container-table-actionBar">
        <jc-pagination
          v-if="showPagination"
          class="align-right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParams.pageNumber"
          :page-sizes="[20, 50, 100]"
          :page-size="pageParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :page-count="totalCount"
        >
        </jc-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ApplyButton from './ApplyButton.vue'
import { usePagination, useSearch } from '../hooks'
import { AUTH_CODE_TYPE } from '@/views/service/constant'

export default {
  components: { ApplyButton },
  setup() {
    // 页码
    const { handleSizeChange, handleCurrentChange, totalCount, pageParams, showPagination } = usePagination()
    const authCodeType = AUTH_CODE_TYPE.SITE
    const { form, tableData, getList } = useSearch(pageParams, totalCount, authCodeType)
    const maxNumber = 1 //最大申请数量
    return {
      AUTH_CODE_TYPE,
      handleSizeChange,
      handleCurrentChange,
      totalCount,
      pageParams,
      showPagination,
      form,
      tableData,
      getList,
      maxNumber
    }
  }
}
</script>
