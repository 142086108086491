<template>
  <div>
    <jc-form-item :error="error">
      <jc-upload
        @mouseenter.native="showShade = true"
        @mouseleave.native="showShade = false"
        v-loading="loading"
        class="avatar-uploader"
        :action="uploadUrl"
        :data="uploadData"
        :headers="header"
        list-type="picture"
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
      >
        <img v-if="upload.imgUrl" :src="upload.imgUrl" class="avatar" style="width: 96px; height: 96px" />
        <i v-else class="jc-icon-plus avatar-uploader-icon"></i>
        <div class="upload-back" v-show="showShade && upload.imgUrl">
          <div class="preview" @click.stop="dialogVisible = true">
            <i class="jc-icon-view" @click.stop="dialogVisible = true"></i>
          </div>
          <div class="delete">
            <i class="jc-icon-delete2" @click.stop="handleRemove"></i>
          </div>
        </div>
      </jc-upload>
    </jc-form-item>
    <!--图片放大预览弹窗-->
    <jc-dialog v-model="dialogVisible" size="small" title="预览">
      <img :src="upload.imgUrl" style="width: 100%;" />
    </jc-dialog>
  </div>
</template>
<script>
import outMsg from '@/mixins/message.mixins.js'
import Emitter from '@jd/element-ui/lib/mixins/emitter'
import { delAttachment } from '@/service/record-api'
import Cookie from 'js-cookie'
export default {
  name: 'rc-upload',
  props: {
    value: {
      type: Object,
      require: true
    },
    uploadData: {
      type: Object,
      require: true
    },
    limit: Number
  },
  inject: {
    jcForm: {
      default: ''
    },
    JcFormItem: {
      default: ''
    }
  },
  components: {},
  mixins: [outMsg, Emitter],
  data() {
    return {
      upload: {
        imgUrl: '',
        attachmentId: ''
      },
      showShade: false,
      dialogVisible: false,
      loading: false,
      error: ''
    }
  },
  watch: {
    value(val) {
      this.upload = val
    },
    upload: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  computed: {
    header() {
      return {
        'x-csrf-token': Cookie.get('csrfToken')
      }
    },
    uploadUrl() {
      return `/record/fileupload/${this.uploadData.recordNo}/${this.uploadData.uploadPath || 'uploadfile'}`
      // return '/localAPI/file'
    }
  },
  mounted() {},
  created() {
    this.upload = this.value
  },
  filters: {},
  methods: {
    beforeUpload(file) {
      this.error = ''
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.outMsg('error', '上传图片只能是 JPG 格式！')
        this.error = '上传图片只能是 JPG 格式！'
      }
      if (!isLt5M) {
        this.outMsg('error', '上传图片大小不能超过 5MB！')
        this.error = '上传图片大小不能超过 5MB！'
      }
      if (isJPG && isLt5M) {
        this.loading = true
      } else {
        return false
      }
      this.uploadData.attachmentId = ''
    },
    handleSuccess(res) {
      if (res && res.code == 0) {
        this.upload.imgUrl = res.data.url
        this.upload.attachmentId = res.data.attId
        this.outMsg('success', '上传成功')
        this.loading = false
        this.dispatch('JcFormItem', 'el.form.change', this.upload)
        this.$emit('uploadSuccess')
      } else {
        switch (res.code) {
          case 2101: {
            this.error = '身份证校验失败，身份证照片无法识别'
            break
          }
          case 2102: {
            this.error = '身份证校验失败，身份证照片和填写信息不一致'
            break
          }
          case 2103: {
            this.error = '身份证校验失败，身份证信息实名验证不通过'
            break
          }
          case 2104: {
            this.error = '身份证校验失败，身份证照片网纹校验不通过'
            break
          }
          case 2105: {
            this.error = '身份证校验失败，网络异常'
            break
          }
          case 2201: {
            this.error = '营业执照校验失败，营业执照照片无法识别'
            break
          }
          case 2202: {
            this.error = '营业执照校验失败，营业执照照片和填写信息不一致'
            break
          }
          case 2203: {
            this.error = '营业执照校验失败，营业执照信息验证不通过'
            break
          }
          case 2204: {
            this.error = '营业执照校验失败，网络异常'
            break
          }
          default: {
            let errorMsg = '上传失败！'
            if (res && res.msg) {
              errorMsg = res.msg
            } else if (res.data && res.data.uploadMsg) {
              errorMsg = res.data.uploadMsg
            }
            this.outMsg('error', errorMsg)
            try {
              window.__sgm__.custom({
                type: 1,
                code: 'ERROR_RC_UPLOAD',
                msg: '上传资料错误：' + errorMsg + ' 参数：' + JSON.stringify(this.uploadData)
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
        this.loading = false
      }
    },
    handlePictureCardPreview() {
      this.dialogVisible = true
    },
    async handleRemove() {
      await delAttachment({
        recordNo: this.uploadData.recordNo,
        id: this.upload.attachmentId
      })
      this.upload.imgUrl = ''
    },
    handleError(error) {
      console.log(error)
      this.$message({ type: 'error', message: error.message })
      this.loading = false
    }
  }
}
</script>
<style>
.avatar-uploader {
  height: 94px;
  width: 94px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  position: relative;
}
.jc-upload--picture img {
  width: 100%;
}
.jc-upload--picture-card {
  height: 94px;
  width: 94px;
  line-height: 94px;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.jc-upload--picture-card img {
  z-index: 0;
  position: absolute;
  height: 94px;
  width: 94px;
  left: -1px;
  top: -1px;
}
.avatar-uploader-icon {
  background: none;
  height: 92px;
  width: 92px;
  line-height: 92px;
}
.upload-back {
  /* display: block !important; */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
}
.upload-back > div {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: 36px 8px 0 8px;
}
.upload-back > div i {
  font-size: 18px;
  display: block;
  color: white;
}
</style>
