import moment from 'moment'
import validator from '@/base/validate.base.js'
import { checkDomainExist, checkIdentityCardExist, validateAuthCode } from '@/service/record-api'
import { isNull, checkIDCard } from '@/utils'
// 地域
export const REGION_RULE = (rule, value, callback) => {
  if (!value.province) {
    callback(new Error('请选择省份'))
  } else if (!value.city) {
    callback(new Error('请选择城市'))
  } else if (!value.county) {
    callback(new Error('请选择区域'))
  } else {
    callback()
  }
}
// 证件号码
export const CARD_RULE = async function(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入证件号码'))
  } else if (this.form.identityType === 2 || this.form.ownerIdentityType === 2) {
    // let reg = validator.reg.identityCard
    // if (!reg.test(value)) {
    //   callback(new Error('填写 18 位身份证件号码'))
    // }
    if (!checkIDCard(value)) {
      callback(new Error('身份证格式错误，请重新输入'))
    }
    // if (!checkIDCard(value)) {
    //   callback(new Error('身份证格式错误，请重新输入'))
    // }
  }
  if (!this.$route.params.recordNo) {
    // 仅verify进行此校验
    const { data = {} } = await checkIdentityCardExist({
      identityNo: value,
      queryType: this.form.identityType
    })
    // 备案0：未备案1：已备案
    const { statusJdCloud, message } = data
    if (statusJdCloud) {
      callback(new Error(message))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 证件有效期
export const VALIDITY_PERIOD_RULE = async function(rule, value, callback) {
  const valArr = value.split('-')
  if (value === '' || valArr.length < 2) {
    callback(new Error('请选择证件有效期'))
    return false
  }
  const [firstVal, secondVal] = valArr || []
  if (!firstVal) {
    callback(new Error('请选择证件生效日期'))
    return false
  }
  if (!secondVal) {
    callback(new Error('请选择证件失效日期'))
    return false
  }
  if (
    secondVal !== '长期' &&
    moment(firstVal.replace(/./gi, '-')).valueOf() >= moment(secondVal.replace(/./gi, '-')).valueOf
  ) {
    callback(new Error('证件生效时间须大于失效时间'))
    return false
  }
  callback()
}

export const STEP1_CARD_RULE = async function(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入证件号码'))
  } else if (this.form.identityType === 2) {
    let reg = validator.reg.identityCard
    if (!reg.test(value)) {
      callback(new Error('填写 18 位身份证件号码'))
    }
  }
  if (!this.$route.params.recordNo) {
    // 仅verify进行此校验
    const { data = {} } = await checkIdentityCardExist({
      identityNo: value,
      queryType: this.form.identityType
    })
    // 备案0：未备案1：已备案
    const { statusSecurity } = data
    if (statusSecurity) {
      callback(new Error('该证件号码已备案'))
    } else {
      callback()
    }
  } else if (this.form.identityNoOld && value !== this.form.identityNoOld) {
    const { data = {} } = await checkIdentityCardExist({
      identityNo: value,
      queryType: this.form.identityType
    })
    // 备案0：未备案1：已备案
    const { statusSecurity } = data
    if (statusSecurity) {
      callback(new Error('您修改的证件号码已备案成功，无法修改为此证件号码，请修改'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
// 公网IP
export const IP_RULE = async function(rule, value, callback) {
  if (this.form.jcloudServiceType === 3) {
    if (isNull(value)) {
      callback('请输入授权码')
    } else if (
      this.siteInfo &&
      this.siteInfo.serviceValue === value &&
      this.form.jcloudServiceType === this.siteInfo.jcloudServiceType
    ) {
      callback()
    } else {
      const { data } = await validateAuthCode({
        code: value
      })
      switch (data) {
        case 0: {
          callback()
          break
        }
        case 1: {
          callback(new Error('授权码已使用'))
          break
        }
        case 2: {
          callback(new Error('授权码已使用'))
          break
        }
        default: {
          callback(new Error('授权码无效'))
        }
      }
    }
  } else {
    const ipv4 = validator.reg.ipv4
    const ipv6 = validator.reg.ipv6
    if (isNull(value)) {
      callback(new Error('请输入京东云公网IP'))
    } else if (!(ipv4.test(value) || ipv6.test(value))) {
      callback(new Error('请输入正确的京东云公网ip'))
    } else {
      callback()
    }
  }
}
// 网站域名
export const DOMAIN_RULE = async function(rule, value, callback) {
  // 和校验页域名相同不校验
  if (this.domainInfo && this.domainInfo.length) {
    let index = this.form.domains.findIndex(val => val.domain === value)
    if (this.domainInfo[index] && value === this.domainInfo[index].domain) {
      callback()
      return
    }
  }
  let reg = validator.reg.domain
  if (value === '') {
    callback(new Error('请输入网站域名'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的网站域名，格式如：hijdcloud.com'))
  } else {
    let { data = {} } = await checkDomainExist({
      domain: value
    })
    // 备案0：未备案1：已备案
    const { statusJdCloud, pin } = data
    if (statusJdCloud) {
      callback(new Error(`此域名已在京东云${pin}中存在备案，请登录${pin}继续备案操作`))
    } else {
      callback()
    }
  }

  // if (data === 1) {
  //   callback(new Error('该域名已存在'))
  // } else if (data === 2) {
  //   callback(new Error('该域名已备案'))
  // } else if(data === 3) {
  //   if(reocrdNo && [9].includes())
  // } else if(data === 3 && ![9].includes(this.recordType)) {
  //   callback(new Error('该域名已在工信部备案'))
  // } else {
  //   callback()
  // }
}
// APP名称
export const APP_NAME_RULE = async function(rule, value, callback) {
  let reg = validator.reg.appName
  if (value.trim() === '') {
    callback(new Error('请输入APP名称'))
    return false
  }
  if (value.length > 128) {
    callback(new Error('最多输入128位字符'))
    return false
  }
  if (!reg.test(value)) {
    callback(new Error('不能包含特殊字符'))
    return false
  }

  try {
    // const { data } = await *******()
    // callback(`此APP名称已在京东云${pin}中存在备案，请登录${pin}继续备案操作`)
  } catch (err) {
    callback(new Error(err?.message || err))
    return false
  }
  callback()
}
/* APP 域名校验 */
export const APP_DOMAIN_RULE = async function(rule, value, callback) {
  let reg = validator.reg.domain

  if (value === '') {
    callback(new Error('请输入域名'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的域名，格式如：hijdcloud.com'))
  } else {
    callback()
  }
}

// 地域
export const ADDRESS_RULE = function(rule, value, callback) {
  if (value === '') {
    this.addressTip = false
    callback(new Error('请输入实际通信地址，必须精确到门牌号'))
  } else {
    this.addressTip = true
    callback()
  }
}
// 图片验证
export const UPLOAD_RULE = function(rule, value, callback) {
  console.log('validate')
  // let url = value.imgUrl
  // let licenseIndex = this.form.license.findIndex(val => val.imgUrl === url)
  // let ownerIndex = this.form.owner.findIndex(val => val.imgUrl === url)
  // let siteOwnerIndex
  // let siteIndex
  // this.form.siteOwner.forEach((site, i) => {
  //   let index = site.owner.findIndex(val => val.imgUrl === url)
  //   if(index !== -1) {
  //     siteOwnerIndex = index
  //     siteIndex = i
  //   }
  // })
  // if (!value.imgUrl) {
  //   if (licenseIndex !== -1) {
  //     if (licenseIndex === 0) {
  //       callback(new Error('请上传图片'))
  //     } else {
  //       callback()
  //     }
  //   } else if (ownerIndex !== -1) { //个人负责人
  //     if (ownerIndex === 0) {
  //       callback(new Error('请上传图片'))
  //     } else if(ownerIndex === 1 && this.principalInfo.ownerIdentityType === 2) {
  //       // 个人 身份证验证2个附件
  //       callback(new Error('请上传图片'))
  //     } else {
  //       callback()
  //     }
  //   } else if (siteOwnerIndex && siteOwnerIndex !== -1) { // 网站负责人
  //     if (siteOwnerIndex === 0) {
  //       callback(new Error('请上传图片'))
  //     } else if (siteOwnerIndex === 1 && this.siteInfo[siteIndex].identityType === 2) {
  //       callback(new Error('请上传图片'))
  //     } else {
  //       callback()
  //     }
  //   } else {
  //     callback(new Error('请上传图片'))
  //   }
  // } else {
  //   callback()
  // }
  if (!value.imgUrl) {
    callback(new Error('请上传图片'))
  } else {
    callback()
  }
}

export const AGREEMENT_RULE = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请阅读并勾选协议'))
  } else {
    callback()
  }
}

export const OWNER_NAME_RULE = function(rule, value, callback) {
  if (!value) {
    console.log(this.$route.name)
    if (this.$route.name === 'step1') {
      callback(new Error('请尽量填写法人信息'))
    } else if (this.$route.name === 'step2') {
      callback(new Error('请填写实际公司网站负责人信息'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
