<template>
  <div class="jc-page-header">
    <h1>
      <!-- <i class="jc-icon-my-circle-bold"></i> -->
      <slot name="title">{{ cardTitle }}</slot>
    </h1>
    <div class="jc-box-action">
      <slot name="link"></slot>
    </div>
  </div>
</template>
<script>
import { RECORD_TYPE } from '@/views/service/constant.js'

export default {
  name: 'rc-card',
  props: {
    title: String,
    type: Number
  },
  components: {},
  mixins: [],
  data() {
    return {
      // cardTitle: ''
    }
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    cardTitle() {
      if (this.title) {
        return this.title
      }
      let recordTypeFind = RECORD_TYPE.find(x => x.value === this.recordType)
      if (recordTypeFind) {
        return recordTypeFind.label
      }
      // 备案类型 1-新备案 2-无主体新增备案 3-有主体新增备案 4-变更主体 5-变更服务
      // 6-变更接入 7-有主体新增接入 8-无主体新增接入 9-无主体新增服务 10-主体注销 11-服务注销 12-取消接入
      // switch (this.recordType) {
      //   case 1:
      //     return '新增备案'
      //   case 3:
      //     return '有主体新增服务'
      //   case 4:
      //     return '主体变更'
      //   case 5:
      //     return '服务变更'
      //   case 6:
      //     return '变更接入'
      //   case 7:
      //     return '有主体新增接入'
      //   case 8:
      //     return '无主体新增接入'
      //   case 9:
      //     return '无主体新增服务'
      //   case 10:
      //     return '主体注销'
      //   case 11:
      //     return '服务注销'
      //   case 12:
      //     return '取消接入'
      //   case 13:
      //     return '变更图片'
      //   case 14:
      //     return '变更备案'
      //   case 24:
      //     return '升级备案'
      // }
    }
  },
  filters: {},
  methods: {}
}
</script>
