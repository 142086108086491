import start from './src/start.vue'
import disabled from './src/disabled.vue'
import overview from './src/overview.vue'
import schedule from './src/schedule.vue'
import SiteDetail from './src/SiteDetail.vue'
import PrincipalDetail from './src/PrincipalDetail.vue'
import NewSite from './src/NewSite.vue'
import AuditRecord from './src/AuditRecord.vue'
import { getRecordStatus } from '@/service/record-api'

export default function(router) {
  // 备案入口
  router.push({
    path: '/index',
    name: 'index',
    component: start,
    alias: '/',
    beforeEnter: async (to, from, next) => {
      try {
        let { result } = await getRecordStatus({ router: to.name })
        if (result.data) {
          next()
        } else {
          next({ name: result.router })
        }
      } catch (err) {
        console.log(err)
        next('/index')
      }
    },
    meta: {}
  })
  // 维护
  router.push({
    path: '/disabled',
    name: 'disabled',
    component: disabled,
    meta: {}
  })
  // 备案步骤
  router.push({
    path: '/list',
    name: 'manageList',
    component: schedule,
    meta: {}
  })
  // 备案总览
  router.push({
    path: '/overview',
    name: 'manage',
    component: overview,
    beforeEnter: async (to, from, next) => {
      try {
        let { result } = await getRecordStatus({ router: to.name })
        if (result.data) {
          next()
        } else {
          next({ name: result.router })
        }
      } catch (err) {
        console.error(err)
        next('/index')
      }
    },
    meta: {}
  })
  // 网站详情
  router.push({
    path: '/siteDetail',
    name: 'siteDetail',
    component: SiteDetail,
    meta: {}
  })
  // 主体详情
  router.push({
    path: '/principalDetail',
    name: 'principalDetail',
    component: PrincipalDetail,
    meta: {}
  })
  // 有主体新增网站
  router.push({
    path: '/newSite',
    name: 'newSite',
    component: NewSite,
    meta: {}
  })
  router.push({
    path: '/auditRecord',
    name: 'auditRecord',
    component: AuditRecord,
    meta: {}
  })
}
