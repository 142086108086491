<template>
  <div id="app">
    <router-view />
    <assistant />
  </div>
</template>

<script>
import assistant from '@/components/assistant'

export default {
  name: 'App',
  components: {
    assistant
  }
}
</script>
