import err from './src/404.vue'
import browser from './src/browser.vue'
export default function(router) {
  router.push({
    path: '/404',
    name: '404',
    component: err
  })

  router.push({
    path: '/browser',
    name: 'browser',
    component: browser
  })
}
