import { reactive, ref, computed } from '@vue/composition-api'

export function usePagination() {
  const defaultPageSize = 20
  const pageParams = reactive({
    pageSize: 20,
    pageNumber: 1
  })
  const totalCount = ref(0)

  const handleSizeChange = val => {
    pageParams.pageSize = val
  }
  const handleCurrentChange = val => {
    pageParams.pageNumber = val
  }

  const showPagination = computed(() => totalCount.value > defaultPageSize)

  return {
    pageParams,
    totalCount,
    handleSizeChange,
    handleCurrentChange,
    showPagination
  }
}
