<template>
  <span>
    <jc-button type="text" @click="apply">申请</jc-button>
  </span>
</template>
<script>
import { createAuthCode } from '@/service/record-api'
export default {
  props: {
    instance: {
      type: 'Object',
      default() {
        return {}
      }
    },
    title: {
      type: 'String',
      default: ''
    },
    instanceId: {
      type: 'String',
      default: ''
    },
    regionId: {
      type: 'String',
      default: ''
    },
    authType: {
      type: 'Number',
      required: true
    }
  },
  methods: {
    async apply() {
      const h = this.$createElement
      await this.$msgbox({
        title: '申请授权码',
        message: h('p', null, [
          h('p', null, `您确定使用${this.title}：${this.instanceId} 申请备案授权码？`),
          h('p', null, '授权码有效期为一年，自申请日开始计算，请知悉。')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: true
      })
      const { data } = await createAuthCode({
        regionId: this.regionId,
        instanceId: this.instanceId,
        elasticIpAddress: this.instance.elasticIpAddress || this.instance.ipAddress,
        instanceName: this.instance.instanceName,
        expiredTime: this.authType === 3 ? null : this.instance.chargeExpiredTime, // 轻量不传expiredTime,
        authCodeType: this.authType
      })
      if (!data.error) {
        this.$message.success('申请成功')
        this.$emit('success')
        this.$router.push({
          name: 'authCodeManage',
          query: {
            instanceId: this.instanceId
          }
        })
      } else {
        this.$message.error('申请失败，请提交工单处理')
      }
    }
  }
}
</script>
